import { LineChartOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Progress, Switch, Tag, Typography } from 'antd';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { ProgressTelemetry, TrainSettings } from 'proto/nn_training/DetectorTraining_pb';
import React from 'react';
import Chart from './chart/charts';
import useGraphics from '../api/useGraphics';
import useDatasetClasses from '../api/useDatasetClasses';
import { useParams } from 'react-router';
import { convertMs } from '../api/helpers';
import { isEqual } from 'lodash';
import { TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';

type OwnProps = {
    progressTelemetry?: ProgressTelemetry.AsObject;
    trainSettings?: TrainSettings.AsObject;
    dataset?: Dataset.AsObject;
    trainingQuery?: TrainingQuery.AsObject;
};

type XAxisName = 'timems' | 'step';

const LearningInfo: React.FC<OwnProps> = ({ progressTelemetry, trainSettings, dataset, trainingQuery }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    // Ось Х на графике по дефолту - время
    const [defaultXAxis, setDefaultXAxis] = React.useState<XAxisName>('timems');

    const { id } = useParams<{ id: string }>();

    const handleCancel = () => setOpen(false);

    const { state } = trainingQuery ?? {};

    const { remaindurationms, durationms, workers, step, map50, map5095, recall, precision } = progressTelemetry ?? {};

    const { graphics } = useGraphics(+id, state);

    const [dclassesIds, setDClassesIds] = React.useState<number[]>([]);

    const { dclasses } = useDatasetClasses(dclassesIds);

    const { trainsetimages, testsetimages, validationsetimages } = dataset ?? {};

    React.useEffect(() => {
        if (dataset) {
            const ids = dataset?.examplesbyclass?.listList.map((ex) => ex.classid);
            setDClassesIds(ids ?? []);
        }
    }, [dataset]);

    const msToDateString = (ms?: number) => {
        const dateString = convertMs(ms ?? 0, '') as { d: number; h: number; m: number; s: number };

        if (typeof dateString === 'object' && dateString.d) {
            return `${dateString.d} дней ${dateString.h} часов ${dateString.m} мин`;
        }

        return `${dateString.h} часов ${dateString.m} мин`;
    };

    // Округление значений и приведение их к числу
    const FIXED_PERCENTS = {
        map5095: +((map5095 ?? 0) * 100).toFixed(2),
        map50: +((map50 ?? 0) * 100).toFixed(2),
        precision: +((precision ?? 0) * 100).toFixed(2),
        recall: +((recall ?? 0) * 100).toFixed(2),
    };

    const handleSwitchAxis = () => {
        setDefaultXAxis((old: XAxisName) => {
            if (old === 'timems') return 'step';

            return 'timems';
        });
    };

    const graphicsEmpty =
        graphics?.boxloss?.pointsList.length === 0 ||
        graphics?.map50?.pointsList.length === 0 ||
        graphics?.precision?.pointsList.length === 0 ||
        graphics?.clsloss?.pointsList.length === 0 ||
        graphics?.dflloss?.pointsList.length === 0 ||
        graphics?.map5095?.pointsList.length === 0 ||
        graphics?.learningrate?.pointsList.length === 0;

    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                title='Прогресс обучения'
                bodyStyle={{ minHeight: '400px', maxWidth: '1000px' }}
                cancelText='Закрыть'
                onOk={handleCancel}
                okButtonProps={{
                    style: {
                        display: 'none',
                    },
                }}
            >
                {(!graphics?.boxloss &&
                    !graphics?.map50 &&
                    !graphics?.precision &&
                    !graphics?.clsloss &&
                    !graphics?.dflloss &&
                    !graphics?.map5095 &&
                    !graphics?.learningrate) ||
                graphicsEmpty ? (
                    <Empty description={false} />
                ) : (
                    <div className='chartsWrapper'>
                        <div>
                            Время <Switch checked={defaultXAxis === 'step'} onChange={handleSwitchAxis} /> Эпохи
                        </div>
                        <div className='chartContainer'>
                            <Chart
                                xAxisName={defaultXAxis}
                                title='Ошибки по коробкам'
                                data={graphics?.boxloss?.pointsList ?? []}
                            />
                            <Chart
                                xAxisName={defaultXAxis}
                                title='Ошибки по классам'
                                data={graphics?.clsloss?.pointsList ?? []}
                            />
                        </div>

                        <div className='chartContainer'>
                            <Chart
                                xAxisName={defaultXAxis}
                                title='Ошибки по dfl'
                                data={graphics?.dflloss?.pointsList ?? []}
                            />
                            <Chart
                                xAxisName={defaultXAxis}
                                title='Скорость обучения'
                                data={graphics?.learningrate?.pointsList ?? []}
                            />
                        </div>

                        <div className='chartContainer'>
                            <Chart xAxisName={defaultXAxis} title='mAP50' data={graphics?.map50?.pointsList ?? []} />
                            <Chart
                                xAxisName={defaultXAxis}
                                title='mAP50_95'
                                data={graphics?.map5095?.pointsList ?? []}
                            />
                        </div>

                        <div className='chartContainer'>
                            <Chart
                                xAxisName={defaultXAxis}
                                title='Критерий точности'
                                data={graphics?.precision?.pointsList ?? []}
                            />
                            <Chart xAxisName={defaultXAxis} title='Recall' data={graphics?.recall?.pointsList ?? []} />
                        </div>
                    </div>
                )}
            </Modal>

            <div className='learningInfo'>
                <Typography.Text strong>Прогресс обучения</Typography.Text>
                <div className='learningInfo-content__wrapper'>
                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Время выполнения</Typography.Text>
                            <Typography.Title level={3}>{msToDateString(durationms)}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>До завершения</Typography.Text>
                            <Typography.Title level={3}>{msToDateString(remaindurationms)}</Typography.Title>
                        </div>
                    </div>

                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Количество рабочих потоков</Typography.Text>
                            <Typography.Title level={3}>{workers}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Шаг обучения</Typography.Text>
                            <Typography.Title level={3}>{step}</Typography.Title>
                        </div>
                    </div>

                    <div className='learningInfo__progressWrapper'>
                        <Typography.Text type='secondary'>mAP50_95</Typography.Text>
                        <Progress percent={FIXED_PERCENTS.map5095} />
                    </div>

                    <div className='learningInfo__progressWrapper'>
                        <Typography.Text type='secondary'>mAP50</Typography.Text>
                        <Progress percent={FIXED_PERCENTS.map50} />
                    </div>

                    <div className='learningInfo__progressWrapper'>
                        <Typography.Text type='secondary'>Precision</Typography.Text>
                        <Progress percent={FIXED_PERCENTS.precision} />
                    </div>

                    <div className='learningInfo__progressWrapper'>
                        <Typography.Text type='secondary'>Recall</Typography.Text>
                        <Progress percent={FIXED_PERCENTS.recall} />
                    </div>

                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Ошибка по коробкам</Typography.Text>
                            <Typography.Title level={3}>{progressTelemetry?.boxloss.toFixed(3)}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Ошибка по определению классов</Typography.Text>
                            <Typography.Title level={3}>{progressTelemetry?.clsloss.toFixed(3)}</Typography.Title>
                        </div>
                    </div>

                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Ошибка dfl</Typography.Text>
                            <Typography.Title level={3}>{progressTelemetry?.dflloss.toFixed(3)}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Текущая скорость обучения</Typography.Text>
                            <Typography.Title level={3}>{progressTelemetry?.learningrate.toFixed(4)}</Typography.Title>
                        </div>
                    </div>

                    <Button onClick={() => setOpen(true)} icon={<LineChartOutlined />}>
                        Показать прогресс в виде графиков
                    </Button>

                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Объем вычислений</Typography.Text>
                            <Typography.Title level={3}>{progressTelemetry?.modelgflops.toFixed(3)}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Время одного вычисления</Typography.Text>
                            <Typography.Title level={3}>
                                {progressTelemetry?.modelspeedPytorchms.toFixed(4)}
                            </Typography.Title>
                        </div>
                    </div>
                </div>
            </div>

            <div className='learningInfo'>
                <Typography.Text strong>Изображения</Typography.Text>

                <div className='learningInfo-content__wrapper'>
                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Количество изображений</Typography.Text>
                            <Typography.Title level={3}>
                                {trainsetimages && validationsetimages && testsetimages
                                    ? trainsetimages + validationsetimages + testsetimages
                                    : undefined}
                            </Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Обучающая выборка</Typography.Text>
                            <Typography.Title level={3}>{dataset?.trainsetimages}</Typography.Title>
                        </div>
                    </div>

                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Контрольная выборка</Typography.Text>
                            <Typography.Title level={3}>{dataset?.validationsetimages}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Тестовая выборка</Typography.Text>
                            <Typography.Title level={3}>{dataset?.testsetimages}</Typography.Title>
                        </div>
                    </div>
                </div>
            </div>

            <div className='learningInfo'>
                <Typography.Text strong>Классы сущностей</Typography.Text>

                <div className='learningInfo-content__wrapper'>
                    <div className='learningInfo-content-param'>
                        <div className='learningInfo__param'>
                            <Typography.Text type='secondary'>Количество классов</Typography.Text>
                            <Typography.Title level={3}>{dataset?.examplesbyclass?.listList.length}</Typography.Title>
                        </div>

                        <div className='learningInfo__param'></div>
                    </div>

                    <div className='learningInfo-content-param'>
                        {dclasses.length > 0 ? (
                            <div className='tagWrapper'>.
                                {dclasses.map((dcl) => {
                                    return <Tag color={dcl.color?.hexrgb}>{dcl.title}</Tag>;
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearningInfo;
