import React from 'react';
import moment from 'moment';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

interface DataseAboutHeaderComponentProps {
    datasetData: Dataset.AsObject | undefined;
}

const DataseAboutHeaderComponent = (props: DataseAboutHeaderComponentProps): JSX.Element => {
    const { datasetData } = props;

    return (
        <div>
            <div className='dataset-paje-component-header'>{datasetData ? datasetData.title : 'Датасет'}</div>
            <div className='dataset-paje-component-header-gray-text'>
                {datasetData ?
                    `Создано пользователем ${datasetData.authortitle} ${moment(datasetData.createdtime?.unixtime).format('Do MMMM YYYY')} года` :
                    'Дата создания'}
            </div>
        </div>
    );
};

export default DataseAboutHeaderComponent;
