import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

const { Panel } = Collapse;

interface ColorCorrectionProps {
    augmentationValues: YoloAugmentationConfig.AsObject | undefined;
    setAugmentationValues: React.Dispatch<React.SetStateAction<YoloAugmentationConfig.AsObject | undefined>>;
    defaultAugmentation: YoloAugmentationConfig.AsObject | undefined;
}

const ColorCorrection: React.FC<ColorCorrectionProps> = ({ augmentationValues, setAugmentationValues, defaultAugmentation }) => {
    const [checkedColorCorrection, setCheckedColorCorrection] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    useEffect(() => {
        if (augmentationValues && (augmentationValues.hsvS > 0 || augmentationValues.hsvH > 0 || augmentationValues.hsvV > 0)) {
            setCheckedColorCorrection(true);
        } else {
            setCheckedColorCorrection(false);
        }
    }, [augmentationValues]);

    const setValue = (valueType: 'hsvH' | 'hsvS' | 'hsvV', value: number | null) => {
        let newValue = value;
        if (value && value > 100) newValue = 100;
        if (value && value < 0) newValue = 0;
        setAugmentationValues(prev => prev ? { ...prev, [valueType]: (newValue || 0) / 100 } : undefined);
    };

    const switchColorCorrection = (value: boolean) => {
        if (!value) {
            setAugmentationValues(prev => prev ? { ...prev, hsvH: 0, hsvS: 0, hsvV: 0 } : undefined);
        } else {
            if (defaultAugmentation) {
                const hsvH = defaultAugmentation.hsvH;
                const hsvS = defaultAugmentation.hsvS;
                const hsvV = defaultAugmentation.hsvV;
                setAugmentationValues(prev => prev ? { ...prev, hsvH, hsvS, hsvV } : undefined);
            }
        }
        setCheckedColorCorrection(value);
    };

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Цветокоррекция</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedColorCorrection}
                                onChange={switchColorCorrection}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_1'
                >
                    <div>
                        Настройте цветовой тон, насыщенность и яркость цветов изображений, чтобы ваша модель была более
                        устойчивой к изменениям освещения и настроек камеры.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Тон
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.hsvH || 0)}
                                        onChange={(value: number) => setValue('hsvH', value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.hsvH || 0)}
                                        onBlur={(event) => setValue('hsvH', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Насыщенность
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.hsvS || 0)}
                                        onChange={(value: number) => setValue('hsvS', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.hsvS || 0)}
                                        onBlur={(event) => setValue('hsvS', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Значение
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.hsvV || 0)}
                                        onChange={(value: number) => setValue('hsvV', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.hsvV || 0)}
                                        onBlur={(event) => setValue('hsvV', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default ColorCorrection;
