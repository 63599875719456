import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row, message, Button, Typography } from 'antd';

import { RuleObject } from 'rc-field-form/lib/interface';

import { createEnhancedClient } from 'utils/grpc';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset, DatasetFormat, MergeDatasets, DatasetPartition } from 'proto/c60/nn/center/DataSets_pb';
import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

import RangeSlider from './range-slider';
import Augmentation from './augmentation';
import { DatasetsTableDataType } from './datasets-page';

import { getClientHost } from 'shared/constants';

const { Text } = Typography;

const currentHost = getClientHost();

interface Props {
    open: boolean;
    onOk: (datasetResponse: Dataset) => void;
    onCancel: () => void;
    datasets: DatasetsTableDataType[] | undefined;
}

const defaultDataset = new Dataset().setFormat(DatasetFormat.YOLO1_1);
const defaultMergeDatasets = new MergeDatasets().setDatasetconfig(defaultDataset).toObject();

const MergeDatasetsModal: React.FC<Props> = ({ open, onOk, onCancel, datasets }) => {
    const [datasetsServiceclient, setDatasetsServiceclient] = useState<DatasetsServiceClient>();
    const [mergeDatasets, setMergeDatasets] = useState(defaultMergeDatasets);
    const [isOkBtnLoading, setIsOkBtnLoading] = useState(false);

    const [mergeDatasetsForm] = Form.useForm();

    const handleOk = async () => {
        mergeDatasetsForm.submit();
        const validateResult = await mergeDatasetsForm.validateFields(['title']);
        if (validateResult) {
            try {
                setIsOkBtnLoading(true);
                const response = await mergeSelectedDatasets();
                onOk(response);
            } catch (err) {
                if (err instanceof Error) {
                    console.error(err.message);
                    message.error(`Ошибка: ${err.message}`)
                }
            } finally {
                setIsOkBtnLoading(false);
            }
        }
    };

    const handleTitleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMergeDatasets(prev => ({ ...prev, datasetconfig: { ...prev.datasetconfig, title: event.target.value } }));
    };

    useEffect(() => {
        setDatasetsServiceclient(createEnhancedClient(DatasetsServiceClient));
    }, []);

    useEffect(() => {
        fillInitialValues();
    }, [datasets]);

    return (
        <Modal
            open={open}
            title="Слияние датасетов"
            onCancel={onCancel}
            destroyOnClose
            maskClosable={false}
            width={1124}
            footer={[
                <Button key='Cancel' onClick={onCancel}>
                    Отмена
                </Button>,
                <Button key='Ok' type='primary' onClick={handleOk} loading={isOkBtnLoading}>
                    Применить
                </Button>
            ]}
        >
            <Form
                form={mergeDatasetsForm}
                name="Merge datasets"
                layout='vertical'
            >
                {datasets?.length &&
                    <Row style={{ marginBottom: 33 }}>
                        <Col span={6}>
                            <div className='modal-export-param-headers'>Датасеты</div>
                        </Col>
                        <Col span={18}>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Text>{datasets.map(ds => ds.title).join(', ')}</Text>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row style={{ marginBottom: 33 }}>
                    <Col span={6}>
                        <div className='modal-export-param-headers'>Название нового датасета</div>
                    </Col>
                    <Col span={18}>
                        <Form.Item
                            name='title'
                            rules={[
                                { required: true, message: 'Необходимо указать название' },
                                { validator: datasetNameValidator },
                            ]}
                            style={{ marginBottom: 0 }}
                        >
                            <Input placeholder='Укажите название датасета' className='cvat-modal-export-filename-input' onChange={handleTitleInputChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 33 }}>
                    <Col span={6}>
                        <div className='modal-export-param-headers'>Выборка</div>
                    </Col>
                    <Col span={18}>
                        <RangeSlider datasetsServiceclient={datasetsServiceclient} datasets={datasets} setMergeDatasets={setMergeDatasets} open={open} />
                    </Col>
                </Row>
                <Row style={{ marginBottom: 33 }}>
                    <Col span={6}>
                        <div className='modal-export-param-headers'>Аугментация</div>
                    </Col>
                    <Col span={18}>
                        <Augmentation datasetsServiceclient={datasetsServiceclient} setMergeDatasets={setMergeDatasets} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );

    function fillInitialValues() {
        mergeDatasetsForm.setFieldsValue({
            title: ''
        });
    }

    async function datasetNameValidator(_: RuleObject, value: any){
        if (datasetsServiceclient && value) {
            await datasetsServiceclient
                .checkUniqName(new Dataset().setTitle(value.trim()), {})
                .then((result) => {
                    if (result.toObject().success) {
                        return Promise.resolve();
                    }

                    return Promise.reject(
                        new Error('Датасет с таким названием уже существует'),
                    );
                })
                .catch((error) => {
                    console.error(error);
                    return Promise.reject(
                        new Error('Датасет с таким названием уже существует'),
                    );
                });
        }
    }

    async function mergeSelectedDatasets() {
        if (datasets?.length && mergeDatasets && datasetsServiceclient) {
            const mergeDatasetsPB = new MergeDatasets();
            mergeDatasetsPB.setDatasetsidsList(datasets.map(ds => Number(ds.key)));

            if (mergeDatasets.partition) {
                mergeDatasetsPB.setPartition(new DatasetPartition()
                    .setTrainpart(mergeDatasets.partition.trainpart)
                    .setValidationpart(mergeDatasets.partition.validationpart));
            }

            const datasetPB = new Dataset();
            if (mergeDatasets.datasetconfig?.title) datasetPB.setTitle(mergeDatasets.datasetconfig.title);
            if (mergeDatasets.datasetconfig?.trainsetimages) datasetPB.setTrainsetimages(mergeDatasets.datasetconfig.trainsetimages);
            if (mergeDatasets.datasetconfig?.validationsetimages) datasetPB.setValidationsetimages(mergeDatasets.datasetconfig.validationsetimages);
            if (mergeDatasets.datasetconfig?.testsetimages) datasetPB.setTestsetimages(mergeDatasets.datasetconfig.testsetimages);

            const augmentation = mergeDatasets.datasetconfig?.augmentation;
            const augmentationPB = augmentation ? new YoloAugmentationConfig()
                .setHsvH(augmentation.hsvH)
                .setHsvS(augmentation.hsvS)
                .setHsvV(augmentation.hsvV)
                .setDegrees(augmentation.degrees)
                .setTranslate(augmentation.translate)
                .setScale(augmentation.scale)
                .setShear(augmentation.shear)
                .setPerspective(augmentation.perspective)
                .setFlipud(augmentation.flipud)
                .setFliplr(augmentation.fliplr)
                .setMosaic(augmentation.mosaic)
                .setMixup(augmentation.mixup)
                .setCopyPaste(augmentation.copyPaste) :
                undefined;
            datasetPB.setAugmentation(augmentationPB);

            mergeDatasetsPB.setDatasetconfig(datasetPB);

            return datasetsServiceclient.mergeDatasets(mergeDatasetsPB, {});
        } else {
            throw new Error('Не удалось слить датасеты.');
        }
    }
};

export default MergeDatasetsModal;
