import React, { useEffect, useState } from 'react';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { Row, Col } from 'antd';

interface DataseAboutAugmentationComponentProps {
    datasetData: Dataset.AsObject | undefined;
}

const DataseAboutAugmentationComponent = (props: DataseAboutAugmentationComponentProps): JSX.Element => {
    const { datasetData } = props;

    // Список Аугментации датасета
    const [augmentationList, setAugmentationList] = useState<JSX.Element[] | undefined>(undefined);

    // Формирование списка данных Аугментации датасета
    const formingAugmentationList = (): void => {
        const dataList = [];

        if (datasetData?.augmentation?.hsvH) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Цветовой тон:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.hsvH * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.hsvS) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Насыщенность:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.hsvS * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.hsvV) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Яркость:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.hsvV * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.degrees) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Вращение:</div>
                    </Col>
                    <Col span={12}>{`${datasetData.augmentation.degrees}°`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.translate) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Сдвиг:</div>
                    </Col>
                    <Col span={12}>
                        {`${Math.floor(datasetData.augmentation.translate * 1000) / 1000}%`}
                    </Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.scale) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Масштаб:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.scale * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.shear) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Искажение:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.shear * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.perspective) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Перспектива:</div>
                    </Col>
                    <Col span={12}>
                        {`${Math.floor(datasetData.augmentation.perspective * 100000) / 100000}%`}
                    </Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.fliplr) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Отражение по горизонтали:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.fliplr * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.flipud) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Отражение по вертикали:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.flipud * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.mosaic) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Мозайка:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.mosaic * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.mixup) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Смешивание:</div>
                    </Col>
                    <Col span={12}>{`${Math.floor(datasetData.augmentation.mixup * 1000) / 1000}%`}</Col>
                </>
            ));
        }

        if (datasetData?.augmentation?.copyPaste) {
            dataList.push((
                <>
                    <Col span={12}>
                        <div className='dataset-paje-component-augmentation-subpoint'>Случайная вставка:</div>
                    </Col>
                    <Col span={12}>
                        {`${Math.floor(datasetData.augmentation.copyPaste * 1000) / 1000}%`}
                    </Col>
                </>
            ));
        }

        setAugmentationList(dataList);
    };

    useEffect(() => {
        if (datasetData) {
            formingAugmentationList();
        }
    }, [datasetData]);

    return (
        <Row gutter={[16, 40]}>
            <Col span={8}>
                <div className='dataset-paje-component-chapter'>Аугментация</div>
            </Col>
            <Col span={16}>
                <Row gutter={[16, 10]}>
                    {augmentationList || (
                        <Col span={24}>
                            <div className='dataset-paje-component-augmentation-subpoint'>Аугментация не задана</div>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default DataseAboutAugmentationComponent;
