import React, { useEffect, useState } from 'react';

import {
    Row, Col, Select, TreeSelect,
} from 'antd';

import { ClassesListForFilter } from 'components/dataset-page/dataset-page';

const { SHOW_CHILD } = TreeSelect;

interface DatasetSubsetFiltersComponentProps {
    datasetClassesList: ClassesListForFilter[];
    setCurrentFilterClasses: (value: number[]) => void;
    setCurrentFilterSubsets (value: number): void;
}

const DatasetSubsetFiltersComponent = (props: DatasetSubsetFiltersComponentProps): JSX.Element => {
    const { datasetClassesList, setCurrentFilterClasses, setCurrentFilterSubsets } = props;

    // Список классов в Select
    const [classesTreeData, setClassesTreeData] = useState<ClassesListForFilter[]>([{ title: 'Все классы', value: 0 }]);

    // Вносим скисок существующих в датасете классов в Select
    useEffect(() => setClassesTreeData([{ title: 'Все классы', value: 0, children: [...datasetClassesList] }]), [datasetClassesList]);

    return (
        <Row gutter={[24, 40]}>
            <Col span={8}>
                <div className='dataset-paje-component-filter-container'>
                    <div style={{ marginRight: 12 }}>Выборка</div>
                    <Select
                        style={{ width: '100%' }}
                        className='dataset-paje-component-selects'
                        onChange={(value) => setCurrentFilterSubsets(value)}
                        placeholder='Вся выборка'
                        defaultValue={0}
                        options={[
                            { value: 0, label: 'Вся выборка' },
                            { value: 1, label: 'Обучающая' },
                            { value: 2, label: 'Контрольная' },
                            { value: 3, label: 'Тестовая' },
                        ]}
                    />
                </div>
            </Col>
            <Col span={16}>
                <div className='dataset-paje-component-filter-container'>
                    <div style={{ marginRight: 12 }}>Классы</div>
                    <TreeSelect
                        allowClear
                        treeCheckable
                        showCheckedStrategy={SHOW_CHILD}
                        maxTagCount='responsive'
                        style={{ width: '100%' }}
                        className='dataset-paje-component-selects'
                        placeholder='Укажите классы'
                        defaultValue={[0]}
                        treeData={classesTreeData}
                        treeDefaultExpandAll
                        onChange={(value): void => setCurrentFilterClasses(value)}
                        filterTreeNode={(search, item) => (
                            String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
                        )}
                    />
                </div>
            </Col>
        </Row>
    );
};

export default DatasetSubsetFiltersComponent;
