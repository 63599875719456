import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

import type { FormValues } from '../export-dataset-modal';

const { Panel } = Collapse;

interface RotationProps {
    initialValues: FormValues;
    augmintationValues?: YoloAugmentationConfig.AsObject;
}

const Rotation = (props: RotationProps): JSX.Element => {
    const { initialValues, augmintationValues } = props;

    const [rotationValue, setRotationValue] = useState<number>(0);
    const [checkedRotation, setCheckedRotation] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 180) {
            return 180;
        }

        return Number(result);
    };

    // Заносим значения по умолчанию, полученные с gRPC
    useEffect(() => {
        setRotationValue(Number(augmintationValues?.degrees));
    }, [augmintationValues]);

    // Если значения не указаны/указаны, выключаем/включаем Switch
    useEffect(() => {
        if (rotationValue > 0) {
            setCheckedRotation(true);
        } else {
            setCheckedRotation(false);
        }

        // Добавляем в initialValues информацию по аугментации для создания Датасета
        if (initialValues.exportDatasetParams.augmentation) {
            // Если значения аугментации по умолчанию изменились, указываем значение из слайдера
            if (augmintationValues?.degrees !== rotationValue) {
                initialValues.exportDatasetParams.augmentation.degrees = rotationValue;
            }
        }
    }, [rotationValue]);

    // Если выключаем Switch, значения сбрасываются до 0
    useEffect(() => {
        if (!checkedRotation) {
            setRotationValue(0);
        }
    }, [checkedRotation]);

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Вращение</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedRotation}
                                onChange={(value) => setCheckedRotation(value)}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_2'
                >
                    <div>
                        Добавьте вращение, чтобы ваша модель была более устойчивой к
                        повороту камеры.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Вращение
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0°', 180: '±180°' }}
                                        min={0}
                                        max={180}
                                        step={1}
                                        value={rotationValue}
                                        onChange={(value) => setRotationValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `±${value}°`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={rotationValue}
                                        onChange={(value) => (
                                            value ? setRotationValue(value) : setRotationValue(0)
                                        )}
                                        min={0}
                                        max={180}
                                        step={1}
                                        controls={false}
                                        addonAfter='°'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Rotation;
