export function convertMs(miliseconds: number, format: string) {
    let days: number,
        hours: number,
        minutes: number,
        seconds: number,
        total_hours: number,
        total_minutes: number,
        total_seconds: number;

    total_seconds = parseInt(Math.floor(miliseconds / 1000).toString());
    total_minutes = parseInt(Math.floor(total_seconds / 60).toString());
    total_hours = parseInt(Math.floor(total_minutes / 60).toString());
    days = parseInt(Math.floor(total_hours / 24).toString());

    seconds = parseInt((total_seconds % 60).toString());
    minutes = parseInt((total_minutes % 60).toString());
    hours = parseInt((total_hours % 24).toString());

    switch (format) {
        case 's':
            return total_seconds;
        case 'm':
            return total_minutes;
        case 'h':
            return total_hours;
        case 'd':
            return days;
        default:
            return { d: days, h: hours, m: minutes, s: seconds };
    }
}
