import React, { useState, useEffect } from 'react';
import {
    Collapse, Row, Col, Input, InputNumber, Button, TreeSelect,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import { getClientHost } from 'shared/constants';
import {
    ObjectsId, StringSearch, TimeInterval, Interval,
} from 'proto/Common_pb';
import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { DClass, AttributeList } from 'proto/c60/nn/center/DatasetClasses_pb';
import { DatasetsCondition } from 'proto/c60/nn/center/DataSets_pb';
import { createEnhancedClient } from '../../utils/grpc';

import CustomDatePicker from './custom-date-picker';

const { Panel } = Collapse;
const { SHOW_ALL } = TreeSelect;

interface ClassesDataType {
    key: React.Key;
    title?: string;
    value?: number;
    color?: string;
    type?: number;
    attributes?: AttributeList.AsObject;
    children?: ClassesDataType[];
}

interface RESTInfoType {
    key: React.Key;
    title?: string;
    value?: number;
}

interface DatasetsFiltersProps {
    setFiltersParamsData(value: DatasetsCondition | undefined): void;
}

const DatasetsFilters = (props: DatasetsFiltersProps): JSX.Element => {
    const { setFiltersParamsData } = props;

    const [classesIds, setClassesIds] = useState<number[]>();
    const [classesBase, setClassesBase] = useState<ClassesDataType[]>();
    const [usersBase, setUsersBase] = useState<RESTInfoType[]>();
    const [projectsBase, setProjectsBase] = useState<RESTInfoType[]>();

    const [fullTextSearchField, setFullTextSearchField] = useState<string | undefined>(undefined);
    const [timeIntervalField, setTimeIntervalField] = useState<number[] | undefined>(undefined);
    const [imagesCountMinField, setImagesCountMinField] = useState<number | null>(null);
    const [imagesCountMaxField, setImagesCountMaxField] = useState<number | null>(null);
    const [classesIdField, setClassesIdField] = useState<number[] | undefined>(undefined);
    const [classesNameField, setClassesNameField] = useState<string[] | undefined>(undefined);
    const [authorConditionIdField, setAuthorConditionIdField] = useState<number[] | undefined>(undefined);
    const [authorConditionNameField, setAuthorConditionNameField] = useState<string[] | undefined>(undefined);
    const [projectsConditionIdField, setProjectsConditionIdField] = useState<number[] | undefined>(undefined);
    const [projectsConditionNameField, setProjectsConditionNameField] = useState<string[] | undefined>(undefined);

    const currentHost = getClientHost();

    //! Получаем список существующих Классов
    // 2 - Формируем дочерние объекты данных для таблицы по существующим классам
    const getClassesChild = (parents: ClassesDataType[], classesList: DClass.AsObject[]): ClassesDataType[] => {
        let haveParent = false;

        parents.map((element: ClassesDataType) => {
            classesList.forEach((section) => {
                if (element.key === section.parentdclassid) {
                    const child: ClassesDataType = {
                        key: Number(section.id),
                        title: section.title,
                        value: Number(section.id),
                        attributes: section.attributes,
                    };

                    child.color = section.color?.hexrgb;

                    // Определяем тип - 1 - 'Часть объекта', 2 - 'Подвид объекта'
                    if (section.ispart) {
                        child.type = 1;
                    } else {
                        child.type = 2;
                    }

                    element.children = element.children || [];
                    haveParent = true;

                    element.children.push(child);
                }
            });

            if (haveParent && element.children) {
                return getClassesChild(element.children, classesList);
            }

            return element;
        });

        return parents;
    };

    // 1 - Формируем объект данных для таблицы и древовидного списка по существующим классам
    const getClassesBase = (classesList: DClass.AsObject[]): void => {
        const rootForming: ClassesDataType[] = classesList.reduce((acc: ClassesDataType[], current) => {
            if (current.parentdclassid === 0) {
                const generate: ClassesDataType = {
                    key: Number(current.id),
                    title: current.title,
                    value: Number(current.id),
                    attributes: current.attributes,
                };

                generate.color = current.color?.hexrgb;

                // Указываем тип - 0 - 'Корневой класс'
                generate.type = 0;

                acc.push(generate);
            }

            return acc;
        }, []);

        const baseData: ClassesDataType[] = getClassesChild(JSON.parse(JSON.stringify(rootForming)), classesList);

        setClassesBase(baseData);
    };

    //! Получаем список ID существующих Классов
    const getClassesIds: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getAll(new StringSearch().setText(''), {});
            setClassesIds(datasets.getIdsList().sort((a, b) => a - b));
        }
    };

    //! Получаем информацию по существующим Классам
    const getClassesInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getInfo(new ObjectsId().setIdsList(classesIds || []), {});
            const classesList = datasets.toObject();
            getClassesBase(classesList.classesList.sort((value) => (value.ispart ? -1 : 1)));
        }
    };

    //! Получаем информацию по существующим Пользователям
    const getAllUsersListREST = async (): Promise<void> => {
        const getUsersList = (data: any): RESTInfoType[] => data.results.map((element: any): RESTInfoType => {
            const formingData: RESTInfoType = {
                key: element.id,
                value: element.id,
            };

            // Указываем Автора
            if (element.first_name || element.last_name) {
                formingData.title = `${element.first_name} ${element.last_name}`;
            } else {
                formingData.title = element.username;
            }

            return formingData;
        });

        const request = await fetch('api/users')
            .then((result) => result.json())
            .catch((error) => console.error(error));

        if (request) {
            setUsersBase(getUsersList(request));
        } else {
            console.error('Неудачная попытка получения списка пользователей');
        }
    };

    //! Получаем информацию по существующим Проектам
    const getAllProjectsListREST = async (): Promise<void> => {
        const getProjectsList = (data: any): RESTInfoType[] => data.results.map((element: any): RESTInfoType => {
            const formingData: RESTInfoType = {
                key: element.id,
                title: element.name,
                value: element.id,
            };

            return formingData;
        });

        const request = await fetch('api/projects')
            .then((result) => result.json())
            .catch((error) => console.error(error));

        if (request) {
            setProjectsBase(getProjectsList(request));
        } else {
            console.error('Неудачная попытка получения списка проектов');
        }
    };

    // Запуск функции получения списка ID Классов - GRPC
    useEffect(() => {
        getClassesIds();
        getAllUsersListREST();
        getAllProjectsListREST();
    }, []);

    // Запуск функции получения объекта данных существующих Классов - GRPC
    useEffect(() => {
        getClassesInfo();
    }, [classesIds]);

    // Функция очистки полей фильтра
    const clearAllFilters = (): void => {
        setFullTextSearchField(undefined);
        setTimeIntervalField(undefined);
        setImagesCountMinField(null);
        setImagesCountMaxField(null);
        setClassesIdField(undefined);
        setClassesNameField(undefined);
        setAuthorConditionIdField(undefined);
        setAuthorConditionNameField(undefined);
        setProjectsConditionIdField(undefined);
        setProjectsConditionNameField(undefined);
        setFiltersParamsData(undefined);
    };

    // Применить параметры фильтра
    const applyFilters = (): void => {
        // Проверка правильности указания Мин/Макс в полях количества изображений датасета
        if (imagesCountMinField && imagesCountMaxField) {
            // Если ввёденное значение Мин Больше Макс, меняем их местами
            if (imagesCountMinField > imagesCountMaxField) {
                setImagesCountMinField(imagesCountMaxField);
                setImagesCountMaxField(imagesCountMinField);
            }
        }

        // new DatasetsCondition()
        const filtersConfig = new DatasetsCondition();

        // setFulltextsearch
        if (fullTextSearchField) {
            filtersConfig.setFulltextsearch(fullTextSearchField);
        }

        // setCreation
        if (timeIntervalField) {
            const creation = new TimeInterval();

            creation.setBegintime(timeIntervalField[0]);
            creation.setEndtime(timeIntervalField[1]);

            filtersConfig.setCreation(creation);
        }

        // setImagescount
        if (imagesCountMinField || imagesCountMaxField) {
            const imageCount = new Interval();

            if (imagesCountMinField) {
                imageCount.setBegin(imagesCountMinField);
            }

            if (imagesCountMaxField) {
                imageCount.setEnd(imagesCountMaxField);
            }

            if (imagesCountMinField && imagesCountMaxField) {
                // Если ввёденное значение Мин Больше Макс, меняем их местами
                if (imagesCountMinField > imagesCountMaxField) {
                    imageCount.setBegin(imagesCountMaxField);
                    imageCount.setEnd(imagesCountMinField);
                }
            }

            filtersConfig.setImagescount(imageCount);
        }

        // setClassesid
        if (classesIdField) {
            const claddesId = new ObjectsId();

            claddesId.setIdsList(classesIdField);

            filtersConfig.setClassesid(claddesId);
        }

        // setAuthorcondition
        if (authorConditionIdField) {
            const authorId = new ObjectsId();

            authorId.setIdsList(authorConditionIdField);

            filtersConfig.setAuthorcondition(authorId);
        }

        // setProjectscondition
        if (projectsConditionIdField) {
            const projectsId = new ObjectsId();

            projectsId.setIdsList(projectsConditionIdField);

            filtersConfig.setProjectscondition(projectsId);
        }

        // Указываем параметры для отправки в gRPC
        setFiltersParamsData(filtersConfig);
    };

    return (
        <Collapse
            style={{ borderRadius: 6, marginBottom: 15 }}
            expandIcon={({ isActive }) => (
                <SettingOutlined
                    style={{
                        fontSize: 16,
                        height: 22,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    rotate={isActive ? 90 : 0}
                />
            )}
        >
            <Panel header='Фильтры:' key='1'>
                <Row gutter={[32, 16]}>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Полнотекстовый поиск
                                </div>
                                <Input
                                    placeholder='Укажите текстовый запрос'
                                    value={fullTextSearchField}
                                    onChange={(value) => setFullTextSearchField(value.target.value)}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Дата/Интервал
                                </div>
                                <CustomDatePicker
                                    timeIntervalField={timeIntervalField}
                                    setTimeIntervalField={setTimeIntervalField}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Количество изображений в выборке
                                </div>
                                <Row gutter={[32, 16]}>
                                    <Col span={12}>
                                        <InputNumber
                                            value={imagesCountMinField}
                                            onChange={(value) => setImagesCountMinField(() => {
                                                const result = Number(String(value).replace(/[^0-9]/g, ''));
                                                return result === 0 ? null : result;
                                            })}
                                            addonBefore='Минимум'
                                            min={0}
                                            formatter={(value) => String(value).replace(/[^0-9]/g, '')}
                                            precision={0}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <InputNumber
                                            value={imagesCountMaxField}
                                            onChange={(value) => setImagesCountMaxField(() => {
                                                const result = Number(String(value).replace(/[^0-9]/g, ''));
                                                return result === 0 ? null : result;
                                            })}
                                            addonBefore='Максимум'
                                            min={0}
                                            formatter={(value) => String(value).replace(/[^0-9]/g, '')}
                                            precision={0}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Классы
                                </div>
                                <TreeSelect
                                    treeData={classesBase}
                                    value={classesNameField}
                                    onChange={(id) => {
                                        setClassesIdField(id.length ? id.map((n) => Number(n)) : undefined);
                                        setClassesNameField(id as string[]);
                                    }}
                                    treeCheckable
                                    treeDefaultExpandAll
                                    treeLine
                                    treeIcon
                                    allowClear
                                    status={classesBase ? '' : 'error'}
                                    showCheckedStrategy={SHOW_ALL}
                                    placeholder={classesBase ? 'Укажите классы' : 'Ошибка получения списка классов'}
                                    maxTagCount='responsive'
                                    style={{ width: '100%' }}
                                    filterTreeNode={(search, item) => (
                                        String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Автор датасета
                                </div>
                                <TreeSelect
                                    treeData={usersBase}
                                    value={authorConditionNameField}
                                    onChange={(id) => {
                                        setAuthorConditionIdField(id.length ? id.map((n) => Number(n)) : undefined);
                                        setAuthorConditionNameField(id as string[]);
                                    }}
                                    treeCheckable
                                    treeDefaultExpandAll
                                    treeIcon
                                    allowClear
                                    status={usersBase ? '' : 'error'}
                                    showCheckedStrategy={SHOW_ALL}
                                    placeholder={usersBase ? 'Укажите автора датасета' : 'Ошибка получения списка авторов'}
                                    maxTagCount='responsive'
                                    style={{ width: '100%' }}
                                    filterTreeNode={(search, item) => (
                                        String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <div
                                    style={{
                                        height: 32,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    Название проекта
                                </div>
                                <TreeSelect
                                    treeData={projectsBase}
                                    value={projectsConditionNameField}
                                    onChange={(id) => {
                                        setProjectsConditionIdField(id.length ? id.map((n) => Number(n)) : undefined);
                                        setProjectsConditionNameField(id as string[]);
                                    }}
                                    treeCheckable
                                    treeDefaultExpandAll
                                    treeIcon
                                    allowClear
                                    status={projectsBase ? '' : 'error'}
                                    showCheckedStrategy={SHOW_ALL}
                                    placeholder={projectsBase ? 'Укажите название проекта' : 'Ошибка получения списка проектов'}
                                    maxTagCount='responsive'
                                    style={{ width: '100%' }}
                                    filterTreeNode={(search, item) => (
                                        String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        span={24}
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            margin: '16px 0 8px',
                        }}
                    >
                        <Button style={{ marginRight: 10 }} onClick={clearAllFilters}>Очистить все фильтры</Button>
                        <Button type='primary' onClick={applyFilters}>Применить</Button>
                    </Col>
                </Row>
            </Panel>
        </Collapse>
    );
};

export default DatasetsFilters;
