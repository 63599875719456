import { Divider, Input, Switch, Typography } from 'antd';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import { TrainOptimizer } from 'proto/nn_training/DetectorTraining_pb';
import React from 'react';

type OwnProps = {
    trainingQuery?: TrainingQuery.AsObject;
    dataset?: Dataset.AsObject;
};

const LearningParams: React.FC<OwnProps> = ({ trainingQuery, dataset }) => {
    const { trainsettings } = trainingQuery ?? {};

    const { hsvV, hsvS, perspective, fliplr, degrees } = dataset?.augmentation ?? {};

    const augmentationParams = {
        hsvV: (hsvV ?? 0) > 1 ? hsvV?.toFixed(2) : (hsvV ?? 0).toPrecision(1),
        hsvS: (hsvS ?? 0) > 1 ? hsvS?.toFixed(2) : (hsvS ?? 0).toPrecision(1),
        perspective: (perspective ?? 0) > 1 ? perspective?.toFixed(2) : (perspective ?? 0).toPrecision(1),
        fliplr: (fliplr ?? 0) > 1 ? fliplr?.toFixed(2) : (fliplr ?? 0).toPrecision(1),
        degrees: (degrees ?? 0) > 1 ? degrees?.toFixed(2) : (degrees ?? 0).toPrecision(1),
    };

    return (
        <>
            <div className='learningParams'>
                <Typography.Text strong>Параметры процесса обучения</Typography.Text>

                <div className='learningParams__mainWrapper'>
                    <div className='learningParams__container'>
                        <Typography.Text>Количество изображений в пакете</Typography.Text>
                        <Input disabled value={trainsettings?.batch} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Количество замороженных слоев</Typography.Text>
                        <Input disabled value={trainsettings?.freeze} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Коэффициент значимости точности коробки</Typography.Text>
                        <Input disabled value={trainsettings?.boxgain} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Коэффициент значимости правильного класса</Typography.Text>
                        <Input disabled value={trainsettings?.classgain} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Коэффициент dfl</Typography.Text>
                        <Input disabled value={trainsettings?.dflgain} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Интенсивность изменения весов</Typography.Text>
                        <Input disabled value={trainsettings?.weightdecay?.toFixed(2)} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Скорость обучения в начале lr0</Typography.Text>
                        <Input disabled value={trainsettings?.learnbeginrate?.toFixed(2)} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Скорость обучения в конце lr1</Typography.Text>
                        <Input disabled value={trainsettings?.learnfinalrate?.toFixed(2)} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Алгоритм управления оптимизацией</Typography.Text>
                        <Input disabled value={TrainOptimizer[trainsettings?.optimizer ?? 0]} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Cохранять пропорции изображения</Typography.Text>
                        <Switch disabled checked={trainsettings?.saveimgproportions} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Автоматическая подстройка точности</Typography.Text>
                        <Switch disabled checked={trainsettings?.amp} />
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text>Режим детерминированности</Typography.Text>
                        <Switch disabled checked={trainsettings?.deterministic} />
                    </div>
                </div>
            </div>

            <div className='learningParams'>
                <Typography.Text strong>Триггер завершения обучения</Typography.Text>

                <div className='learningParams__mainWrapper'>
                    <div className='learningParams__container'>
                        <Typography.Text>Количество эпох без прогресса</Typography.Text>
                        <Input disabled value={trainsettings?.epochs} />
                    </div>
                </div>
            </div>

            <Divider />

            <div className='learningParams'>
                <Typography.Text strong>Аугментация</Typography.Text>

                <div className='learningParams__mainWrapper'>
                    <div className='learningParams__container'>
                        <Typography.Text type='secondary'>Насыщенность:</Typography.Text>
                        <Typography.Text>{augmentationParams.hsvV}%</Typography.Text>
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text type='secondary'>Яркость:</Typography.Text>
                        <Typography.Text>{augmentationParams.hsvS}%</Typography.Text>
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text type='secondary'>Перспектива:</Typography.Text>
                        <Typography.Text>{augmentationParams.perspective}%</Typography.Text>
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text type='secondary'>Отразить по горизонтали:</Typography.Text>
                        <Typography.Text>{augmentationParams.fliplr}%</Typography.Text>
                    </div>

                    <div className='learningParams__container'>
                        <Typography.Text type='secondary'>Вращение:</Typography.Text>
                        <Typography.Text>{augmentationParams.degrees}%</Typography.Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearningParams;
