import {
    AimOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    GatewayOutlined,
    MoreOutlined,
    PullRequestOutlined,
    ShareAltOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    Dropdown, Form, Input, InputRef, MenuProps, message, Modal, Select, Space, Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';

import ColorPicker from 'components/annotation-page/standard-workspace/objects-side-bar/color-picker';
import { AttributeList, DClass } from 'proto/c60/nn/center/DatasetClasses_pb';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { ObjectsId, SolidColor, StringSearch } from 'proto/Common_pb';
import React, { useEffect, useRef, useState } from 'react';

import { getClientHost } from 'shared/constants';
import { createEnhancedClient } from 'utils/grpc';
import AttributeModal from './attribute-modal';
import MoveToNodeModal from './node-modal';

import TopBarComponent from './top-bar';
import TypeEditModal from './type-modal';

const { confirm } = Modal;

interface DataType {
    key: React.Key;
    tblName?: string;
    tblKind?: number;
    tblAttribute?: number;
    tblColor?: string;
    tblType?: number;
    tblDescription?: string;
    attributes?: AttributeList.AsObject;
    children?: DataType[];
}

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof DataType;
    record: DataType;
    handleSave: (record: DataType, action: string) => void;
}

// Редактируемые поля таблицы Название класса / Описание
const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editing) {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [editing]);

    const toggleEdit = (): void => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async (): Promise<void> => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({ ...record, ...values }, 'edit');
        } catch (errInfo) {
            console.error('Ошибка:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form form={form} component={false}>
                <Form.Item style={{ margin: 0 }} name={dataIndex}>
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            </Form>
        ) : (
            <div className='editableCellValueWrap' style={{ paddingRight: 24 }} onClick={toggleEdit} aria-hidden='true'>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default function ClassRegistryComponent(): JSX.Element {
    const [datasetsIds, setDatasetsIds] = useState<number[]>();
    const [classesBase, setClassesBase] = useState<DataType[]>();

    // Модальное - Перемещение в другой узел
    const [nodeModalOpen, setNodeModalOpen] = useState<boolean>(false);
    const [currentClassNode, setCurrentClassNode] = useState<DataType>();

    // Модальное - Атрибуты
    const [attributeModalOpen, setAttributeModalOpen] = useState<boolean>(false);

    // Модальное - Атрибуты
    const [typeModalOpen, setTypeModalOpen] = useState<boolean>(false);

    const currentHost = getClientHost();

    // 2 - Формируем дочерние объекты данных для таблицы по существующим классам
    const getClassesChild = (parents: DataType[], classesList: DClass.AsObject[]): DataType[] => {
        let haveParent = false;

        parents.map((element: DataType) => {
            classesList.forEach((section) => {
                if (element.key === section.parentdclassid) {
                    const child: DataType = {
                        key: Number(section.id),
                        tblName: section.title,
                        tblKind: section.kind,
                        tblAttribute: section.attributes?.attributesList.length,
                        tblDescription: section.description,
                        attributes: section.attributes,
                    };

                    child.tblColor = section.color?.hexrgb;

                    // Определяем тип - 1 - 'Часть объекта', 2 - 'Подвид объекта'
                    if (section.ispart) {
                        child.tblType = 1;
                    } else {
                        child.tblType = 2;
                    }

                    element.children = element.children || [];
                    haveParent = true;

                    element.children.push(child);
                }
            });

            if (haveParent && element.children) {
                return getClassesChild(element.children, classesList);
            }

            return element;
        });

        return parents;
    };

    // 1 - Формируем объект данных для таблицы и древовидного списка по существующим классам
    const getClassesBase = (classesList: DClass.AsObject[]): void => {
        const rootForming: DataType[] = classesList.reduce((acc: DataType[], current) => {
            if (current.parentdclassid === 0) {
                const generate: DataType = {
                    key: Number(current.id),
                    tblName: current.title,
                    tblKind: current.kind,
                    tblAttribute: current.attributes?.attributesList.length,
                    tblDescription: current.description,
                    attributes: current.attributes,
                };

                generate.tblColor = current.color?.hexrgb;

                // Указываем тип - 0 - 'Корневой класс'
                generate.tblType = 0;

                acc.push(generate);
            }

            return acc;
        }, []);

        const baseData: DataType[] = getClassesChild(JSON.parse(JSON.stringify(rootForming)), classesList);

        setClassesBase(baseData);
    };

    //! Получаем список ID существующих классов
    const getDatasetsIds: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getAll(new StringSearch().setText(''), {});
            setDatasetsIds(datasets.getIdsList().sort((a, b) => a - b));
        }
    };

    //! Получаем информацию по существующим классам
    const getDatasetsInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getInfo(new ObjectsId().setIdsList(datasetsIds || []), {});
            const classesList = datasets.toObject();
            getClassesBase(classesList.classesList.sort((value) => (value.ispart ? -1 : 1)));
        }
    };

    // Запуск функции получения списка ID - GRPC
    useEffect(() => {
        getDatasetsIds();
    }, []);

    // Запуск функции получения объекта данных существующих классов - GRPC
    useEffect(() => {
        getDatasetsInfo();
    }, [datasetsIds, currentClassNode]);

    //! Сохраняем изменение данных - GRPC
    const handleSave = (row: DataType, action?: string): void => {
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);

                // Изменения Названия класса/Описания
                if (action === 'edit') {
                    await client
                        .insert(
                            new DClass()
                                .setId(Number(row.key))
                                .setTitle(String(row.tblName).trim())
                                .setDescription(String(row.tblDescription).trim()),
                            {},
                        )
                        .then(() => {
                            getDatasetsIds();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }

                // Добавление подвида
                if (action === 'subspecies') {
                    // Проверка нового имени + добавление уникального
                    let checkedTitle: string;
                    for (let idCheck = 0; ; idCheck++) {
                        checkedTitle = idCheck === 0 ? 'Новый подвид' : `Новый подвид #${idCheck}`;
                        const checkResult = await client.checkUniqTitle(new DClass().setTitle(checkedTitle), {});

                        if (checkResult.getSuccess()) {
                            await client
                                .insert(
                                    new DClass()
                                        .setTitle(checkedTitle)
                                        .setIspart(false)
                                        .setParentdclassid(Number(row.key)),
                                    {},
                                )
                                .then(() => {
                                    getDatasetsIds();
                                    message.success('Новый подвид объекта создан!');
                                })
                                .catch((error) => {
                                    message.error('Не удалось создать новый подвид объекта!');
                                    console.error(error);
                                });
                            break;
                        }
                    }
                }

                // Добавление части
                if (action === 'section') {
                    // Проверка нового имени + добавление уникального
                    let checkedTitle: string;
                    for (let idCheck = 0; ; idCheck++) {
                        checkedTitle = idCheck === 0 ? 'Новая часть' : `Новая часть #${idCheck}`;
                        const checkResult = await client.checkUniqTitle(new DClass().setTitle(checkedTitle), {});

                        if (checkResult.getSuccess()) {
                            await client
                                .insert(
                                    new DClass()
                                        .setTitle(checkedTitle)
                                        .setIspart(true)
                                        .setParentdclassid(Number(row.key)),
                                    {},
                                )
                                .then(() => {
                                    getDatasetsIds();
                                    message.success('Новая часть объекта создана!');
                                })
                                .catch((error) => {
                                    message.error('Не удалось создать новую часть объекта!');
                                    console.error(error);
                                });
                            break;
                        }
                    }
                }

                // Удалить класс
                if (action === 'delete') {
                    await client
                        .delete(new ObjectsId().setIdsList([Number(row.key)] || []), {})
                        .then(() => {
                            getDatasetsIds();
                            message.success('Запись удалена!');
                        })
                        .catch((error) => {
                            message.error('Не удалось удалить запись!');
                            console.error(error);
                        });
                }
            }
        })();
    };

    //! Сохраняем изменение типа разметки - GRPC
    const handleKind = (row: DataType, value?: number): void => {
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);
                await client
                    .insert(new DClass().setId(Number(row.key)).setKind(Number(value)), {})
                    .then(() => {
                        getDatasetsIds();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        })();
    };

    //! Сохраняем изменение цвета - GRPC
    const handleColor = (row: DataType, value?: string): void => {
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);
                await client
                    .insert(new DClass().setId(Number(row.key)).setColor(new SolidColor().setHexrgb(String(value))), {})
                    .then(() => {
                        getDatasetsIds();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        })();
    };

    //! Добавляем корневой класс - GRPC
    const handleAdd = (): void => {
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);

                // Проверка нового имени + добавление уникального
                let checkedTitle: string;
                for (let idCheck = 0; ; idCheck++) {
                    checkedTitle = idCheck === 0 ? 'Новый корневой класс' : `Новый корневой класс #${idCheck}`;
                    const checkResult = await client.checkUniqTitle(new DClass().setTitle(checkedTitle), {});

                    if (checkResult.getSuccess()) {
                        await client
                            .insert(new DClass().setTitle(checkedTitle).setIspart(false), {})
                            .then(() => {
                                getDatasetsIds();
                                message.success('Новый корневой класс создан!');
                            })
                            .catch((error) => {
                                message.error('Не удалось создать новый корневой класс!');
                                console.error(error);
                            });
                        break;
                    }
                }
            }
        })();
    };

    // Иконки типа классов
    const getClassNameIcon = (value: number | undefined): JSX.Element => {
        switch (value) {
            case 2:
                return <GatewayOutlined />;
            case 1:
                return <AimOutlined />;
            default:
                return <ShareAltOutlined />;
        }
    };

    // Названия типа классов
    const getClassTypeName = (value: number | undefined): string => {
        switch (value) {
            case 2:
                return 'Подвид объекта';
            case 1:
                return 'Часть объекта';
            default:
                return 'Корневой класс';
        }
    };

    // Подсветка красным цветом новых классов
    const getClassNameColor = (value: string | undefined): JSX.Element => {
        if (
            new RegExp(/Новый корневой класс.*/, 'g').test(String(value)) ||
            new RegExp(/Новая часть.*/, 'g').test(String(value)) ||
            new RegExp(/Новый подвид.*/, 'g').test(String(value))
        ) {
            return <div style={{ paddingLeft: '15px', color: '#ff0000' }}>{value}</div>;
        }

        return <div style={{ paddingLeft: '15px' }}>{value}</div>;
    };

    // Опции разметки
    const getKindOptions = (value: number | undefined): JSX.Element => {
        let options: JSX.Element;

        // Если Корневой класс
        if (value === 0) {
            options = (
                <>
                    <Select.Option value={1}>Прямоугольник</Select.Option>
                    <Select.Option value={2}>Полигон</Select.Option>
                    <Select.Option value={3}>Полилиния</Select.Option>
                    <Select.Option value={4}>Точки</Select.Option>
                    <Select.Option value={5}>Эллипс</Select.Option>
                    <Select.Option value={6}>Кубоид</Select.Option>
                    <Select.Option value={7}>Маска</Select.Option>
                    <Select.Option value={8}>Тег</Select.Option>
                </>
            );
        } else {
            options = (
                <>
                    <Select.Option value={1}>Прямоугольник</Select.Option>
                    <Select.Option value={2}>Полигон</Select.Option>
                    <Select.Option value={5}>Эллипс</Select.Option>
                </>
            );
        }

        return options;
    };

    //! Столбцы таблицы
    const columns: ColumnsType<DataType> = [
        {
            title: 'Название класса',
            dataIndex: 'tblName',
            key: 'tblName',
            width: 375,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {getClassNameIcon(record.tblType)}
                    {getClassNameColor(record.tblName)}
                </div>
            ),
            onCell: (record: DataType) => ({
                record,
                editable: true,
                dataIndex: 'tblName',
                title: 'Название класса',
                handleSave,
            }),
        },
        {
            title: 'Разметка',
            dataIndex: 'tblKind',
            key: 'tblKind',
            width: 210,
            render: (_, record) => (
                <Select style={{ width: 200 }} value={record.tblKind} onChange={(val) => handleKind(record, val)}>
                    {getKindOptions(record.tblType)}
                </Select>
            ),
        },
        {
            title: 'Атрибуты',
            dataIndex: 'tblAttribute',
            key: 'tblAttribute',
            width: 155,
            render: (_, record) => (
                <div
                    className='attributeLink'
                    onClick={() => {
                        setCurrentClassNode(record);
                        setAttributeModalOpen(true);
                    }}
                    aria-hidden='true'
                >
                    {record.tblAttribute}
                </div>
            ),
        },
        {
            title: 'Цвет',
            dataIndex: 'tblColor',
            key: 'tblColor',
            width: 145,
            render: (value, record) => (
                <div className='colorContainer'>
                    <ColorPicker
                        onChange={(color) => handleColor(record, color)}
                        value={value}
                        placement='bottom'
                        resetVisible={false}
                    >
                        <div className='colorPickerPreview' style={{ backgroundColor: value }} />
                    </ColorPicker>
                </div>
            ),
        },
        {
            title: 'Тип',
            dataIndex: 'tblType',
            key: 'tblType',
            width: 175,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {getClassTypeName(record.tblType)}
                </div>
            ),
        },
        {
            title: 'Описание',
            dataIndex: 'tblDescription',
            key: 'tblDescription',
            width: 400,
            onCell: (record: DataType) => ({
                record,
                editable: true,
                dataIndex: 'tblDescription',
                title: 'Описание',
                handleSave,
            }),
        },
        {
            title: 'Действия',
            dataIndex: 'tblActions',
            key: 'tblActions',
            width: 100,
            render: (_, record) => {
                // Вызов модального окна - Подтвердить удаление класса
                const showDeleteConfirm = (): void => {
                    confirm({
                        title: 'Вы действительно хотите удалить Класс?',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Внимание! При удалении узла, так же удаляются и все его дочерние классы.',
                        onOk() {
                            handleSave(record, 'delete');
                        },
                        okText: 'Удалить',
                        okType: 'danger',
                        cancelText: 'Отменить',
                    });
                };

                // Вызов модального окна - Перенести в другой узел
                const showMoveToNodeModal = (): void => {
                    setCurrentClassNode(record);
                    setNodeModalOpen(true);
                };

                // Вызов модального окна - Изменить тип класса
                const showTypeEditModal = (): void => {
                    setCurrentClassNode(record);
                    setTypeModalOpen(true);
                };

                const items: MenuProps['items'] = [
                    {
                        key: 'action_1',
                        label: (
                            <div onClick={() => handleSave(record, 'subspecies')} aria-hidden='true'>
                                Создать подвид
                            </div>
                        ),
                        icon: <GatewayOutlined />,
                        disabled: record.tblType === 1,
                    },
                    {
                        key: 'action_2',
                        label: (
                            <div onClick={() => handleSave(record, 'section')} aria-hidden='true'>
                                Создать часть
                            </div>
                        ),
                        icon: <AimOutlined />,
                        disabled: record.tblType === 1,
                    },
                    {
                        key: 'action_3',
                        label: (
                            <div onClick={() => showMoveToNodeModal()} aria-hidden='true'>
                                Перенести в другой узел
                            </div>
                        ),
                        icon: <PullRequestOutlined />,
                    },
                    {
                        key: 'action_4',
                        label: (
                            <div onClick={() => showTypeEditModal()} aria-hidden='true'>
                                Изменить тип класса
                            </div>
                        ),
                        icon: <SyncOutlined />,
                    },
                    {
                        key: 'action_5',
                        danger: true,
                        label: (
                            <div onClick={() => showDeleteConfirm()} aria-hidden='true'>
                                Удалить класс
                            </div>
                        ),
                        icon: <DeleteOutlined />,
                    },
                ];
                return (
                    <Dropdown menu={{ items }}>
                        <div onClick={(e) => e.preventDefault()} aria-hidden='true'>
                            <Space align='center'>
                                <MoreOutlined />
                            </Space>
                        </div>
                    </Dropdown>
                );
            },
        },
    ];

    // useEffect(() => console.log('allObjects', allObjects));

    return (
        <div className='cvat-projects-page'>
            <TopBarComponent handleAdd={handleAdd} />
            <Table
                key={`${Date.now()}_loading-done`}
                dataSource={classesBase}
                columns={columns}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                expandable={{
                    defaultExpandAllRows: true,
                }}
                rowClassName={() => 'editableRow'}
            />
            <MoveToNodeModal
                nodeModalOpen={nodeModalOpen}
                setNodeModalOpen={setNodeModalOpen}
                currentClassNode={currentClassNode}
                setCurrentClassNode={setCurrentClassNode}
            />
            <AttributeModal
                attributeModalOpen={attributeModalOpen}
                setAttributeModalOpen={setAttributeModalOpen}
                currentClassNode={currentClassNode}
                setCurrentClassNode={setCurrentClassNode}
            />
            <TypeEditModal
                typeModalOpen={typeModalOpen}
                setTypeModalOpen={setTypeModalOpen}
                currentClassNode={currentClassNode}
                setCurrentClassNode={setCurrentClassNode}
            />
        </div>
    );
}
