import React from 'react';

import { Select, Typography } from 'antd';

import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';

import {
    Dumper, Project, Task, Job,
} from 'cvat-core-wrapper';

const { Text } = Typography;

interface FormatSelectDatasetProps {
    dumpers: Dumper[];
    instance: Project | Task | Job | null;
    current: string[];
    setDatasetFormat: ((values: string) => void);
    datasetFormat: string;
}

const FormatSelectDataset = (props: FormatSelectDatasetProps): JSX.Element => {
    const {
        dumpers,
        instance,
        current,
        setDatasetFormat,
        datasetFormat,
    } = props;

    return (
        <Select
            onChange={setDatasetFormat}
            virtual={false}
            placeholder='Выберите формат датасета'
            className='cvat-modal-export-select'
            style={{ width: '100%' }}
            value={datasetFormat}
        >
            {dumpers
                .sort((a: Dumper, b: Dumper) => b.name.localeCompare(a.name))
                .filter(
                    (dumper: Dumper): boolean => dumper.dimension === instance?.dimension ||
                        (instance instanceof Project && instance.dimension === null),
                )
                .map((dumper: Dumper): JSX.Element => {
                    const pending = (instance && current ? current : []).includes(dumper.name);
                    const disabled = !dumper.enabled || pending;
                    return (
                        <Select.Option
                            value={dumper.name}
                            key={dumper.name}
                            disabled={disabled}
                            className='cvat-modal-export-option-item'
                        >
                            <DownloadOutlined style={{ color: '#368585' }} />
                            <Text disabled={disabled}>{dumper.name}</Text>
                            {pending && <LoadingOutlined style={{ marginLeft: 10 }} />}
                        </Select.Option>
                    );
                })}
        </Select>
    );
};

export default FormatSelectDataset;
