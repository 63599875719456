import React, { useEffect, useState } from 'react';
import { Row } from 'antd';

import { getClientHost } from 'shared/constants';

import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Empty } from 'proto/Common_pb';
import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

import ColorCorrection from './augmentation-kit/color-correction';
import Rotation from './augmentation-kit/rotation';
import Transformation from './augmentation-kit/transformation';
import Flip from './augmentation-kit/flip';
import Convert from './augmentation-kit/convert';
import { MergeDatasets } from '../../proto/c60/nn/center/DataSets_pb';

interface AugmentationProps {
    datasetsServiceclient: DatasetsServiceClient | undefined;
    setMergeDatasets: React.Dispatch<React.SetStateAction<MergeDatasets.AsObject>>;
}

const Augmentation: React.FC<AugmentationProps> = ({ datasetsServiceclient, setMergeDatasets }) => {
    const [augmentationValues, setAugmentationValues] = useState<YoloAugmentationConfig.AsObject>();
    const [defaultAugmentation, setDefaultAugmentation] = useState<YoloAugmentationConfig.AsObject>();

    const currentHost = getClientHost();

    //! Получаем значения аугментации по умолчанию
    const getAugmentationValues: () => void = async () => {
        if (datasetsServiceclient) {
            const defaultAug = await datasetsServiceclient.getDefaultAugmentation(new Empty(), {});
            setDefaultAugmentation(defaultAug.toObject())
            setAugmentationValues(defaultAug.toObject());
        }
    };

    // Запуск функции получения значений аугментации по умолчанию - GRPC
    useEffect(() => {
        getAugmentationValues();
    }, [datasetsServiceclient]);

    useEffect(() => {
        setMergeDatasets(prev => ({ ...prev, datasetconfig: { ...prev.datasetconfig, augmentation: augmentationValues } }));
    }, [augmentationValues]);

    return (
        <Row gutter={[12, 12]}>
            <ColorCorrection augmentationValues={augmentationValues} setAugmentationValues={setAugmentationValues} defaultAugmentation={defaultAugmentation} />
            <Rotation augmentationValues={augmentationValues} setAugmentationValues={setAugmentationValues} defaultAugmentation={defaultAugmentation} />
            <Transformation augmentationValues={augmentationValues} setAugmentationValues={setAugmentationValues} defaultAugmentation={defaultAugmentation} />
            <Flip augmentationValues={augmentationValues} setAugmentationValues={setAugmentationValues} defaultAugmentation={defaultAugmentation} />
            <Convert augmentationValues={augmentationValues} setAugmentationValues={setAugmentationValues} defaultAugmentation={defaultAugmentation} />
        </Row>
    );
};

export default Augmentation;
