import React, { useState, useEffect } from 'react';
import {
    Col, Row, Slider, Statistic, Switch,
} from 'antd';

import { CombinedState } from 'reducers';

import type { FormValues } from './export-dataset-modal';

interface RangeSliderProps {
    backState: CombinedState;
    initialValues: FormValues;
}

const RangeSlider = (props: RangeSliderProps): JSX.Element => {
    const { backState, initialValues } = props;

    // Общее количество изображений в Проекте/Задаче
    const [projectImagesCount, setProjectImagesCount] = useState<number>(0);
    // Общее количество изображений в Выборке Train (Обучающая)
    const [trainSubsetCount, setTrainSubsetCount] = useState<number>(0);
    // Общее количество изображений в Выборке Validation (Контрольная)
    const [validationSubsetCount, setValidationSubsetCount] = useState<number>(0);
    // Общее количество изображений в Выборке Test (Тестовая)
    const [testSubsetCount, setTestSubsetCount] = useState<number>(0);
    // Начальное значение ползунков в Выборке
    const [defaultSubsetCount, setDefaultSubsetCount] = useState<[number, number] | undefined>();
    // Отрисовка зеленой полосы слайдера Выборки Train (Обучающая)
    const [railColor, setRailColor] = useState<string>('0');
    // Switch выборки - Использовать собственные настройки выборки
    const [customSubset, setCustomSubset] = useState<boolean>(false);
    // Вывод в Tooltip значений количества изображений в выборках
    const formatter = (value: number | undefined): number | undefined => {
        if (value) {
            return value === trainSubsetCount ? value : value - trainSubsetCount;
        }

        return undefined;
    };

    // Вычисляем и указываем состояния информацией с бэка по Проекту
    useEffect(() => {
        // Вычисляем общее количество изображений в Проекте/Задаче
        const imageCount = backState.tasks.current.reduce(
            (accumulator, currentValue) => accumulator + currentValue.size,
            0,
        );
        setProjectImagesCount(imageCount);

        // Вычисляем общее количество изображений в Выборке Train (Обучающая)
        const trainCount = backState.tasks.current.reduce(
            (accumulator, currentValue) => (
                currentValue.subset === 'Train' || currentValue.subset === '' ? accumulator + currentValue.size : accumulator
            ), 0,
        );
        setTrainSubsetCount(trainCount);

        // Вычисляем общее количество изображений в Выборке Validation (Контрольная)
        const validationCount = backState.tasks.current.reduce(
            (accumulator, currentValue) => (
                currentValue.subset === 'Validation' ? accumulator + currentValue.size : accumulator
            ), 0,
        );
        setValidationSubsetCount(validationCount);

        // Вычисляем общее количество изображений в Выборке Test (Тестовая)
        const testCount = backState.tasks.current.reduce(
            (accumulator, currentValue) => (
                currentValue.subset === 'Test' ? accumulator + currentValue.size : accumulator
            ), 0,
        );
        setTestSubsetCount(testCount);

        setDefaultSubsetCount([trainCount, trainCount + validationCount]);
    }, [backState]);

    // Вычисляем процент заполнения Слайдера зеленым цветом Выборки Train (Обучающая)
    useEffect(() => setRailColor(((100 / projectImagesCount) * trainSubsetCount).toFixed()), [trainSubsetCount]);

    // При изменении значения Switch выборки - Подставляем в слайдер значения из проекта
    useEffect(() => {
        if (defaultSubsetCount) {
            setTrainSubsetCount(defaultSubsetCount[0]);
            setValidationSubsetCount(defaultSubsetCount[1] - defaultSubsetCount[0]);
        }
    }, [customSubset]);

    // Добавляем в initialValues информацию по выборке для создания Датасета
    useEffect(() => {
        if (!customSubset) {
            // Если выбрана выборка из проекта - удаляем свойства
            delete initialValues.exportDatasetParams.trainsetimages;
            delete initialValues.exportDatasetParams.validationsetimages;
            delete initialValues.exportDatasetParams.testsetimages;
        } else {
            // Если выбрана кастомная выборка - указываем значения
            initialValues.exportDatasetParams.trainsetimages = trainSubsetCount;
            initialValues.exportDatasetParams.validationsetimages = validationSubsetCount;
            initialValues.exportDatasetParams.testsetimages = testSubsetCount;
        }
    }, [trainSubsetCount, validationSubsetCount, testSubsetCount, customSubset]);

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Switch
                    checked={customSubset}
                    onChange={(value) => setCustomSubset(value)}
                    checkedChildren='УКАЗАНИЕ ЗНАЧЕНИЙ ВЫБОРКИ'
                    unCheckedChildren='ЭКСПОРТИРОВАНА ИЗ ПРОЕКТА'
                />
            </Col>
            <Col span={24}>
                {projectImagesCount ? (
                    <Slider
                        range={{ draggableTrack: true }}
                        value={[trainSubsetCount, trainSubsetCount + validationSubsetCount]}
                        onChange={(value: number[]) => {
                            setRailColor(((100 / projectImagesCount) * value[0]).toFixed());
                            setTrainSubsetCount(value[0]);
                            setValidationSubsetCount(value[1] - value[0]);
                            setTestSubsetCount(projectImagesCount - value[1]);
                        }}
                        min={0}
                        max={projectImagesCount}
                        tooltip={{ formatter }}
                        trackStyle={[{ background: '#384952', height: '7px' }]}
                        railStyle={{
                            background: `linear-gradient(to right, #368585 ${railColor}%, #CECECE 0%)`,
                            borderRadius: '5px',
                            height: 7,
                        }}
                        handleStyle={[
                            {
                                background: '#ffffff',
                                border: 'solid 2px #384952',
                                width: '20px',
                                height: '20px',
                                marginTop: '-7px',
                            },
                            {
                                background: '#ffffff',
                                border: 'solid 2px #384952',
                                width: '20px',
                                height: '20px',
                                marginTop: '-7px',
                            },
                        ]}
                        disabled={!customSubset}
                    />
                ) : (<div style={{ fontWeight: 600 }}>В проекте нет ни одного изображения</div>) }
            </Col>
            <Col span={8}>
                <Statistic title='Обучающая' value={trainSubsetCount} />
            </Col>
            <Col span={8}>
                <Statistic title='Контрольная' value={validationSubsetCount} />
            </Col>
            <Col span={8}>
                <Statistic title='Тестовая' value={testSubsetCount} />
            </Col>
        </Row>
    );
};

export default RangeSlider;
