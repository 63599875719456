import React, { useEffect, useState } from 'react';

import { Dataset, ClassExamples } from 'proto/c60/nn/center/DataSets_pb';

import { Row, Col } from 'antd';

import { ClassesList } from 'components/dataset-page/dataset-page';
import DataseAboutStatisticItemComponent from './about-statistic-item';

interface StatisticStructure {
    classId: number;
    title: string;
    trainExamples: number;
    validationExamples: number;
    testExamples: number;
    countExamples: number;
}

interface DataseAboutClassesStatisticComponentProps {
    datasetData: Dataset.AsObject | undefined;
    classesInfo: ClassesList[] | undefined;
}

const DataseAboutClassesStatisticComponent = (props: DataseAboutClassesStatisticComponentProps): JSX.Element => {
    const { datasetData, classesInfo } = props;

    // Список Классов для вывода статистики
    const [classesStatList, setClassesStatList] = useState<StatisticStructure[] | undefined>(undefined);

    // Формирование списка данных Классов для вывода статистики
    const getClassesStatList = (): void => {
        if (datasetData?.examplesbyclass?.listList.length) {
            const result = datasetData.examplesbyclass.listList.map(
                (element: ClassExamples.AsObject): StatisticStructure => {
                    const statStructure = {
                        classId: element.classid,
                        title: `Класс #${element.classid}`,
                        trainExamples: element.trainexamples,
                        validationExamples: element.validationexamples,
                        testExamples: element.testexamples,
                        countExamples: (element.trainexamples + element.validationexamples + element.testexamples),
                    };

                    if (classesInfo) {
                        for (const findName of classesInfo) {
                            if (findName.id === element.classid) {
                                statStructure.title = findName.title;
                            }
                        }
                    }

                    return statStructure;
                },
            );

            result.sort((a, b) => b.countExamples - a.countExamples);

            setClassesStatList(result);
        }
    };

    useEffect(() => {
        if (datasetData) {
            getClassesStatList();
        }
    }, [datasetData, classesInfo]);

    return (
        <Row gutter={[16, 40]}>
            <Col span={24}>
                <Row gutter={[16, 40]}>
                    <Col span={8}>
                        <div className='dataset-paje-component-chapter'>Статистика по классам</div>
                    </Col>
                    <Col span={16}>
                        {classesStatList ?
                            classesStatList.map((data) => (
                                <DataseAboutStatisticItemComponent
                                    data={data}
                                    maxCount={classesStatList[0].countExamples}
                                />
                            )) :
                            (<div className='dataset-paje-component-augmentation-subpoint'>Классы отсутствуют</div>)}
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DataseAboutClassesStatisticComponent;
