// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'antd/lib/modal';
import Menu from 'antd/lib/menu';
// import { LoadingOutlined } from '@ant-design/icons';
// import { CombinedState } from 'reducers';
import { deleteProjectAsync } from 'actions/projects-actions';
// import { exportActions } from 'actions/export-actions';e
// import { importActions } from 'actions/import-actions';
// import { useHistory } from 'react-router';

interface Props {
    projectInstance: any;
}

export default function ProjectActionsMenuComponent(props: Props): JSX.Element {
    const { projectInstance } = props;

    // const history = useHistory();
    const dispatch = useDispatch();
    // const exportBackupIsActive = useSelector((state: CombinedState) => (
    //     state.export.projects.backup.current[projectInstance.id]
    // ));

    const onDeleteProject = useCallback((): void => {
        Modal.confirm({
            title: `Проект ${projectInstance.name} будет удален`,
            content: 'Все данные (изображения, аннотации) будут потеряны. Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            onOk: () => {
                dispatch(deleteProjectAsync(projectInstance));
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Отменить',
            okText: 'Удалить',
        });
    }, []);

    return (
        <Menu selectable={false} className='cvat-project-actions-menu'>
            {/* <Menu.Item
                key='export-dataset'
                onClick={() => dispatch(exportActions.openExportDatasetModal(projectInstance))}
            >
                Создать датасет
            </Menu.Item>
            <Menu.Divider /> */}
            <Menu.Item key='delete' onClick={onDeleteProject}>
                Удалить
            </Menu.Item>
        </Menu>
    );
}
