import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

const { Panel } = Collapse;

interface ConvertProps {
    augmentationValues: YoloAugmentationConfig.AsObject | undefined;
    setAugmentationValues: React.Dispatch<React.SetStateAction<YoloAugmentationConfig.AsObject | undefined>>;
    defaultAugmentation: YoloAugmentationConfig.AsObject | undefined;
}

const Convert: React.FC<ConvertProps> = ({ augmentationValues, setAugmentationValues, defaultAugmentation}): JSX.Element => {
    const [checkedConvert, setCheckedConvert] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    // Конвертация значений аугментации по умолчанию в значения слайдера
    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    // Заносим значения по умолчанию, полученные с gRPC
    useEffect(() => {
        if (augmentationValues && (augmentationValues.mosaic > 0 || augmentationValues.mixup > 0 || augmentationValues.copyPaste > 0)) {
            setCheckedConvert(true);
        } else {
            setCheckedConvert(false);
        }
    }, [augmentationValues]);

    const setValue = (valueType: 'mosaic' | 'mixup' | 'copyPaste', value: number) => {
        let newValue = value;
        if (value && value > 100) newValue = 100;
        if (value && value < 0) newValue = 0;
        setAugmentationValues(prev => prev ? { ...prev, [valueType]: newValue / 100 } : undefined);
    };

    const switchTransformation = (value: boolean) => {
        if (!value) {
            setAugmentationValues(prev => prev ? { ...prev, mosaic: 0, mixup: 0, copyPaste: 0 } : undefined);
        } else {
            if (defaultAugmentation) {
                const mosaic = defaultAugmentation.mosaic;
                const mixup = defaultAugmentation.mixup;
                const copyPaste = defaultAugmentation.copyPaste;
                setAugmentationValues(prev => prev ? { ...prev, mosaic, mixup, copyPaste } : undefined);
            }
        }
        setCheckedConvert(value);
    };

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Преобразование</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedConvert}
                                onChange={switchTransformation}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_5'
                >
                    <div>
                        Добавьте преобразование для изображений, чтобы ваша модель имела
                        более высокую устойчивость к изменению окружающей среды, в которой
                        находится объект.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Мозайка
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.mosaic || 0)}
                                        onChange={(value: number) => setValue('mosaic', value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.mosaic || 0)}
                                        onBlur={event => setValue('mosaic', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Смешивание
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.mixup || 0)}
                                        onChange={(value: number) => setValue('mixup', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.mixup || 0)}
                                        onBlur={event => setValue('mixup', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Случайная вставка
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.copyPaste || 0)}
                                        onChange={(value: number) => setValue('copyPaste', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.copyPaste || 0)}
                                        onBlur={event => setValue('copyPaste', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Convert;
