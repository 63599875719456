import { ObjectId } from 'proto/Common_pb';
import { TrainingQueryServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { QueryState, TrainingGraphics } from 'proto/c60/nn/center/Detectors_pb';
import React from 'react';
import { getClientHost } from 'shared/constants';

const useGraphics = (id: number, state?: QueryState) => {
    const host = getClientHost();

    const [graphics, setGraphics] = React.useState<TrainingGraphics.AsObject>();

    const client = new TrainingQueryServiceClient(host ?? '');

    React.useEffect(() => {
        const getTrainGraphics = async (id: number) => {
            const res = await client.getTrainGraphics(new ObjectId().setId(id), null);

            setGraphics(res.toObject());

            return res.toObject();
        };

        if (id) getTrainGraphics(id);

        if (id && state && state === QueryState.RUNNING) {
            const interval = setInterval(() => {
                console.log('Updated graphics after 10 sec');
                getTrainGraphics(id);
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [id, state]);

    return {
        graphics,
    };
};

export default useGraphics;
