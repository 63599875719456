import React, { useEffect, useState } from 'react';
import { Row } from 'antd';

import { getClientHost } from 'shared/constants';

import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Empty } from 'proto/Common_pb';
import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';
import { createEnhancedClient } from '../../utils/grpc';

import type { FormValues } from './export-dataset-modal';

import ColorCorrection from './augmentation-kit/color-correction';
import Rotation from './augmentation-kit/rotation';
import Transformation from './augmentation-kit/transformation';
import Flip from './augmentation-kit/flip';
import Convert from './augmentation-kit/convert';

interface AugmentationProps {
    initialValues: FormValues;
}

const Augmentation = (props: AugmentationProps): JSX.Element => {
    const { initialValues } = props;

    const [augmintationValues, setAugmentationValues] = useState<YoloAugmentationConfig.AsObject>();

    const currentHost = getClientHost();

    //! Получаем значения аугментации по умолчанию
    const getAugmentationValues: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const defaultAugmentation = await client.getDefaultAugmentation(new Empty(), {});
            setAugmentationValues(defaultAugmentation.toObject());

            // Добавляем в initialValues значения аугментации по умолчанию для создания Датасета
            initialValues.exportDatasetParams.augmentation = defaultAugmentation.toObject();
        }
    };

    // Запуск функции получения значений аугментации по умолчанию - GRPC
    useEffect(() => {
        getAugmentationValues();
    }, []);

    return (
        <Row gutter={[12, 12]}>
            <ColorCorrection initialValues={initialValues} augmintationValues={augmintationValues} />
            <Rotation initialValues={initialValues} augmintationValues={augmintationValues} />
            <Transformation initialValues={initialValues} augmintationValues={augmintationValues} />
            <Flip initialValues={initialValues} augmintationValues={augmintationValues} />
            <Convert initialValues={initialValues} augmintationValues={augmintationValues} />
        </Row>
    );
};

export default Augmentation;
