import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { getClientHost } from 'shared/constants';
import { TrainingRunnerServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { TrainingRunner } from 'proto/c60/nn/center/Detectors_pb';

type EditableCellProps = {
    record: TrainingRunner.AsObject;
    value: string;
    refresh(): Promise<void>;
    cellKey: string;
};

const EditableCell = ({
    record, value, refresh, cellKey,
}: EditableCellProps): JSX.Element => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [form] = useForm();
    const currentHost = getClientHost();

    const [cellHelp, setCellHelp] = useState<'Введите название!' | 'Введите уникальное название!' | ''>('');
    const [cellStatus, setCellStatus] = useState<'error' | ''>('');

    useEffect(() => {
        if (value) {
            form.setFieldValue('value', value);
        }
    }, [value]);

    const handleValidate = async (): Promise<void> => {
        if (currentHost) {
            if (!form?.getFieldValue('value') || form?.getFieldValue('value')?.length === 0) {
                setCellHelp('Введите название!');
                setCellStatus('error');
            }

            if (!(!form?.getFieldValue('value') || form?.getFieldValue('value')?.length === 0)) {
                const client = new TrainingRunnerServiceClient(currentHost);
                const newService = new TrainingRunner().setId(record.id || 0);

                switch (cellKey) {
                    case 'title': {
                        newService.setTitle(form.getFieldValue('value')).setAddress(record.address || '');
                        const checkUniqTitleService = await client.checkUniqName(newService, {});

                        if (!checkUniqTitleService.getSuccess()) {
                            setCellHelp('Введите уникальное название!');
                            setCellStatus('error');
                        }

                        if (checkUniqTitleService.getSuccess()) {
                            try {
                                await client.insert(newService, {});
                            } catch (e) {
                                console.log(e);
                            } finally {
                                setIsEditing(false);
                                refresh();
                            }
                        }

                        break;
                    }

                    case 'address': {
                        newService.setAddress(form.getFieldValue('value')).setTitle(record.title || '');
                        const checkUniqAddressService = await client.checkUniqAddress(newService, {});

                        if (!checkUniqAddressService.getSuccess()) {
                            setCellHelp('Введите уникальное название!');
                            setCellStatus('error');
                        }

                        if (checkUniqAddressService.getSuccess()) {
                            try {
                                await client.insert(newService, {});
                            } catch (e) {
                                console.log(e);
                            } finally {
                                setIsEditing(false);
                                refresh();
                            }
                        }

                        break;
                    }

                    default:
                }
            }
        }
    };

    return (
        <div className='editable-container'>
            {isEditing ? (
                <Form form={form}>
                    <Form.Item help={cellHelp} status={cellStatus} name='value'>
                        <Input
                            onBlur={() => setIsEditing(false)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleValidate();
                                }
                            }}
                            autoFocus
                        />
                    </Form.Item>
                </Form>
            ) : (
                <div
                    role='button'
                    tabIndex={0}
                    className='editable-cell'
                    onKeyDown={() => setIsEditing(true)}
                    onClick={() => setIsEditing(true)}
                >
                    {value}
                </div>
            )}
        </div>
    );
};

export default EditableCell;
