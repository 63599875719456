import React, { useEffect, useState } from 'react';

import { Row, Col } from 'antd';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import DatasetSubsetHeaderComponent from './components/subset/subset-header';
import DatasetSubsetFiltersComponent from './components/subset/subset-filters';
import DatasetSubsetImagesComponent from './components/subset/subset-images';

interface ClassesList {
    id: number;
    title: string;
    color: string
}

interface ClassesListForFilter {
    title: string;
    value: number;
    children?: ClassesListForFilter[];
    color?: string;
}

interface DatasetSubsetPageProps {
    datasetID?: number[];
    datasetData?: Dataset.AsObject;
    classesInfo?: ClassesList[];
}

const DatasetSubsetPage = (props: DatasetSubsetPageProps): JSX.Element => {
    const { datasetID, datasetData, classesInfo } = props;

    // Список Классов указанных в фильтре, для вывода изображений
    const [currentFilterClasses, setCurrentFilterClasses] = useState<number[]>([]);
    // Выборка указанная в фильтре, для вывода изображений
    const [currentFilterSubsets, setCurrentFilterSubsets] = useState<number>(0);
    // Список Классов с параметрами, для разметки на изображении
    const [datasetClassesList, setDatasetClassesList] = useState<ClassesListForFilter[]>([]);

    // Формируем список Классов для разметки на изображениях
    useEffect(() => {
        if (datasetData && classesInfo) {
            // Переменная для формирования списка классов с их id
            const classesListFilter = [] as ClassesListForFilter[];

            // Формируем список Классов с их id для фильтра
            datasetData.examplesbyclass?.listList.map((element) => {
                for (const find of classesInfo) {
                    if (element.classid === find.id) {
                        classesListFilter.push({ title: find.title, value: find.id, color: find.color });

                        return element;
                    }
                }

                // Если класса с указанным ID нет в базе
                classesListFilter.push({ title: `Класс #${element.classid}`, value: element.classid, color: '#999999' });

                return element;
            });

            setDatasetClassesList(classesListFilter);
        }
    }, [datasetData, classesInfo]);

    // Формируем список Классов указанных в фильтре
    useEffect(() => {
        const getAllDatasetClassesId: number[] = datasetClassesList.map((element): number => element.value);
        setCurrentFilterClasses(getAllDatasetClassesId);
    }, [datasetClassesList]);

    return (
        <Row gutter={[16, 40]}>
            <Col span={24}>
                <DatasetSubsetHeaderComponent />
            </Col>
            <Col span={24}>
                <DatasetSubsetFiltersComponent
                    datasetClassesList={datasetClassesList}
                    setCurrentFilterClasses={setCurrentFilterClasses}
                    setCurrentFilterSubsets={setCurrentFilterSubsets}
                />
            </Col>
            <Col span={24}>
                <DatasetSubsetImagesComponent
                    currentFilterClasses={currentFilterClasses}
                    currentFilterSubsets={currentFilterSubsets}
                    datasetClassesList={datasetClassesList}
                    datasetID={datasetID}
                    datasetData={datasetData}
                />
            </Col>
        </Row>
    );
};

export default DatasetSubsetPage;
