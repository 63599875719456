/**
 * @fileoverview gRPC-Web generated client stub for grpc.c60.nn.center
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.2
// source: c60/nn/center/DataSets.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../../../Common_pb';
import * as c60_nn_center_DataSets_pb from '../../../c60/nn/center/DataSets_pb';
import * as nn_training_DetectorTraining_pb from '../../../nn_training/DetectorTraining_pb';


export class DatasetsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetDefaultAugmentation = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/getDefaultAugmentation',
    grpcWeb.MethodType.UNARY,
    Common_pb.Empty,
    nn_training_DetectorTraining_pb.YoloAugmentationConfig,
    (request: Common_pb.Empty) => {
      return request.serializeBinary();
    },
    nn_training_DetectorTraining_pb.YoloAugmentationConfig.deserializeBinary
  );

  getDefaultAugmentation(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null): Promise<nn_training_DetectorTraining_pb.YoloAugmentationConfig>;

  getDefaultAugmentation(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: nn_training_DetectorTraining_pb.YoloAugmentationConfig) => void): grpcWeb.ClientReadableStream<nn_training_DetectorTraining_pb.YoloAugmentationConfig>;

  getDefaultAugmentation(
    request: Common_pb.Empty,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: nn_training_DetectorTraining_pb.YoloAugmentationConfig) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/getDefaultAugmentation',
        request,
        metadata || {},
        this.methodDescriptorgetDefaultAugmentation,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/getDefaultAugmentation',
    request,
    metadata || {},
    this.methodDescriptorgetDefaultAugmentation);
  }

  methodDescriptorgetDatasetImages = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/getDatasetImages',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DataSets_pb.ViewImagesCondition,
    c60_nn_center_DataSets_pb.MarkedImageList,
    (request: c60_nn_center_DataSets_pb.ViewImagesCondition) => {
      return request.serializeBinary();
    },
    c60_nn_center_DataSets_pb.MarkedImageList.deserializeBinary
  );

  getDatasetImages(
    request: c60_nn_center_DataSets_pb.ViewImagesCondition,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_DataSets_pb.MarkedImageList>;

  getDatasetImages(
    request: c60_nn_center_DataSets_pb.ViewImagesCondition,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.MarkedImageList) => void): grpcWeb.ClientReadableStream<c60_nn_center_DataSets_pb.MarkedImageList>;

  getDatasetImages(
    request: c60_nn_center_DataSets_pb.ViewImagesCondition,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.MarkedImageList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/getDatasetImages',
        request,
        metadata || {},
        this.methodDescriptorgetDatasetImages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/getDatasetImages',
    request,
    metadata || {},
    this.methodDescriptorgetDatasetImages);
  }

  methodDescriptorcreateYoloDatasetFromProject = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/createYoloDatasetFromProject',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DataSets_pb.CreateYoloDatasetParams,
    c60_nn_center_DataSets_pb.Dataset,
    (request: c60_nn_center_DataSets_pb.CreateYoloDatasetParams) => {
      return request.serializeBinary();
    },
    c60_nn_center_DataSets_pb.Dataset.deserializeBinary
  );

  createYoloDatasetFromProject(
    request: c60_nn_center_DataSets_pb.CreateYoloDatasetParams,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_DataSets_pb.Dataset>;

  createYoloDatasetFromProject(
    request: c60_nn_center_DataSets_pb.CreateYoloDatasetParams,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.Dataset) => void): grpcWeb.ClientReadableStream<c60_nn_center_DataSets_pb.Dataset>;

  createYoloDatasetFromProject(
    request: c60_nn_center_DataSets_pb.CreateYoloDatasetParams,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.Dataset) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/createYoloDatasetFromProject',
        request,
        metadata || {},
        this.methodDescriptorcreateYoloDatasetFromProject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/createYoloDatasetFromProject',
    request,
    metadata || {},
    this.methodDescriptorcreateYoloDatasetFromProject);
  }

  methodDescriptormergeDatasets = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/mergeDatasets',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DataSets_pb.MergeDatasets,
    c60_nn_center_DataSets_pb.Dataset,
    (request: c60_nn_center_DataSets_pb.MergeDatasets) => {
      return request.serializeBinary();
    },
    c60_nn_center_DataSets_pb.Dataset.deserializeBinary
  );

  mergeDatasets(
    request: c60_nn_center_DataSets_pb.MergeDatasets,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_DataSets_pb.Dataset>;

  mergeDatasets(
    request: c60_nn_center_DataSets_pb.MergeDatasets,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.Dataset) => void): grpcWeb.ClientReadableStream<c60_nn_center_DataSets_pb.Dataset>;

  mergeDatasets(
    request: c60_nn_center_DataSets_pb.MergeDatasets,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.Dataset) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/mergeDatasets',
        request,
        metadata || {},
        this.methodDescriptormergeDatasets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/mergeDatasets',
    request,
    metadata || {},
    this.methodDescriptormergeDatasets);
  }

  methodDescriptorgetAllAvailableDatasets = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/getAllAvailableDatasets',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DataSets_pb.DatasetsCondition,
    Common_pb.ObjectsId,
    (request: c60_nn_center_DataSets_pb.DatasetsCondition) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAllAvailableDatasets(
    request: c60_nn_center_DataSets_pb.DatasetsCondition,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAllAvailableDatasets(
    request: c60_nn_center_DataSets_pb.DatasetsCondition,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAllAvailableDatasets(
    request: c60_nn_center_DataSets_pb.DatasetsCondition,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/getAllAvailableDatasets',
        request,
        metadata || {},
        this.methodDescriptorgetAllAvailableDatasets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/getAllAvailableDatasets',
    request,
    metadata || {},
    this.methodDescriptorgetAllAvailableDatasets);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    c60_nn_center_DataSets_pb.DatasetList,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    c60_nn_center_DataSets_pb.DatasetList.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_DataSets_pb.DatasetList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.DatasetList) => void): grpcWeb.ClientReadableStream<c60_nn_center_DataSets_pb.DatasetList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_DataSets_pb.DatasetList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

  methodDescriptorcheckUniqName = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DatasetsService/checkUniqName',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_DataSets_pb.Dataset,
    Common_pb.Check,
    (request: c60_nn_center_DataSets_pb.Dataset) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkUniqName(
    request: c60_nn_center_DataSets_pb.Dataset,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkUniqName(
    request: c60_nn_center_DataSets_pb.Dataset,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkUniqName(
    request: c60_nn_center_DataSets_pb.Dataset,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DatasetsService/checkUniqName',
        request,
        metadata || {},
        this.methodDescriptorcheckUniqName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DatasetsService/checkUniqName',
    request,
    metadata || {},
    this.methodDescriptorcheckUniqName);
  }

}

