// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import InputNumber from 'antd/lib/input-number';
import Checkbox from 'antd/lib/checkbox';
import Collapse from 'antd/lib/collapse';

// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import CVATTooltip from 'components/common/cvat-tooltip';
import { getCore } from 'cvat-core-wrapper';
import { JobStage } from 'reducers';

const core = getCore();

interface Props {
    taskMode: string;
    jobInstance: any;
    onClickMenu(params: MenuInfo): void;
    stopFrame: number;
    removeAnnotations(startnumber: number, endnumber: number, delTrackKeyframesOnly:boolean): void;
    setForceExitAnnotationFlag(forceExit: boolean): void;
    saveAnnotations(jobInstance: any, afterSave?: () => void): void;
}

export enum Actions {
    LOAD_JOB_ANNO = 'load_job_anno',
    EXPORT_JOB_DATASET = 'export_job_dataset',
    REMOVE_ANNO = 'remove_anno',
    OPEN_TASK = 'open_task',
    FINISH_JOB = 'finish_job',
    RENEW_JOB = 'renew_job',
}

function AnnotationMenuComponent(props: Props & RouteComponentProps): JSX.Element {
    const {
        jobInstance,
        stopFrame,
        history,
        onClickMenu,
        removeAnnotations,
        setForceExitAnnotationFlag,
        saveAnnotations,
    } = props;

    const jobStage = jobInstance.stage;
    const jobState = jobInstance.state;
    const taskID = jobInstance.taskId;
    const { JobState } = core.enums;

    function onClickMenuWrapper(params: MenuInfo): void {
        function checkUnsavedChanges(_params: MenuInfo): void {
            if (jobInstance.annotations.hasUnsavedChanges()) {
                Modal.confirm({
                    title: 'Разметка содержит несохраненные изменения',
                    content: 'Хотели бы вы сохранить изменения, прежде чем продолжить?',
                    className: 'cvat-modal-content-save-job',
                    okButtonProps: {
                        children: 'Сохранить',
                    },
                    cancelButtonProps: {
                        children: 'Нет',
                    },
                    onOk: () => {
                        saveAnnotations(jobInstance, () => onClickMenu(_params));
                    },
                    onCancel: () => {
                        // do not ask leave confirmation
                        setForceExitAnnotationFlag(true);
                        setTimeout(() => {
                            onClickMenu(_params);
                        });
                    },
                });
            } else {
                onClickMenu(_params);
            }
        }

        if (params.key === Actions.REMOVE_ANNO) {
            let removeFrom: number | null;
            let removeUpTo: number | null;
            let removeOnlyKeyframes = false;
            const { Panel } = Collapse;
            Modal.confirm({
                title: 'Удалить аннотацию',
                content: (
                    <div>
                        <Text>Вы собираетесь удалить аннотации из клиента. </Text>
                        <Text>Они останутся на сервере до тех пор, пока вы не сохраните задание. Продолжить?</Text>
                        <br />
                        <br />
                        <Collapse bordered={false}>
                            <Panel header={<Text>Выберите диапазон</Text>} key={1}>
                                <Text>From: </Text>
                                <InputNumber
                                    min={0}
                                    max={stopFrame}
                                    onChange={(value) => {
                                        removeFrom = value;
                                    }}
                                />
                                <Text>  To: </Text>
                                <InputNumber
                                    min={0}
                                    max={stopFrame}
                                    onChange={(value) => { removeUpTo = value; }}
                                />
                                <CVATTooltip title='Применимо только для аннотаций в диапазоне'>
                                    <br />
                                    <br />
                                    <Checkbox
                                        onChange={(check) => {
                                            removeOnlyKeyframes = check.target.checked;
                                        }}
                                    >
                                        Удалить только ключевые кадры
                                    </Checkbox>
                                </CVATTooltip>
                            </Panel>
                        </Collapse>
                    </div>
                ),
                className: 'cvat-modal-confirm-remove-annotation',
                onOk: () => {
                    removeAnnotations(removeFrom, removeUpTo, removeOnlyKeyframes);
                },
                okButtonProps: {
                    type: 'primary',
                    danger: true,
                },
                okText: 'Удалить',
                cancelText: 'Отмена',
            });
        } else if (params.key.startsWith('state:')) {
            Modal.confirm({
                title: 'Вы хотите изменить текущее состояние задания?',
                content: `Состояние задания будет переключено в "${params.key.split(':')[1]}". Продолжить?`,
                okText: 'Продолжить',
                cancelText: 'Отменить',
                className: 'cvat-modal-content-change-job-state',
                onOk: () => {
                    checkUnsavedChanges(params);
                },
            });
        } else if (params.key === Actions.FINISH_JOB) {
            Modal.confirm({
                title: 'Этап выполнения задания будет переключен',
                content: 'Этап будет изменен на "Принят". Хотели бы вы продолжить?',
                okText: 'Продолжить',
                cancelText: 'Отменить',
                className: 'cvat-modal-content-finish-job',
                onOk: () => {
                    checkUnsavedChanges(params);
                },
            });
        } else if (params.key === Actions.RENEW_JOB) {
            Modal.confirm({
                title: 'Вы хотите возобновить работу?',
                content: 'Этапу будет присвоено значение "Выполняется", состоянию будет присвоено значение "Аннотация". Хотели бы вы продолжить?',
                okText: 'Продолжить',
                cancelText: 'Отменить',
                className: 'cvat-modal-content-renew-job',
                onOk: () => {
                    onClickMenu(params);
                },
            });
        } else {
            onClickMenu(params);
        }
    }

    const computeClassName = (menuItemState: string): string => {
        if (menuItemState === jobState) return 'cvat-submenu-current-job-state-item';
        return '';
    };

    return (
        <Menu onClick={(params: MenuInfo) => onClickMenuWrapper(params)} className='cvat-annotation-menu' selectable={false}>
            {/* <Menu.Item key={Actions.LOAD_JOB_ANNO}>Upload annotations</Menu.Item> */}
            {/* <Menu.Item key={Actions.EXPORT_JOB_DATASET}>Export job dataset</Menu.Item> */}
            <Menu.Item key={Actions.REMOVE_ANNO}>Удалить аннотацию</Menu.Item>
            <Menu.Item key={Actions.OPEN_TASK}>
                <a
                    href={`/tasks/${taskID}`}
                    onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        history.push(`/tasks/${taskID}`);
                        return false;
                    }}
                >
                    Открыть задачу
                </a>
            </Menu.Item>
            <Menu.SubMenu popupClassName='cvat-annotation-menu-job-state-submenu' key='job-state-submenu' title='Изменить состояние разметки'>
                <Menu.Item key={`state:${JobState.NEW}`}>
                    <Text className={computeClassName(JobState.NEW)}>Новая</Text>
                </Menu.Item>
                <Menu.Item key={`state:${JobState.IN_PROGRESS}`}>
                    <Text className={computeClassName(JobState.IN_PROGRESS)}>В процессе</Text>
                </Menu.Item>
                <Menu.Item key={`state:${JobState.REJECTED}`}>
                    <Text className={computeClassName(JobState.REJECTED)}>Отклонена</Text>
                </Menu.Item>
                <Menu.Item key={`state:${JobState.COMPLETED}`}>
                    <Text className={computeClassName(JobState.COMPLETED)}>Завершена</Text>
                </Menu.Item>
            </Menu.SubMenu>
            {[JobStage.ANNOTATION, JobStage.REVIEW].includes(jobStage) ?
                <Menu.Item key={Actions.FINISH_JOB}>Завершить разметку</Menu.Item> : null}
            {jobStage === JobStage.ACCEPTANCE ?
                <Menu.Item key={Actions.RENEW_JOB}>Возобновить разметку</Menu.Item> : null}
        </Menu>
    );
}

export default withRouter(AnnotationMenuComponent);
