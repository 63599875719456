import './styles.css';
import React from 'react';
import { useHistory } from 'react-router';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { Button, Dropdown, Typography } from 'antd';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';

import DatasetActionsMenu from 'components/datasets-page/actions-menu';

const { Text } = Typography;

interface DatasetTopBarProps {
    datasetID: number[] | undefined;
    datasetData: Dataset.AsObject | undefined;
}

const DatasetTopBar = (props: DatasetTopBarProps): JSX.Element => {
    const { datasetID, datasetData } = props;
    const history = useHistory();

    return (
        <div className='cvat-task-top-bar'>
            <div>
                <Button
                    className='cvat-page-actions-button'
                    onClick={() => history.push('/datasets')}
                    type='default'
                    size='middle'
                >
                    <LeftOutlined />
                    Назад к датасетам
                </Button>
            </div>
            <div className='cvat-project-top-bar-actions-buttons'>
                <Dropdown overlay={<DatasetActionsMenu datasetID={datasetID} datasetData={datasetData} />}>
                    <Button size='middle' className='cvat-page-actions-button wo-mrgn-icon'>
                        <MoreOutlined />
                        <Text className='cvat-text-color'>Действия</Text>
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
};

export default DatasetTopBar;
