import React, { useEffect, useState } from 'react';
import './styles.scss';
import { ImportOutlined, PlusOutlined, SisternodeOutlined } from '@ant-design/icons';
import { Button, Tabs, Typography, notification } from 'antd';
import { useHistory } from 'react-router';
import { TrainingQuery, TrainingRunner } from 'proto/c60/nn/center/Detectors_pb';
import { StringSearch } from 'proto/Common_pb';
import { TrainingQueryServiceClient, TrainingRunnerServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { getClientHost } from 'shared/constants';
import { createEnhancedClient } from 'utils/grpc';
import TrainingServiceTable from './trainingservice/trainingservice-table';
import CreateServiceModal from './create-service-modal';
import TrainingQueryTable from './trainingquery/trainingquery-table';

const LearningPage = (): JSX.Element => {
    const history = useHistory();

    const [currentTabActiveKey, setCurrentTabActiveKey] = useState<'TrainingService' | 'TrainingQuery'>();

    const currentHost = getClientHost();

    // trainingqueries

    const [loadTrainingQueryTableDataSpin, setLoadTrainingQueryTableDataSpin] = useState<boolean>(false);

    const [trainingQueryData, setTrainingQueryData] = useState<TrainingQuery.AsObject[]>();

    const getTrainingQueries = async (): Promise<void> => {
        if (currentHost) {
            setLoadTrainingQueryTableDataSpin(true);
            const client = createEnhancedClient(TrainingQueryServiceClient);
            try {
                const queriesList = (
                    await client.getInfo(await client.getAvailable(new StringSearch(), {}), {})
                ).toObject().listList;
                setTrainingQueryData(queriesList as TrainingQuery.AsObject[]);
                setLoadTrainingQueryTableDataSpin(false);
            } catch (e: any) {
                notification.error({
                    message: 'Не удалось получить процесс обучения нейросетей',
                    description: e.message,
                });
            } finally {
                setLoadTrainingQueryTableDataSpin(false);
            }
        }
    };

    // trainingservices

    const [openCreateServiceModal, setOpenCreateServiceModal] = useState<boolean>(false);

    const [loadTrainingServiceTableDataSpin, setLoadTrainingServiceTableDataSpin] = useState<boolean>(false);
    const [trainingServiceData, setTrainingServiceData] = useState<TrainingRunner.AsObject[]>();

    const getTrainingServices = async (): Promise<void> => {
        if (currentHost) {
            setLoadTrainingServiceTableDataSpin(true);
            const client = createEnhancedClient(TrainingRunnerServiceClient);
            try {
                const servicesList = (
                    await client.getInfo(await client.getAvailable(new StringSearch(), {}), {})
                ).toObject().listList;

                setTrainingServiceData(servicesList as TrainingRunner.AsObject[]);
                setLoadTrainingServiceTableDataSpin(false);
            } catch (e: any) {
                notification.error({
                    message: 'Не удалось получить реестр сервисов для обучения',
                    description: e.message,
                });
            } finally {
                setLoadTrainingServiceTableDataSpin(false);
            }
        }
    };

    useEffect(() => {
        getTrainingQueries();
        getTrainingServices();
    }, []);

    return (
        <>
            <div className='learning-page'>
                <div className='header-container'>
                    <Typography.Text className='header-text'>Обучение нейросетей</Typography.Text>
                    <div className='buttons-container'>
                        <Button
                            type='primary'
                            icon={<PlusOutlined />}
                            className='header-button'
                            onClick={() => history.push('/learning/create')}
                        >
                            Обучить нейросеть
                        </Button>
                        <Button
                            type='primary'
                            icon={<SisternodeOutlined />}
                            className='header-button'
                            onClick={() => history.push('/learning/retrain')}
                        >
                            Дообучить нейросеть
                        </Button>
                        <Button
                            type='primary'
                            icon={<ImportOutlined />}
                            className='header-button'
                            onClick={() => setOpenCreateServiceModal(true)}
                        >
                            Добавить сервис
                        </Button>
                    </div>
                </div>
                <div className='tabs-container'>
                    <Tabs
                        onChange={(e) => setCurrentTabActiveKey(e as 'TrainingService' | 'TrainingQuery')}
                        activeKey={currentTabActiveKey}
                        items={[
                            {
                                label: <div className='tab-element'>Процесс обучения нейросетей</div>,
                                key: 'TrainingQuery',
                                children: (
                                    <TrainingQueryTable
                                        refresh={getTrainingQueries}
                                        dataSource={trainingQueryData}
                                        setDataSource={setTrainingQueryData}
                                        loading={loadTrainingQueryTableDataSpin}
                                    />
                                ),
                            },
                            {
                                label: <div className='tab-element'>Реестр сервисов для обучения</div>,
                                key: 'TrainingService',
                                children: (
                                    <TrainingServiceTable
                                        refresh={getTrainingServices}
                                        dataSource={trainingServiceData}
                                        setDataSource={setTrainingServiceData}
                                        loading={loadTrainingServiceTableDataSpin}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <CreateServiceModal
                refresh={getTrainingServices}
                isModalOpen={openCreateServiceModal}
                setIsModalOpen={setOpenCreateServiceModal}
                setCurrentTabActiveKey={setCurrentTabActiveKey}
            />
        </>
    );
};

export default React.memo(LearningPage);
