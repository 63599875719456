// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Text from 'antd/lib/typography/Text';

import CreateProjectContent from './create-project-content';

function CreateProjectPageComponent(): JSX.Element {
    return (
        <div className='cvat-create-project-form-wrapper'>
            <Text className='cvat-title'>Создать новый проект</Text>
            <CreateProjectContent />
        </div>
    );
}

export default React.memo(CreateProjectPageComponent);
