import React, { useState, useEffect } from 'react';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { Row, Col, Statistic } from 'antd';

import { ClassesList } from 'components/dataset-page/dataset-page';
import ClassiViewerItem from './class-label-item';

interface DataseAboutClassesComponentProps {
    datasetData: Dataset.AsObject | undefined;
    classesInfo: ClassesList[] | undefined;
}

const DataseAboutClassesComponent = (props: DataseAboutClassesComponentProps): JSX.Element => {
    const { datasetData, classesInfo } = props;

    // Сумма Классов в датасете
    const [classesCount, setClassesCount] = useState<number>(0);
    // Тэги Классов
    const [classesLabels, setClassesLabels] = useState<JSX.Element[] | undefined>(undefined);

    useEffect(() => {
        if (datasetData) {
            // Вычисляем количество Классов в датасете
            if (datasetData.examplesbyclass?.listList.length) {
                setClassesCount(datasetData.examplesbyclass.listList.length);
            }

            // Формируем список Тэгов Классов
            const classesListElement = datasetData.examplesbyclass?.listList.map((element) => {
                if (classesInfo) {
                    for (const find of classesInfo) {
                        if (element.classid === find.id) {
                            return <ClassiViewerItem label={find.title} color={find.color} />;
                        }
                    }
                }

                return <ClassiViewerItem label={`Класс #${element.classid}`} />;
            });

            setClassesLabels(classesListElement);
        }
    }, [datasetData, classesInfo]);

    return (
        <Row gutter={[16, 40]}>
            <Col span={8}>
                <div className='dataset-paje-component-chapter'>Классы сущностей</div>
            </Col>
            <Col span={16}>
                <Row gutter={[16, 30]}>
                    <Col span={24}>
                        <Statistic title='Количество классов' value={classesCount} />
                    </Col>
                    <Col span={24}>
                        <div className='dataset-paje-component-classes-tags'>
                            {classesLabels || ''}
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DataseAboutClassesComponent;
