import React from 'react';

const DatasetSubsetHeaderComponent = (): JSX.Element => (
    <div>
        <div className='dataset-paje-component-header'>Просмотр изображений датасета</div>
        <div className='dataset-paje-component-header-gray-text'>
            Параметры фильтрации:
        </div>
    </div>
);

export default DatasetSubsetHeaderComponent;
