import React from 'react';
import './styles.scss';
import { Button, Card, Divider, Modal, Popover, Tag, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import DatasetForm from './dataset-form';
import LearningParams from './learning-params';
import LearningInfo from './learning-info';
import useDatasetInfo from '../api/useDatasetInfo';
import useDetectorService from '../api/useTrainingService';
import useTraining from '../api/useTraining';
import { QueryState } from 'proto/c60/nn/center/Detectors_pb';

const { confirm } = Modal;

const ModelDetails = () => {
    const { id } = useParams<{ id: string }>();

    const { finish, cancel, trainingQuery } = useTraining(Number(id));

    // Данные по датасету
    const { dataset } = useDatasetInfo(trainingQuery?.datasetid);

    const [ellipsis, setEllipsis] = React.useState(true);

    const showFinishConfirm = () => {
        confirm({
            title: 'Вы хотите завершить обучение модели?',
            onOk() {
                return new Promise((resolve, reject) => {
                    finish().then((res) => resolve(res));
                }).catch(() => console.log('Finish error'));
            },
            onCancel() {},
            cancelText: 'Отменить',
        });
    };

    const showCancelConfirm = () => {
        confirm({
            title: 'Вы хотите отменить обучение модели?',
            onOk() {
                return new Promise((resolve, reject) => {
                    cancel().then((res) => {
                        if (res) resolve(res);
                    });
                }).catch((err) => {
                    console.log('Cancel error', err);
                });
            },
            onCancel() {},
            cancelText: 'Отменить',
        });
    };

    const history = useHistory();

    const trainingStatus = (state: QueryState) => {
        switch (state) {
            case QueryState.CANCELED:
                return (
                    <Tag color='warning' className='statusTag'>
                        Отменено
                    </Tag>
                );
            case QueryState.DONE:
                return (
                    <Tag color='success' className='statusTag'>
                        Успешно завершено
                    </Tag>
                );
            case QueryState.PENDING:
                return (
                    <Tag color='default' className='statusTag'>
                        Ожидает запуска
                    </Tag>
                );
            case QueryState.RUNNING:
                return (
                    <Tag color='processing' className='statusTag'>
                        Обучение
                    </Tag>
                );
            default:
                return (
                    <Tag color='default' className='statusTag'>
                        NONE
                    </Tag>
                );
        }
    };

    const hasError = trainingQuery?.state === QueryState.CANCELED && trainingQuery.currentstep?.error ? true : false;

    const statesToHideButtons = [QueryState.CANCELED, QueryState.DONE, QueryState.NONESTATE];

    const CardTitle = (
        <>
            {`Модель нейросети №${id}`}
            <div style={{ marginTop: 16 }}>
                {hasError ? (
                    <Typography.Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'ещё' } : false}>
                      Ошибка: {trainingQuery?.currentstep?.error}
                    </Typography.Paragraph>
                ) : null}
            </div>
        </>
    );

    return (
        <>
            <div className='button__tools'>
                <Button onClick={() => history.push('/learning')}>Назад</Button>
                <div className='button__tools'>
                    {statesToHideButtons.includes(trainingQuery?.state ?? QueryState.NONESTATE) ? null : (
                        <>
                            <Button type='primary' onClick={showFinishConfirm}>
                                Завершить обучение
                            </Button>
                            <Button type='primary' onClick={showCancelConfirm}>
                                Отменить обучение
                            </Button>
                        </>
                    )}
                </div>
            </div>

            <div className='container'>
                <Card title={CardTitle} bordered className='container__mainCard'>
                    {hasError ? (
                        <Tag color='error' className='statusTag'>
                            Ошибка
                        </Tag>
                    ) : (
                        trainingStatus(trainingQuery?.state ?? QueryState.NONESTATE)
                    )}
                    <Typography.Text type='secondary'>
                        Создано пользователем {trainingQuery?.cvatauthor?.title}{' '}
                        {new Date(trainingQuery?.creationtime?.unixtime ?? 0)?.toLocaleDateString('ru')}
                    </Typography.Text>
                    <DatasetForm dataset={dataset} trainingQuery={trainingQuery} />
                    <Divider />
                    <LearningParams trainingQuery={trainingQuery} dataset={dataset} />
                </Card>

                <Card title='Информация по обучению' bordered>
                    <LearningInfo
                        progressTelemetry={trainingQuery?.currentstep}
                        trainSettings={trainingQuery?.trainsettings}
                        dataset={dataset}
                        trainingQuery={trainingQuery}
                    />
                </Card>
            </div>
        </>
    );
};

export default ModelDetails;
