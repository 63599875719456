import React, { useEffect } from 'react';
import {
    Form, Input, Row, Col,
} from 'antd';

import { FormInstance } from 'antd/lib/form';

import { CombinedState } from 'reducers';
import {
    Dumper, Project, Task, Job,
} from 'cvat-core-wrapper';

import { getClientHost } from 'shared/constants';

import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { ObjectRef } from 'proto/Common_pb';
import { createEnhancedClient } from '../../utils/grpc';

import type { FormValues } from './export-dataset-modal';

import RangeSlider from './range-slider';
import Augmentation from './augmentation';
import FormatSelectDataset from './format-select-dataset';

interface ExportDatasetProps {
    form: FormInstance<any>;
    initialValues: FormValues;
    handleCreate?: (values: any) => void;
    dumpers: Dumper[];
    instance: Project | Task | Job | null;
    current: string[];
    datasetFormat: string;
    setDatasetFormat: (values: string) => void;
    backState: CombinedState;
}

const CreateDataset = (props: ExportDatasetProps): JSX.Element => {
    const {
        form,
        initialValues,
        handleCreate,
        dumpers,
        instance,
        current,
        datasetFormat,
        setDatasetFormat,
        backState,
    } = props;
    const currentHost = getClientHost();

    // Добавляем в initialValues информацию по проекту для создания Датасета
    useEffect(() => {
        // Указываем Формат Датасета YOLO
        initialValues.exportDatasetParams.format = 24;
        // Указываем Автора
        const userInfo = backState.auth.user;
        if (userInfo.firstName.trim() || userInfo.lastName.trim()) {
            initialValues.exportDatasetParams.authortitle = `${userInfo.firstName} ${userInfo.lastName}`;
        } else {
            initialValues.exportDatasetParams.authortitle = backState.auth.user.username;
        }
        // Указываем ID Автора
        initialValues.exportDatasetParams.cvatAuthorId = backState.auth.user.id;

        // Указываем организацию при наличии
        if (backState.organizations.current) {
            initialValues.exportDatasetParams.organization = new ObjectRef()
                .setId(Number(backState.organizations.current?.id))
                .setTitle(String(backState.organizations.current?.name))
                .toObject();
        }

        if (instance instanceof Project || instance instanceof Task) {
            // ID Проекта
            initialValues.exportDatasetParams.cvatProjectId = instance.id;
            // Указываем название Проекта
            initialValues.exportDatasetParams.cvatProjectname = instance.name;
        }
    }, []);

    return (
        <Form
            name='Create dataset'
            form={form}
            layout='vertical'
            initialValues={{ ...initialValues, selectedFormat: datasetFormat }}
            onFinish={handleCreate}
        >
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Формат</div>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name='selectedFormat'
                        rules={[{ required: true, message: 'Необходимо выбрать формат' }]}
                        style={{ marginBottom: 0 }}
                    >
                        <FormatSelectDataset
                            setDatasetFormat={setDatasetFormat}
                            datasetFormat={datasetFormat}
                            dumpers={dumpers}
                            instance={instance}
                            current={current}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Название датасета</div>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name='customName'
                        rules={[
                            { required: true, message: 'Необходимо указать название' },
                            {
                                validator: async (_, value) => {
                                    // ! Проверяем уникальность название датасета в gRPC
                                    if (currentHost) {
                                        const client = createEnhancedClient(DatasetsServiceClient);

                                        if (value) {
                                            await client
                                                .checkUniqName(new Dataset().setTitle(value.trim()), {})
                                                .then((result) => {
                                                    if (result.toObject().success) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(
                                                        new Error('Датасет с таким названием уже существует'),
                                                    );
                                                })
                                                .catch((error) => {
                                                    console.error(error);
                                                    return Promise.reject(
                                                        new Error('Датасет с таким названием уже существует'),
                                                    );
                                                });
                                        }
                                    }
                                },
                            },
                        ]}
                        style={{ marginBottom: 0 }}
                    >
                        <Input placeholder='Укажите название датасета' className='cvat-modal-export-filename-input' />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Выборка</div>
                </Col>
                <Col span={18}>
                    <RangeSlider backState={backState} initialValues={initialValues} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Аугментация</div>
                </Col>
                <Col span={18}>
                    <Augmentation initialValues={initialValues} />
                </Col>
            </Row>
        </Form>
    );
};

export default CreateDataset;
