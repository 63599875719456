import React from 'react';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { Row, Col } from 'antd';

import DataseAboutHeaderComponent from './components/about/about-header';
import DataseAboutProjectComponent from './components/about/about-project';
import DataseAboutClassesComponent from './components/about/about-classes';
import DataseAboutClassesStatisticComponent from './components/about/about-classes-statistic';
import DataseAboutAugmentationComponent from './components/about/about-augmentation';

interface ClassesList {
    id: number;
    title: string;
    color: string
}

interface DatasetAboutPageProps {
    datasetData: Dataset.AsObject | undefined;
    classesInfo: ClassesList[] | undefined;
}

const DatasetAboutPage = (props: DatasetAboutPageProps): JSX.Element => {
    const { datasetData, classesInfo } = props;

    return (
        <Row gutter={[16, 40]}>
            <Col span={24}>
                <DataseAboutHeaderComponent datasetData={datasetData} />
            </Col>
            <Col span={24}>
                <DataseAboutProjectComponent datasetData={datasetData} />
            </Col>
            <Col span={24}>
                <DataseAboutClassesComponent datasetData={datasetData} classesInfo={classesInfo} />
            </Col>
            <Col span={24}>
                <DataseAboutClassesStatisticComponent datasetData={datasetData} classesInfo={classesInfo} />
            </Col>
            <Col span={24}>
                <DataseAboutAugmentationComponent datasetData={datasetData} />
            </Col>
        </Row>
    );
};

export default DatasetAboutPage;
