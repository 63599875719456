import React, { useEffect, useState } from 'react';

import { Divider, Pagination, Select } from 'antd';

import { getClientHost } from 'shared/constants';

import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { ObjectsId } from 'proto/Common_pb';
import { ViewImagesCondition, MarkedImage, Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { ClassesListForFilter } from 'components/dataset-page/dataset-page';
import { createEnhancedClient } from '../../../../utils/grpc';

import DatasetSubsetImageItem from './image-item';

interface DatasetSubsetImagesComponentProps {
    currentFilterClasses: number[];
    currentFilterSubsets: number;
    datasetClassesList: ClassesListForFilter[];
    datasetID?: number[];
    datasetData?: Dataset.AsObject;
}

const HD_DEFAULT_PAGE_SIZE = 20;
const K2_DEFAULT_PAGE_SIZE = 50;
const K4_DEFAULT_PAGE_SIZE = 100;

const DatasetSubsetImagesComponent = (props: DatasetSubsetImagesComponentProps): JSX.Element => {
    const {
        currentFilterClasses,
        currentFilterSubsets,
        datasetClassesList,
        datasetID,
        datasetData,
    } = props;

    // Изображения, согласно фильтрам
    const [datasetImages, setDatasetImages] = useState<MarkedImage.AsObject[] | []>([]);

    // Настройки пагинации
    const [paginationPage, setPaginationPage] = useState<number>(1);
    const [defaultPageSize, setDefaultPageSize] = useState(HD_DEFAULT_PAGE_SIZE);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(HD_DEFAULT_PAGE_SIZE);

    const currentHost = getClientHost();

    //! Получаем информацию по изображениям, согласно фильтрам
    const getFilteredDatasetImages: () => void = async () => {
        if (currentHost) {
            if (datasetID) {
                const client = createEnhancedClient(DatasetsServiceClient);
                const filterRules = new ViewImagesCondition().setDatasetid(datasetID[0]);

                if (currentFilterClasses.length) {
                    filterRules.setClasses(new ObjectsId().setIdsList(currentFilterClasses));
                }

                if (currentFilterSubsets) {
                    filterRules.setPart(currentFilterSubsets);
                }

                const images = await client.getDatasetImages(filterRules, {});
                const imagesList = images.toObject().listList;

                setDatasetImages(imagesList);
            }
        }
    };

    useEffect(() => {
        if (window.innerWidth > 2048) {
            setDefaultPageSize(K2_DEFAULT_PAGE_SIZE);
            setPaginationPageSize(K2_DEFAULT_PAGE_SIZE);
        }
        if (window.innerWidth > 3800 && window.innerHeight > 1400) {
            setDefaultPageSize(K4_DEFAULT_PAGE_SIZE);
            setPaginationPageSize(K4_DEFAULT_PAGE_SIZE);
        }
    }, []);

    useEffect(() => {
        if (datasetID) {
            getFilteredDatasetImages();
        }
    }, [currentFilterClasses, currentFilterSubsets, datasetID]);

    return (
        <>
            <Divider style={{ marginTop: '-10px', marginBottom: '30px' }} />
            <div className='img-dataset-container'>
                {datasetImages.map((imageData, ind) => {
                    if (
                        ind + 1 > paginationPage * paginationPageSize - paginationPageSize &&
                        ind + 1 <= paginationPage * paginationPageSize
                    ) {
                        return (
                            <DatasetSubsetImageItem
                                imageData={imageData}
                                datasetData={datasetData}
                                datasetClassesList={datasetClassesList}
                            />
                        );
                    }

                    return '';
                })}
            </div>
            <div className='pagination-dataset-container'>
                <Pagination
                    total={datasetImages.length}
                    current={paginationPage}
                    pageSize={paginationPageSize}
                    showSizeChanger={false}
                    defaultPageSize={defaultPageSize}
                    onChange={(page, pageSize) => {
                        setPaginationPage(page);
                        setPaginationPageSize(pageSize);
                    }}
                />
                <Select
                    style={{ width: 120, marginLeft: 20 }}
                    onChange={(value) => setPaginationPageSize(Number(value))}
                    value={paginationPageSize.toString()}
                    options={[
                        {
                            value: '10',
                            label: 'По 10 шт.',
                        },
                        {
                            value: '20',
                            label: 'По 20 шт.',
                        },
                        {
                            value: '50',
                            label: 'По 50 шт.',
                        },
                        {
                            value: '100',
                            label: 'По 100 шт.',
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default DatasetSubsetImagesComponent;
