import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { ExceptionOutlined } from '@ant-design/icons';

import { AttributeList } from 'proto/c60/nn/center/DatasetClasses_pb';

import AttributeForm from './attribute-form';

interface DataType {
    key: React.Key;
    tblName?: string;
    tblKind?: number;
    tblAttribute?: number;
    tblColor?: string;
    tblType?: number;
    tblDescription?: string;
    attributes?: AttributeList.AsObject;
    children?: DataType[];
}

interface AttributeType {
    id?: number;
    name: string;
    type: string;
    mutable: boolean;
    values: Array<string> | string;
    default_value: string;
}

interface Props {
    attributeModalOpen: boolean;
    setAttributeModalOpen(boolean: boolean): void;
    currentClassNode?: DataType;
    setCurrentClassNode(object: undefined): void;
}

const AttributeModal = (props: Props): JSX.Element => {
    const {
        attributeModalOpen, setAttributeModalOpen, currentClassNode, setCurrentClassNode,
    } = props;
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [attributeData, setAttributeData] = useState<AttributeType[]>([]);

    // Триггер - Валидация формы
    const handleOk = (): void => {
        setConfirmLoading(true);
    };

    useEffect(() => {
        let getAttributesList: AttributeType[];
        // Формируем объект данных для вывода полей в форме
        if (currentClassNode?.attributes?.attributesList.length) {
            getAttributesList = currentClassNode.attributes.attributesList.map((element): any => {
                // Select
                if (element.selectmany) {
                    const getAttribute: AttributeType = {
                        name: element.title,
                        type: 'select',
                        mutable: element.mutable,
                        values: element.selectmany?.textList,
                        default_value: element.selectmany?.textList[0],
                    };

                    if (element.id !== undefined) {
                        getAttribute.id = element.id;
                    }

                    return getAttribute;
                }

                // Radio
                if (element.radio) {
                    const getAttribute: AttributeType = {
                        name: element.title,
                        type: 'radio',
                        mutable: element.mutable,
                        values: element.radio?.textList,
                        default_value: element.radio?.textList[0],
                    };

                    if (element.id !== undefined) {
                        getAttribute.id = element.id;
                    }

                    return getAttribute;
                }

                // Checkbox
                if (element.checkbox) {
                    const getAttribute: AttributeType = {
                        name: element.title,
                        type: 'checkbox',
                        mutable: element.mutable,
                        values: String(element.checkbox?.value),
                        default_value: String(element.checkbox?.value),
                    };

                    if (element.id !== undefined) {
                        getAttribute.id = element.id;
                    }

                    return getAttribute;
                }

                // Text
                if (element.text) {
                    const getAttribute: AttributeType = {
                        name: element.title,
                        type: 'text',
                        mutable: element.mutable,
                        values: String(element.text?.text),
                        default_value: String(element.text?.text),
                    };

                    if (element.id !== undefined) {
                        getAttribute.id = element.id;
                    }

                    return getAttribute;
                }

                // Number
                if (element.number) {
                    const getAttribute: AttributeType = {
                        name: element.title,
                        type: 'number',
                        mutable: element.mutable,
                        values: String(`${element.number?.min}.${element.number?.max}.${element.number?.step}`),
                        default_value: String(element.number?.min),
                    };

                    if (element.id !== undefined) {
                        getAttribute.id = element.id;
                    }

                    return getAttribute;
                }

                return element;
            });

            setAttributeData(getAttributesList);
        }
    }, [currentClassNode]);

    return (
        <Modal
            title={(
                <>
                    <ExceptionOutlined style={{ marginRight: '10px', color: '#368585' }} />
                    {`Атрибуты класса - ${currentClassNode?.tblName}`}
                </>
            )}
            open={attributeModalOpen}
            onOk={() => handleOk()}
            onCancel={() => setAttributeModalOpen(false)}
            afterClose={() => {
                setAttributeModalOpen(false);
                setCurrentClassNode(undefined);
                setAttributeData([]);
            }}
            okText='Применить'
            cancelText='Отменить'
            width={900}
            confirmLoading={confirmLoading}
            destroyOnClose
            bodyStyle={{ width: 900 }}
        >
            <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <AttributeForm
                    currentClassNode={currentClassNode}
                    setAttributeModalOpen={setAttributeModalOpen}
                    confirmLoading={confirmLoading}
                    setConfirmLoading={setConfirmLoading}
                    attributeData={attributeData}
                />
            </div>
        </Modal>
    );
};

export default AttributeModal;
