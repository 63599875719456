// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useHistory } from 'react-router';
import { LeftOutlined, MoreOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import ActionsMenuContainer from 'containers/actions-menu/actions-menu';

interface DetailsComponentProps {
    taskInstance: any;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { taskInstance } = props;

    const history = useHistory();

    return (
        <div className='cvat-task-top-bar'>
            <div>
                {taskInstance.projectId ? (
                    <Button
                        className='cvat-page-actions-button'
                        onClick={() => history.push(`/projects/${taskInstance.projectId}`)}
                        type='default'
                        size='middle'
                    >
                        <LeftOutlined />
                        Назад к проектам
                    </Button>
                ) : (
                    <Button
                        className='cvat-page-actions-button'
                        onClick={() => history.push('/tasks')}
                        type='default'
                        size='middle'
                    >
                        <LeftOutlined />
                        Назад к задачам
                    </Button>
                )}
            </div>
            <div>
                <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} />}>
                    <Button size='middle' className='cvat-page-actions-button'>
                        <MoreOutlined />
                        <Text className='cvat-text-color'>Действия</Text>
                    </Button>
                </Dropdown>
            </div>
        </div>
    );
}
