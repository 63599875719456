import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

const { Panel } = Collapse;

interface TransformationProps {
    augmentationValues: YoloAugmentationConfig.AsObject | undefined;
    setAugmentationValues: React.Dispatch<React.SetStateAction<YoloAugmentationConfig.AsObject | undefined>>;
    defaultAugmentation: YoloAugmentationConfig.AsObject | undefined;
}

const Transformation: React.FC<TransformationProps> = ({ augmentationValues, setAugmentationValues, defaultAugmentation }) => {
    const [checkedTransformation, setCheckedTransformation] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    // Конвертация значений аугментации в значения слайдера
    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    const getValueToPerspectiveSlider = (value?: number): number => (
        Math.floor((Number(value) * 100 * 1000) * 100) / 100
    );

    useEffect(() => {
        if (augmentationValues && (augmentationValues.translate > 0 || augmentationValues.scale > 0 || augmentationValues.shear > 0 || augmentationValues.perspective > 0)) {
            setCheckedTransformation(true);
        } else {
            setCheckedTransformation(false);
        }
    }, [augmentationValues]);

    const setValue = (valueType: 'translate' | 'scale' | 'shear' | 'perspective', value: number) => {
        let newValue = value;
        if (value && value > 100) newValue = 100;
        if (value && value < 0) newValue = 0;
        setAugmentationValues(prev => prev ? { ...prev, [valueType]: newValue / (valueType === 'perspective' ? 100 * 1000 : 100) } : undefined);
    };

    const switchTransformation = (value: boolean) => {
        if (!value) {
            setAugmentationValues(prev => prev ? { ...prev, translate: 0, scale: 0, shear: 0, perspective: 0 } : undefined);
        } else {
            if (defaultAugmentation) {
                const translate = defaultAugmentation.translate;
                const scale = defaultAugmentation.scale;
                const shear = defaultAugmentation.shear;
                const perspective = defaultAugmentation.perspective;
                setAugmentationValues(prev => prev ? { ...prev, translate, scale, shear, perspective } : undefined);
            }
        }
        setCheckedTransformation(value);
    };

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Трансформирование</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedTransformation}
                                onChange={switchTransformation}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_3'
                >
                    <div>
                        Добавьте вариативности с помощью трансформирования изображений,
                        чтобы ваша модель была более устойчивой к углу наклона камеры и
                        масштабу объекта съемки.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Сдвиг
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.translate || 0)}
                                        onChange={(value: number) => setValue('translate', value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.translate || 0)}
                                        onBlur={event => setValue('translate', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Масштаб
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.scale || 0)}
                                        onChange={(value: number) => setValue('scale', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.scale || 0)}
                                        onBlur={event => setValue('scale', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Искажение
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.shear || 0)}
                                        onChange={(value: number) => setValue('shear', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.shear || 0)}
                                        onBlur={event => setValue('shear', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Перспектива
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToPerspectiveSlider(augmentationValues?.perspective || 0)}
                                        onChange={(value: number) => setValue('perspective', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToPerspectiveSlider(augmentationValues?.perspective || 0)}
                                        onBlur={event => setValue('perspective', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Transformation;
