import React, { useEffect } from 'react';
import {
    Space, Form, Checkbox, Input, Select, Tooltip, Button, message,
} from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { getClientHost } from 'shared/constants';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { StringList, BooleanMsg, StringMsg } from 'proto/Common_pb';
import {
    DClass, Attribute, AttributeList, NumberDescription,
} from 'proto/c60/nn/center/DatasetClasses_pb';
import { createEnhancedClient } from '../../utils/grpc';

interface DataType {
    key: React.Key;
    tblName?: string;
    tblKind?: number;
    tblAttribute?: number;
    tblColor?: string;
    tblType?: number;
    tblDescription?: string;
    attributes?: AttributeList.AsObject;
    children?: DataType[];
}

interface AttributeType {
    id?: number;
    name: string;
    type: string;
    mutable: boolean;
    values: any;
    default_value: string;
}

interface Props {
    currentClassNode?: DataType;
    setAttributeModalOpen(boolean: boolean): void;
    confirmLoading: boolean;
    setConfirmLoading(boolean: boolean): void;
    attributeData: AttributeType[];
}

const AttributeForm = (props: Props): JSX.Element => {
    const {
        currentClassNode, setAttributeModalOpen, confirmLoading, setConfirmLoading, attributeData,
    } = props;
    const [form] = Form.useForm();

    const currentHost = getClientHost();

    //! Сохраняем изменение данных - GRPC
    const handleSave = (values: AttributeType[]): void => {
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);

                await client
                    .insert(
                        new DClass().setId(Number(currentClassNode?.key)).setAttributes(
                            new AttributeList().setAttributesList(
                                values.map((element) => {
                                    // Select
                                    if (element.type === 'select') {
                                        return new Attribute()
                                            .setTitle(element.name)
                                            .setMutable(element.mutable)
                                            .setSelectmany(new StringList().setTextList(element.values));
                                    }

                                    // Radio
                                    if (element.type === 'radio') {
                                        return new Attribute()
                                            .setTitle(element.name)
                                            .setMutable(element.mutable)
                                            .setRadio(new StringList().setTextList(element.values));
                                    }

                                    // Checkbox
                                    if (element.type === 'checkbox') {
                                        let formValue: boolean;
                                        if (typeof element.values === 'object') {
                                            formValue = element.values[0].toLowerCase() === 'true';
                                        } else {
                                            formValue = element.values.toLowerCase() === 'true';
                                        }

                                        return new Attribute()
                                            .setTitle(element.name)
                                            .setMutable(element.mutable)
                                            .setCheckbox(new BooleanMsg().setValue(formValue));
                                    }

                                    // Text
                                    if (element.type === 'text') {
                                        let formValue: string;
                                        if (typeof element.values === 'object') {
                                            formValue = String(element.values.join('.'));
                                        } else {
                                            formValue = String(element.values);
                                        }

                                        return new Attribute()
                                            .setTitle(element.name)
                                            .setMutable(element.mutable)
                                            .setText(new StringMsg().setText(String(formValue)));
                                    }

                                    // Number
                                    if (element.type === 'number') {
                                        let formValue;
                                        if (typeof element.values === 'object') {
                                            formValue = element.values[0]?.split('.');
                                        } else {
                                            formValue = element.values?.split('.');
                                        }

                                        return new Attribute()
                                            .setTitle(element.name)
                                            .setMutable(element.mutable)
                                            .setNumber(
                                                new NumberDescription()
                                                    .setMin(Number(formValue[0]))
                                                    .setMax(Number(formValue[1]))
                                                    .setStep(Number(formValue[2])),
                                            );
                                    }

                                    return new Attribute();
                                }),
                            ),
                        ),
                        {},
                    )
                    .then(() => {
                        message.success('Атрибуты изменены!');
                    })
                    .catch((error) => {
                        message.error('Не удалось изменить атрибуты!');
                        console.error(error);
                    });
            }
        })();

        setAttributeModalOpen(false);
    };

    // Триггер - Валидация формы
    useEffect(() => {
        if (confirmLoading) {
            (async (): Promise<void> => {
                try {
                    const values = await form.validateFields();

                    setConfirmLoading(false);
                    handleSave(values.attributes);
                } catch (errorInfo) {
                    setConfirmLoading(false);
                    console.log('Failed:', errorInfo);
                }
            })();
        }
    }, [confirmLoading]);

    // Формирование полей и данных формы с условиями валидации
    const renderAttributes = (data: any, add: any, remove: any): any => (
        <>
            {data.map(
                ({ key, name }: any): JSX.Element => (
                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align='baseline'>
                        <Form.Item
                            name={[name, 'name']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, укажите название',
                                },
                            ]}
                        >
                            <Input placeholder='Название атрибута' style={{ width: '225px' }} />
                        </Form.Item>

                        <Tooltip title='HTML-элемент, представляющий атрибут' placement='left' mouseEnterDelay={0.5}>
                            <Form.Item name={[name, 'type']}>
                                <Select style={{ width: '125px' }} defaultValue='select'>
                                    <Select.Option value='select'>Select</Select.Option>
                                    <Select.Option value='radio'>Radio</Select.Option>
                                    <Select.Option value='checkbox'>Checkbox</Select.Option>
                                    <Select.Option value='text'>Text</Select.Option>
                                    <Select.Option value='number'>Number</Select.Option>
                                </Select>
                            </Form.Item>
                        </Tooltip>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                                const prevValuesJSON = JSON.stringify(prevValues.attributes);
                                const currentValuesJSON = JSON.stringify(currentValues.attributes);
                                return prevValuesJSON !== currentValuesJSON;
                            }}
                        >
                            {({ getFieldValue }): JSX.Element | undefined => {
                                if (
                                    getFieldValue(['attributes', name, 'type']) === 'select' ||
                                    getFieldValue(['attributes', name, 'type']) === 'radio'
                                ) {
                                    return (
                                        <Tooltip
                                            title='Нажмите Enter, чтобы добавить новое значение'
                                            placement='right'
                                            mouseEnterDelay={0.5}
                                        >
                                            <Form.Item
                                                name={[name, 'values']}
                                                style={{ width: '300px' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Пожалуйста, укажите значение',
                                                    },
                                                ]}
                                            >
                                                <Select mode='tags' placeholder='Значения атрибута' />
                                            </Form.Item>
                                        </Tooltip>
                                    );
                                }

                                if (getFieldValue(['attributes', name, 'type']) === 'checkbox') {
                                    return (
                                        <Tooltip
                                            title='Укажите значение по умолчанию'
                                            placement='right'
                                            mouseEnterDelay={0.5}
                                        >
                                            <Form.Item
                                                name={[name, 'values']}
                                                style={{ width: '300px' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Пожалуйста, укажите значение',
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            let typeValue;

                                                            if (typeof value === 'object') {
                                                                typeValue = value.join();
                                                            } else {
                                                                typeValue = value;
                                                            }

                                                            if (typeValue === 'true' || typeValue === 'false') {
                                                                return Promise.resolve();
                                                            }

                                                            return Promise.reject(
                                                                new Error('Необходимо выбрать True или False'),
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Select
                                                    placeholder='Значение атрибута'
                                                    onChange={() => {
                                                        form.setFieldValue(
                                                            ['attributes', name, 'values'],
                                                            new Array(getFieldValue(['attributes', name, 'values'])),
                                                        );
                                                    }}
                                                    options={[
                                                        {
                                                            value: 'true',
                                                            label: 'True',
                                                        },
                                                        {
                                                            value: 'false',
                                                            label: 'False',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    );
                                }

                                if (getFieldValue(['attributes', name, 'type']) === 'text') {
                                    return (
                                        <Form.Item
                                            name={[name, 'values']}
                                            style={{ width: '300px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Пожалуйста, укажите значение',
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder='Значение атрибута'
                                                onChange={() => {
                                                    form.setFieldValue(
                                                        ['attributes', name, 'values'],
                                                        new Array(getFieldValue(['attributes', name, 'values'])),
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    );
                                }

                                if (getFieldValue(['attributes', name, 'type']) === 'number') {
                                    return (
                                        <Tooltip
                                            title='Укажите диапазон ОТ - ДО и ШАГ, разделённые точкой'
                                            placement='right'
                                            mouseEnterDelay={0.5}
                                        >
                                            <Form.Item
                                                name={[name, 'values']}
                                                style={{ width: '300px' }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Пожалуйста, укажите МИН.МАКС.ШАГ',
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            let typeValue;

                                                            if (typeof value === 'object') {
                                                                typeValue = value.join(',');
                                                            } else {
                                                                typeValue = value;
                                                            }

                                                            const numbers = typeValue.split('.');

                                                            if (numbers.length !== 3) {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        'Необходимо указать три числа через точку',
                                                                    ),
                                                                );
                                                            }

                                                            if (numbers.length === 3 && numbers[2] === '') {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        'Необходимо указать три числа через точку',
                                                                    ),
                                                                );
                                                            }

                                                            for (const number of numbers) {
                                                                if (!new RegExp('^[0-9-]+$', 'gi').test(number)) {
                                                                    return Promise.reject(
                                                                        new Error(`Недопустимые символы - ${number}`),
                                                                    );
                                                                }
                                                            }

                                                            const [min, max, step] = numbers;

                                                            if (Number(min) >= Number(max)) {
                                                                return Promise.reject(
                                                                    new Error('Значение МИН не может быть больше МАКС'),
                                                                );
                                                            }

                                                            if (Number(max) - Number(min) < Number(step)) {
                                                                return Promise.reject(
                                                                    new Error('ШАГ больше разницы между МИН и МАКС'),
                                                                );
                                                            }

                                                            if (Number(step) <= 0) {
                                                                return Promise.reject(
                                                                    new Error('ШАГ должен быть положительным числом'),
                                                                );
                                                            }

                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input
                                                    placeholder='МИН.МАКС.ШАГ (через точку)'
                                                    onChange={() => {
                                                        form.setFieldValue(
                                                            ['attributes', name, 'values'],
                                                            new Array(getFieldValue(['attributes', name, 'values'])),
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    );
                                }

                                return undefined;
                            }}
                        </Form.Item>

                        <Tooltip
                            title='Можно ли изменить этот атрибут от кадра к кадру?'
                            placement='left'
                            mouseEnterDelay={0.5}
                        >
                            <Form.Item name={[name, 'mutable']} valuePropName='checked'>
                                <Checkbox style={{ width: '125px', marginLeft: 20, marginRight: 10 }}>
                                    Мутабельный
                                </Checkbox>
                            </Form.Item>
                        </Tooltip>

                        <Tooltip title='Удалить атрибут' placement='left' mouseEnterDelay={0.5}>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                        </Tooltip>
                    </Space>
                ),
            )}

            <Form.Item>
                <Button
                    type='dashed'
                    onClick={() => {
                        add({
                            default_value: '',
                            type: 'select',
                            mutable: false,
                            name: '',
                            values: [],
                        });
                    }}
                    block
                    icon={<PlusCircleOutlined />}
                >
                    Добавить атрибут
                </Button>
            </Form.Item>
        </>
    );

    return (
        <Form
            initialValues={{
                attributes: attributeData,
            }}
            layout='vertical'
            form={form}
        >
            <Form.List name='attributes'>
                {(objData, { add, remove }) => <>{renderAttributes(objData, add, remove)}</>}
            </Form.List>
        </Form>
    );
};

export default AttributeForm;
