/* eslint-disable jsx-a11y/control-has-associated-label */
import { LeftOutlined } from '@ant-design/icons';
import {
    Typography, Button, Card, Form, Input, Space, Select, Divider, Switch, notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import './styles.scss';
import { useForm } from 'antd/lib/form/Form';
import { getClientHost } from 'shared/constants';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset, DatasetsCondition } from 'proto/c60/nn/center/DataSets_pb';
import { DetectorServiceClient, TrainingQueryServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { Detector, TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import { TrainSettings } from 'proto/nn_training/DetectorTraining_pb';
import { ObjectId, StringSearch } from 'proto/Common_pb';
import WarningInput from '../warning-input/warning-input';

type FormValuesDataType = {
    detectorname: string;
    datasetid: number;
    parentdetectorid: number;
    'trainsetting.epochs': string;
    'trainsetting.batch': string;
    'trainsetting.freeze': string;
    'trainsetting.boxgain': number;
    'trainsetting.classgain': number;
    'trainsetting.dflgain': number;
    'trainsetting.weightdecay': number;
    'trainsetting.learnbeginrate': number;
    'trainsetting.learnfinalrate': number;
    'trainsetting.optimizer': number;
    'trainsetting.saveimgproportions': boolean;
    'trainsetting.amp': boolean;
    'trainsetting.deterministic': boolean;
    'trainsetting.patience': string;
};

const RetrainPage = (): JSX.Element => {
    const history = useHistory();
    const currentHost = getClientHost();

    const [form] = useForm();
    const [loadingNewQuery, setLoadingNewQuery] = useState<boolean>(false);
    const [datasetData, setDatasetData] = useState<Dataset.AsObject[]>();
    const [parentDetectorData, setParentDetectorData] = useState<Detector.AsObject[]>();

    const [parentDetectorHelp, setParentDetectorHelp] = useState<'Выберите дообучаемую нейросеть!' | ''>('');
    const [parentDetectorStatus, setParentDetectorStatus] = useState<'error' | '' | undefined>('');

    const [datasetidHelp, setDatasetidHelp] = useState<'Выберите датасет!' | ''>('');
    const [datasetidStatus, setDatasetidStatus] = useState<'error' | '' | undefined>('');

    const [detectornameHelp, setDetectornameHelp] = useState<'Введите название!' | ''>('');
    const [detectornameStatus, setDetectornameStatus] = useState<'error' | '' | undefined>('');

    const setDefaultValues = (): void => {
        form.setFieldValue('trainsetting.boxgain', 7.5);
        form.setFieldValue('trainsetting.classgain', 0.5);
        form.setFieldValue('trainsetting.dflgain', 1.5);
        form.setFieldValue('trainsetting.weightdecay', 0.0005);
        form.setFieldValue('trainsetting.learnbeginrate', 0.01);
        form.setFieldValue('trainsetting.learnfinalrate', 0.01);
        form.setFieldValue('trainsetting.optimizer', 8);
        form.setFieldValue('trainsetting.amp', true);
        form.setFieldValue('trainsetting.deterministic', true);
    };

    const getDatasetData = async (): Promise<void> => {
        if (currentHost) {
            const client = new DatasetsServiceClient(currentHost);
            const datasetList = (
                await client.getInfo(await client.getAllAvailableDatasets(new DatasetsCondition(), {}), {})
            ).toObject().listList;

            setDatasetData(datasetList);
        }
    };

    const getParentDetectorsData = async (): Promise<void> => {
        if (currentHost) {
            const client = new DetectorServiceClient(currentHost);
            const detectorList = (
                await client.getInfo(await client.getAvailable(new StringSearch(), {}), {})
            ).toObject().listList;

            setParentDetectorData(detectorList);
        }
    };

    useEffect(() => {
        setDefaultValues();
        getParentDetectorsData();
        getDatasetData();
    }, []);

    const handleSubmit = async (values: FormValuesDataType): Promise<void> => {
        if (!form?.getFieldValue('detectorname') || form?.getFieldValue('detectorname')?.length === 0) {
            setDetectornameHelp('Введите название!');
            setDetectornameStatus('error');
        }

        if (!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0) {
            setDatasetidHelp('Выберите датасет!');
            setDatasetidStatus('error');
        }

        if (!form?.getFieldValue('parentdetector') || form?.getFieldValue('parentdetector')?.length === 0) {
            setParentDetectorHelp('Выберите дообучаемую нейросеть!');
            setParentDetectorStatus('error');
        }

        if (
            !(!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0) &&
            !(!form?.getFieldValue('datasetid') || form?.getFieldValue('datasetid')?.length === 0) &&
            !(!form?.getFieldValue('parentdetector') || form?.getFieldValue('parentdetector')?.length === 0)
        ) {
            if (currentHost) {
                setLoadingNewQuery(true);
                const client = new TrainingQueryServiceClient(currentHost);

                const newTrainingQuery = new TrainingQuery();

                const newParentDetector = new ObjectId();
                const newTrainSettings = new TrainSettings();

                if (values.detectorname) newTrainingQuery.setDetectorname(values.detectorname);
                if (values.datasetid) newTrainingQuery.setDatasetid(values.datasetid);
                if (values.parentdetectorid) newParentDetector.setId(values.parentdetectorid);

                if (values['trainsetting.amp']) newTrainSettings.setAmp(values['trainsetting.amp']);
                if (values['trainsetting.batch']) newTrainSettings.setBatch(Number(values['trainsetting.batch']));
                if (values['trainsetting.boxgain']) newTrainSettings.setBoxgain(Number(values['trainsetting.boxgain']));
                if (values['trainsetting.classgain']) newTrainSettings.setClassgain(Number(values['trainsetting.classgain']));
                if (values['trainsetting.deterministic']) newTrainSettings.setDeterministic(values['trainsetting.deterministic']);
                if (values['trainsetting.dflgain']) newTrainSettings.setDflgain(Number(values['trainsetting.dflgain']));
                if (values['trainsetting.epochs']) newTrainSettings.setEpochs(Number(values['trainsetting.epochs']));
                if (values['trainsetting.freeze']) newTrainSettings.setFreeze(Number(values['trainsetting.freeze']));
                if (values['trainsetting.learnbeginrate']) newTrainSettings.setLearnbeginrate(Number(values['trainsetting.learnbeginrate']));
                if (values['trainsetting.learnfinalrate']) newTrainSettings.setLearnfinalrate(Number(values['trainsetting.learnfinalrate']));
                if (values['trainsetting.optimizer']) newTrainSettings.setOptimizer(values['trainsetting.optimizer']);
                if (values['trainsetting.patience']) newTrainSettings.setPatience(Number(values['trainsetting.patience']));
                if (values['trainsetting.saveimgproportions']) newTrainSettings.setSaveimgproportions(values['trainsetting.saveimgproportions']);
                if (values['trainsetting.weightdecay']) newTrainSettings.setWeightdecay(Number(values['trainsetting.weightdecay']));

                newTrainingQuery.setTrainsettings(newTrainSettings);
                newTrainingQuery.setParentdetector(newParentDetector);

                console.log(newTrainingQuery);
                try {
                    await client.startQuery(newTrainingQuery, {});
                    setLoadingNewQuery(false);
                    history.push('/learning');
                    notification.success({
                        message: 'Дообучение нейросети успешно создано',
                    });
                } catch (e: any) {
                    console.log(e);
                    notification.error({
                        message: 'Не удалось начать дообучение нейросети',
                        description: e.message,
                    });
                } finally {
                    setLoadingNewQuery(false);
                }
            }
        }
    };

    return (
        <>
            <div className='create-learning-page'>
                <div className='header-container'>
                    <Button type='default' className='header-button back' onClick={() => history.push('/learning')}>
                        <LeftOutlined className='margin-icon' />
                        Назад
                    </Button>
                    <Button
                        onClick={() => form.submit()}
                        loading={loadingNewQuery}
                        type='primary'
                        className='header-button start'
                    >
                        Начать дообучение
                    </Button>
                </div>
                <div className='cards-container'>
                    <Card className='custom-card arch' title='Архитектура'>
                        <Form onFinish={handleSubmit} form={form}>
                            <table className='arch-table space-8'>
                                <tr>
                                    <th colSpan={1}>Название</th>
                                    <td colSpan={1}>
                                        <Form.Item
                                            help={detectornameHelp}
                                            status={detectornameStatus}
                                            name='detectorname'
                                        >
                                            <Input
                                                placeholder='Введите название'
                                                onChange={() => {
                                                    setDetectornameHelp('');
                                                    setDetectornameStatus('');
                                                }}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='arch-table space-10'>
                                <tr>
                                    <th colSpan={1}>Датасет</th>
                                    <td colSpan={1}>
                                        <Form.Item help={datasetidHelp} status={datasetidStatus} name='datasetid'>
                                            <Select
                                                onChange={() => {
                                                    setDatasetidHelp('');
                                                    setDatasetidStatus('');
                                                }}
                                                loading={datasetData?.length === 0}
                                                options={datasetData?.map((dataset) => ({
                                                    label: dataset.title,
                                                    value: dataset.id,
                                                }))}
                                                placeholder='Выбрать датасет'
                                                notFoundContent={
                                                    <div style={{ textAlign: 'center' }}>Нету доступных датасетов</div>
                                                }
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1}>Размер изображений</th>
                                    <td colSpan={1}>
                                        <Space className='compact'>
                                            <WarningInput forminstance={form} formname='architecture.input.size.width' placeholder='32' type='number' addonBefore='Ширина' />
                                            <WarningInput forminstance={form} formname='architecture.input.size.height' placeholder='32' type='number' addonBefore='Высота' />
                                        </Space>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1}>Размер модели</th>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='architecture.networksize'>
                                            <Select
                                                placeholder='Выбрать размер'
                                                options={[
                                                    { label: 'Минимальный (nano)', value: 1 },
                                                    { label: 'Маленький (small)', value: 2 },
                                                    { label: 'Средний (medium)', value: 3 },
                                                    { label: 'Большой (large)', value: 4 },
                                                    { label: 'Максимальный (XL)', value: 5 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <Divider />
                            <table className='arch-table space-10'>
                                <tr>
                                    <th colSpan={1}>Дообучаемая нейросеть</th>
                                    <td colSpan={1}>
                                        <Form.Item
                                            help={parentDetectorHelp}
                                            status={parentDetectorStatus}
                                            name='parentdetector'
                                        >
                                            <Select
                                                onChange={() => {
                                                    setParentDetectorHelp('');
                                                    setParentDetectorStatus('');
                                                }}
                                                loading={parentDetectorData?.length === 0}
                                                options={parentDetectorData?.map((detector) => ({
                                                    label: detector.title,
                                                    value: detector.id,
                                                }))}
                                                placeholder='Выбрать дообучаемую нейросеть'
                                                notFoundContent={
                                                    <div style={{ textAlign: 'center' }}>Нету обученных нейросетей</div>
                                                }
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                        </Form>
                    </Card>
                    <Card className='custom-card process' title='Настройки процесса обучения'>
                        <Form onFinish={handleSubmit} form={form}>
                            <table className='process-table space-8'>
                                <tr>
                                    <th colSpan={1}>Параметры</th>
                                    <td colSpan={1}>
                                        <Typography.Text>Количество эпох для обучения</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.epochs'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество изображений в пакете</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.batch'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Количество замороженных слоёв</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.freeze'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент значимости точности коробки</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.boxgain'>
                                            <Input type='number' placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент значимости правильного класса</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.classgain'>
                                            <Input type='number' placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Коэффициент dfl</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.dflgain'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Интенсивность изменения весов</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.weightdecay'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Скорость обучения в начале lr0</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.learnbeginrate'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Скорость обучения в конце lr1</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.learnfinalrate'>
                                            <Input placeholder='0' />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Алгоритм управления оптимизацией</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.optimizer'>
                                            <Select
                                                options={[
                                                    { label: 'auto', value: 8 },
                                                    { label: 'Без оптимизации', value: 0 },
                                                    { label: 'SGD', value: 1 },
                                                    { label: 'Adam', value: 2 },
                                                    { label: 'Adamax', value: 3 },
                                                    { label: 'AdamW', value: 4 },
                                                    { label: 'NAdam', value: 5 },
                                                    { label: 'RAdam', value: 6 },
                                                    { label: 'RMSProp', value: 7 },
                                                ]}
                                            />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='process-table space-18'>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Сохранять пропорции изображения</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.saveimgproportions'>
                                            <Switch />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Автоматическая подстройка точности</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.amp'>
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={1} />
                                    <td colSpan={1}>
                                        <Typography.Text>Режим детерминированности</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.deterministic'>
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                            <table className='process-table space-10'>
                                <tr>
                                    <th colSpan={1}>Триггер завершения обучения</th>
                                    <td colSpan={1}>
                                        <Typography.Text>Количество эпох без прогресса</Typography.Text>
                                    </td>
                                    <td colSpan={1}>
                                        <Form.Item noStyle name='trainsetting.patience'>
                                            <Input type='number' placeholder='по умолчанию' />
                                        </Form.Item>
                                    </td>
                                </tr>
                            </table>
                        </Form>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default React.memo(RetrainPage);
