import './styles.css';
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { getClientHost } from 'shared/constants';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { DatasetsCondition, Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { ObjectsId } from 'proto/Common_pb';

import {
    Table, Input, Space, Button, Empty, Modal, notification, Typography,
} from 'antd';
import {
    SearchOutlined,
    InfoCircleOutlined,
    MergeCellsOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import type { InputRef } from 'antd';
import { createEnhancedClient } from '../../utils/grpc';

import DatasetsTopBar from './top-bar';
import DatasetsFilters from './datasets-filters';
import MergeDatasetsModal from './merge-datasets-modal';
import { exportActions } from '../../actions/export-actions';
import { findIndex } from 'lodash';

export interface DatasetsTableDataType {
    key: React.Key;
    title: string;
    projectName: string;
    creationDate: string;
    imageCount: number;
    subsets: string;
    classes: number;
}

type TableDataIndex = keyof DatasetsTableDataType;

const DatasetsPage = (): JSX.Element => {
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [idsList, setIdsList] = useState<number[] | undefined>(undefined);
    const [allDatasetsData, setAllDatasetsData] = useState<DatasetsTableDataType[] | undefined>(undefined);
    const [filtersParamsData, setFiltersParamsData] = useState<DatasetsCondition | undefined>(undefined);
    const [idsFiltersList, setIdsFiltersList] = useState<number[] | undefined>(undefined);
    const [filteredDatasetsData, setFilteredDatasetsData] = useState<DatasetsTableDataType[] | undefined>(undefined);
    const [loadTableDataSpin, setLoadTableDataSpin] = useState<boolean>(true);
    const [selectedDatasets, setSelectedDatasets] = useState<DatasetsTableDataType[]>();
    const [isMergeDatasetsModalOpen, setIsMergeDatasetsModalOpen] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const searchInput = useRef<InputRef>(null);

    const currentHost = getClientHost();

    // Формируем объект данных информацией полученной из gRPC для вывода в таблицу
    const formDataObject = (dataInfo: Dataset.AsObject[]): DatasetsTableDataType[] => dataInfo.map((dataset: any) => {
        const toTableStructure = {} as DatasetsTableDataType;

        toTableStructure.key = dataset.id;
        toTableStructure.title = dataset.title;
        toTableStructure.projectName = dataset.cvatProjectname;
        toTableStructure.creationDate = new Date(dataset.createdtime.unixtime).toLocaleDateString('ru-RU');

        const train = dataset.trainsetimages;
        const validation = dataset.validationsetimages;
        const test = dataset.testsetimages;

        toTableStructure.imageCount = train + validation + test;
        toTableStructure.subsets = `${train} | ${validation} | ${test}`;
        toTableStructure.classes = dataset.examplesbyclass.listList.length;

        return toTableStructure;
    });

    //! Получаем список ID существующих Датасетов
    const getAvailableDatasetsIDs: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const datasetsId = await client.getAllAvailableDatasets(new DatasetsCondition(), {});
            const availableValues = datasetsId.getIdsList();
            setIdsList(availableValues);
        }
    };

    //! Получаем список всех существующих Датасетов с их данными
    const getDatasetsInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const datasetsInfo = await client.getInfo(new ObjectsId().setIdsList(idsList || []), {});
            const dataObject = datasetsInfo.toObject().listList;
            setAllDatasetsData(formDataObject(dataObject));
        }
    };

    const showDeleteDatasetModal = async (id: number, name?: string): Promise<void> => {
        Modal.confirm({
            title: `Датасет "${name}" будет удален`,
            content: 'Все данные (изображения, аннотации) будут потеряны. Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Отменить',
            okText: 'Удалить',
            onOk: () => {
                //! Удаляем датасет
                (async () => {
                    if (currentHost) {
                        const client = createEnhancedClient(DatasetsServiceClient);

                        await client
                            .delete(new ObjectsId().addIds(id), {})
                            .then(() => {
                                notification.success({
                                    message: 'Удаление датасета',
                                    description: 'Датасет успешно удален.',
                                });

                                getDatasetsInfo();
                            })
                            .catch((error) => {
                                notification.error({
                                    message: 'Удаление датасета',
                                    description: 'Ошибка удаления датасета.',
                                });
                                console.error(error);
                            });
                    }
                })();
            },
            onCancel() {},
        });
    };

    const openMergeDatasetsModal = () => {
        setIsMergeDatasetsModalOpen(true);
    };

    const handleMergeDatasetsModalOk = (datasetResponse: Dataset) => {
        setIsMergeDatasetsModalOpen(false);
        const newDataset = formDataObject([datasetResponse.toObject()])[0];
        setAllDatasetsData(prev => prev?.length ? [...prev, newDataset] : [newDataset]);
    };

    const handleMergeDatasetsModalCancel = () => {
        setIsMergeDatasetsModalOpen(false);
    };

    //! Получаем список ID отфильтрованных Датасетов
    const getFilteredDatasetsIDs: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            if (filtersParamsData) {
                const datasetsId = await client.getAllAvailableDatasets(filtersParamsData, {});
                const filteredValues = datasetsId.getIdsList();
                setIdsFiltersList(filteredValues);
            }
        }
    };

    //! Получаем список отфильтрованных Датасетов с их данными
    const getFilteredDatasetsInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const datasetsInfo = await client.getInfo(new ObjectsId().setIdsList(idsFiltersList || []), {});
            const filteredObject = datasetsInfo.toObject().listList;
            setFilteredDatasetsData(formDataObject(filteredObject));
        }
    };

    // Запуск функции получения списка ID существующих Датасетов - GRPC
    useEffect(() => {
        setLoadTableDataSpin(true);
        getAvailableDatasetsIDs();
    }, []);

    // Запуск функции получения данных Датасетов по списку ID существующих Датасетов - GRPC
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            getDatasetsInfo();
        }
    }, [idsList]);

    // Запуск функции получения списка ID отфильтрованных Датасетов - GRPC
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else if (filtersParamsData) {
            setLoadTableDataSpin(true);
            getFilteredDatasetsIDs();
        } else {
            setIdsFiltersList(undefined);
            setFilteredDatasetsData(undefined);
            // TODO: отфильтровать выбранные с помощью чек-боксов строки по фильтрам
        }
    }, [filtersParamsData]);

    // Запуск функции получения данных Датасетов по списку ID отфильтрованных Датасетов - GRPC
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else if (idsFiltersList) {
            getFilteredDatasetsInfo();
        }
    }, [idsFiltersList]);

    // Отключения индикатора загрузки данных таблицы - спиннер
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else if (filtersParamsData) {
            if (filteredDatasetsData) {
                setLoadTableDataSpin(false);
            }
        } else if (allDatasetsData) {
            setLoadTableDataSpin(false);
        }
    }, [allDatasetsData, filteredDatasetsData]);

    // Функция поиска По названию Датасета/Проекта в столбцах таблицы
    const getColumnSearchProps = (dataIndex: TableDataIndex): ColumnType<DatasetsTableDataType> => ({
        filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters, close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()} aria-hidden='true'>
                <Input
                    ref={searchInput}
                    placeholder='Найти...'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            close();
                        }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                clearFilters();
                            }

                            confirm({ closeDropdown: false });
                            close();
                        }}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Сбросить
                    </Button>
                    <Button type='link' size='small' onClick={() => close()}>
                        Закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#00F8E0' : undefined }} />,
        onFilter: (value, record) => record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (result) => result,
    });

    // Колонки таблицы
    const tableColumns: ColumnsType<DatasetsTableDataType> = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            width: 400,
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Проект',
            dataIndex: 'projectName',
            key: 'projectName',
            width: 325,
            sorter: (a, b) => a.projectName.localeCompare(b.projectName),
            sortDirections: ['ascend', 'descend'],
            ...getColumnSearchProps('projectName'),
        },
        {
            title: 'Дата создания',
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: 190,
        },
        {
            title: 'Изображения',
            key: 'imageCount',
            dataIndex: 'imageCount',
            width: 180,
            sorter: (a, b) => a.imageCount - b.imageCount,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Выборка',
            dataIndex: 'subsets',
            key: 'subsets',
            width: 165,
        },
        {
            title: 'Классы',
            key: 'classes',
            dataIndex: 'classes',
            width: 165,
            sorter: (a, b) => a.classes - b.classes,
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: 'Действия',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <Space align='center' style={{ color: '#647D89', cursor: 'pointer' }}>
                    <InfoCircleOutlined
                        onClick={() => history.push({ search: `?info=${record.key}`, pathname: '/dataset' })}
                        style={{ fontSize: 18, verticalAlign: 'sub' }}
                    />
                    <Typography.Link onClick={() => showDeleteDatasetModal(Number(record.key), record.title)}>
                        Удалить
                    </Typography.Link>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys: selectedDatasets?.length ? selectedDatasets.map(dataset => dataset.key) : [],
        onChange: (selectedRowKeys: React.Key[], selectedRows: DatasetsTableDataType[]) => {
            setSelectedDatasets(selectedRows);
        },
        // getCheckboxProps: (record: TableDataType) => ({
        //     // disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //     selected: !!selectedDatasets?.length && selectedDatasets.findIndex(dataset => dataset.key === record.key) !== -1,
        //     // name: record.name,
        // }),
    };

    return (
        <div className='datasets-page-container'>
            <DatasetsTopBar />
            <DatasetsFilters setFiltersParamsData={setFiltersParamsData} />
            <div className='datasets-buttons-container'>
                <Button
                    type='primary'
                    icon={<MergeCellsOutlined />}
                    className='datasets-header-button'
                    disabled={!(selectedDatasets && selectedDatasets.length > 1)}
                    onClick={openMergeDatasetsModal}
                >
                    Слияние
                </Button>
                {/*<Button*/}
                {/*    type='primary'*/}
                {/*    icon={<DeleteOutlined />}*/}
                {/*    className='datasets-header-button'*/}
                {/*    disabled={!selectedDatasets?.length}*/}
                {/*>*/}
                {/*    Удалить*/}
                {/*</Button>*/}
            </div>
            <Table
                key={`${Date.now()}_loading-done`}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                showSorterTooltip={false}
                dataSource={filteredDatasetsData || allDatasetsData}
                columns={tableColumns}
                pagination={false}
                loading={{ spinning: loadTableDataSpin, tip: 'Загрузка данных...', size: 'small' }}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет данных' /> }}
            />
            <MergeDatasetsModal open={isMergeDatasetsModalOpen} onOk={handleMergeDatasetsModalOk} onCancel={handleMergeDatasetsModalCancel} datasets={selectedDatasets} />
        </div>
    );
};

export default DatasetsPage;
