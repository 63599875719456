import { Menu, Modal, notification } from 'antd';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { ObjectsId } from 'proto/Common_pb';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { getClientHost } from 'shared/constants';
import { createEnhancedClient } from '../../utils/grpc';

interface DatasetActionsMenuProps {
    datasetID: number[] | undefined;
    datasetData: Dataset.AsObject | undefined;
}

const DatasetActionsMenu = (props: DatasetActionsMenuProps): JSX.Element => {
    const { datasetID, datasetData } = props;

    const history = useHistory();

    const currentHost = getClientHost();

    const onDownloadDataset = (): void => {
        const datasetLink = datasetData?.ziparchive?.url;
        if (datasetLink) {
            notification.success({
                message: 'Скачивание датасета',
                description: 'Процесс скачивания датасета успешно начат',
            });

            document.location = datasetLink?.replace(/^.*(?=\/center\/download.*)/, '');
        }
    };

    const onDeleteDataset = useCallback((): void => {
        Modal.confirm({
            title: 'Датасет будет удален',
            content: 'Все данные (изображения, аннотации) будут потеряны. Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            onOk: () => {
                //! Удаляем датасет
                (async () => {
                    if (currentHost) {
                        const client = createEnhancedClient(DatasetsServiceClient);

                        await client
                            .delete(
                                new ObjectsId()
                                    .setIdsList(datasetID || []),
                                {},
                            )
                            .then(() => {
                                notification.success({
                                    message: 'Удаление датасета',
                                    description: 'Датасет успешно удален.',
                                });

                                history.push('/datasets');
                            })
                            .catch((error) => {
                                notification.error({
                                    message: 'Удаление датасета',
                                    description: 'Ошибка удаления датасета.',
                                });
                                console.error(error);
                            });
                    }
                })();
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Отменить',
            okText: 'Удалить',
        });
    }, []);

    return (
        <Menu selectable={false} className='cvat-project-actions-menu'>
            <Menu.Item key='download' onClick={onDownloadDataset}>
                Скачать датасет
            </Menu.Item>
            <Menu.Item key='delete' onClick={onDeleteDataset}>
                Удалить
            </Menu.Item>
        </Menu>
    );
};

export default DatasetActionsMenu;
