// source: c60/nn/center/DataSets.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../../../Common_pb.js');
goog.object.extend(proto, Common_pb);
var nn_training_DetectorTraining_pb = require('../../../nn_training/DetectorTraining_pb.js');
goog.object.extend(proto, nn_training_DetectorTraining_pb);
goog.exportSymbol('proto.grpc.c60.nn.center.ClassExamples', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.ClassesExamplesList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.CreateYoloDatasetParams', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.Dataset', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DatasetFormat', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DatasetList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DatasetPart', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DatasetPartition', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DatasetsCondition', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.Mark', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.MarkedImage', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.MarkedImageList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.MergeDatasets', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.ViewImagesCondition', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.Dataset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.Dataset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.Dataset.displayName = 'proto.grpc.c60.nn.center.Dataset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DatasetList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.DatasetList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.DatasetList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DatasetList.displayName = 'proto.grpc.c60.nn.center.DatasetList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.ClassExamples = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.ClassExamples, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.ClassExamples.displayName = 'proto.grpc.c60.nn.center.ClassExamples';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.ClassesExamplesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.ClassesExamplesList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.ClassesExamplesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.ClassesExamplesList.displayName = 'proto.grpc.c60.nn.center.ClassesExamplesList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.MarkedImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.MarkedImage.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.MarkedImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.MarkedImage.displayName = 'proto.grpc.c60.nn.center.MarkedImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.MarkedImageList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.MarkedImageList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.MarkedImageList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.MarkedImageList.displayName = 'proto.grpc.c60.nn.center.MarkedImageList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.Mark = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.Mark, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.Mark.displayName = 'proto.grpc.c60.nn.center.Mark';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.ViewImagesCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.ViewImagesCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.ViewImagesCondition.displayName = 'proto.grpc.c60.nn.center.ViewImagesCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DatasetPartition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.DatasetPartition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DatasetPartition.displayName = 'proto.grpc.c60.nn.center.DatasetPartition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.CreateYoloDatasetParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.CreateYoloDatasetParams.displayName = 'proto.grpc.c60.nn.center.CreateYoloDatasetParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.MergeDatasets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.MergeDatasets.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.MergeDatasets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.MergeDatasets.displayName = 'proto.grpc.c60.nn.center.MergeDatasets';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DatasetsCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.DatasetsCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DatasetsCondition.displayName = 'proto.grpc.c60.nn.center.DatasetsCondition';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.Dataset.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.Dataset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.Dataset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Dataset.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    format: jspb.Message.getFieldWithDefault(msg, 3, 0),
    authortitle: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cvatAuthorId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    cvatProjectId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    cvatProjectname: jspb.Message.getFieldWithDefault(msg, 7, ""),
    imagesize: (f = msg.getImagesize()) && Common_pb.MatrixSize.toObject(includeInstance, f),
    createdtime: (f = msg.getCreatedtime()) && Common_pb.Time.toObject(includeInstance, f),
    trainsetimages: jspb.Message.getFieldWithDefault(msg, 10, 0),
    validationsetimages: jspb.Message.getFieldWithDefault(msg, 11, 0),
    testsetimages: jspb.Message.getFieldWithDefault(msg, 12, 0),
    examplesbyclass: (f = msg.getExamplesbyclass()) && proto.grpc.c60.nn.center.ClassesExamplesList.toObject(includeInstance, f),
    augmentation: (f = msg.getAugmentation()) && nn_training_DetectorTraining_pb.YoloAugmentationConfig.toObject(includeInstance, f),
    crc: jspb.Message.getFieldWithDefault(msg, 16, ""),
    archivebytelen: jspb.Message.getFieldWithDefault(msg, 17, 0),
    ziparchive: (f = msg.getZiparchive()) && Common_pb.File.toObject(includeInstance, f),
    organization: (f = msg.getOrganization()) && Common_pb.ObjectRef.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.Dataset}
 */
proto.grpc.c60.nn.center.Dataset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.Dataset;
  return proto.grpc.c60.nn.center.Dataset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.Dataset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.Dataset}
 */
proto.grpc.c60.nn.center.Dataset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.grpc.c60.nn.center.DatasetFormat} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthortitle(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCvatAuthorId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCvatProjectId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCvatProjectname(value);
      break;
    case 8:
      var value = new Common_pb.MatrixSize;
      reader.readMessage(value,Common_pb.MatrixSize.deserializeBinaryFromReader);
      msg.setImagesize(value);
      break;
    case 9:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setCreatedtime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrainsetimages(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidationsetimages(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTestsetimages(value);
      break;
    case 13:
      var value = new proto.grpc.c60.nn.center.ClassesExamplesList;
      reader.readMessage(value,proto.grpc.c60.nn.center.ClassesExamplesList.deserializeBinaryFromReader);
      msg.setExamplesbyclass(value);
      break;
    case 15:
      var value = new nn_training_DetectorTraining_pb.YoloAugmentationConfig;
      reader.readMessage(value,nn_training_DetectorTraining_pb.YoloAugmentationConfig.deserializeBinaryFromReader);
      msg.setAugmentation(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrc(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArchivebytelen(value);
      break;
    case 18:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setZiparchive(value);
      break;
    case 19:
      var value = new Common_pb.ObjectRef;
      reader.readMessage(value,Common_pb.ObjectRef.deserializeBinaryFromReader);
      msg.setOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.Dataset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.Dataset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.Dataset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Dataset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.grpc.c60.nn.center.DatasetFormat} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImagesize();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      Common_pb.MatrixSize.serializeBinaryToWriter
    );
  }
  f = message.getCreatedtime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getExamplesbyclass();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.grpc.c60.nn.center.ClassesExamplesList.serializeBinaryToWriter
    );
  }
  f = message.getAugmentation();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      nn_training_DetectorTraining_pb.YoloAugmentationConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeInt64(
      17,
      f
    );
  }
  f = message.getZiparchive();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
  f = message.getOrganization();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      Common_pb.ObjectRef.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DatasetFormat format = 3;
 * @return {!proto.grpc.c60.nn.center.DatasetFormat}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getFormat = function() {
  return /** @type {!proto.grpc.c60.nn.center.DatasetFormat} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grpc.c60.nn.center.DatasetFormat} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setFormat = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearFormat = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasFormat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string authorTitle = 4;
 * @return {string}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getAuthortitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setAuthortitle = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearAuthortitle = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasAuthortitle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 cvat_author_id = 5;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getCvatAuthorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setCvatAuthorId = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearCvatAuthorId = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasCvatAuthorId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int32 cvat_project_id = 6;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getCvatProjectId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setCvatProjectId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearCvatProjectId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasCvatProjectId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string cvat_projectName = 7;
 * @return {string}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getCvatProjectname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setCvatProjectname = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearCvatProjectname = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasCvatProjectname = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.MatrixSize imageSize = 8;
 * @return {?proto.common.MatrixSize}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getImagesize = function() {
  return /** @type{?proto.common.MatrixSize} */ (
    jspb.Message.getWrapperField(this, Common_pb.MatrixSize, 8));
};


/**
 * @param {?proto.common.MatrixSize|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setImagesize = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearImagesize = function() {
  return this.setImagesize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasImagesize = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional common.Time createdTime = 9;
 * @return {?proto.common.Time}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getCreatedtime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 9));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setCreatedtime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearCreatedtime = function() {
  return this.setCreatedtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasCreatedtime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional int32 trainSetImages = 10;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getTrainsetimages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setTrainsetimages = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearTrainsetimages = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasTrainsetimages = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 validationSetImages = 11;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getValidationsetimages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setValidationsetimages = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearValidationsetimages = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasValidationsetimages = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int32 testSetImages = 12;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getTestsetimages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setTestsetimages = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearTestsetimages = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasTestsetimages = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional ClassesExamplesList examplesByClass = 13;
 * @return {?proto.grpc.c60.nn.center.ClassesExamplesList}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getExamplesbyclass = function() {
  return /** @type{?proto.grpc.c60.nn.center.ClassesExamplesList} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.ClassesExamplesList, 13));
};


/**
 * @param {?proto.grpc.c60.nn.center.ClassesExamplesList|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setExamplesbyclass = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearExamplesbyclass = function() {
  return this.setExamplesbyclass(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasExamplesbyclass = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional grpc.c60.nn.training.YoloAugmentationConfig augmentation = 15;
 * @return {?proto.grpc.c60.nn.training.YoloAugmentationConfig}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getAugmentation = function() {
  return /** @type{?proto.grpc.c60.nn.training.YoloAugmentationConfig} */ (
    jspb.Message.getWrapperField(this, nn_training_DetectorTraining_pb.YoloAugmentationConfig, 15));
};


/**
 * @param {?proto.grpc.c60.nn.training.YoloAugmentationConfig|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setAugmentation = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearAugmentation = function() {
  return this.setAugmentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasAugmentation = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string crc = 16;
 * @return {string}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getCrc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setCrc = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearCrc = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasCrc = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional int64 archiveByteLen = 17;
 * @return {number}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getArchivebytelen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.setArchivebytelen = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearArchivebytelen = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasArchivebytelen = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional common.File zipArchive = 18;
 * @return {?proto.common.File}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getZiparchive = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 18));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setZiparchive = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearZiparchive = function() {
  return this.setZiparchive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasZiparchive = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional common.ObjectRef organization = 19;
 * @return {?proto.common.ObjectRef}
 */
proto.grpc.c60.nn.center.Dataset.prototype.getOrganization = function() {
  return /** @type{?proto.common.ObjectRef} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectRef, 19));
};


/**
 * @param {?proto.common.ObjectRef|undefined} value
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
*/
proto.grpc.c60.nn.center.Dataset.prototype.setOrganization = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Dataset} returns this
 */
proto.grpc.c60.nn.center.Dataset.prototype.clearOrganization = function() {
  return this.setOrganization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Dataset.prototype.hasOrganization = function() {
  return jspb.Message.getField(this, 19) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.DatasetList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DatasetList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DatasetList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DatasetList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.Dataset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DatasetList}
 */
proto.grpc.c60.nn.center.DatasetList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DatasetList;
  return proto.grpc.c60.nn.center.DatasetList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DatasetList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DatasetList}
 */
proto.grpc.c60.nn.center.DatasetList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.Dataset;
      reader.readMessage(value,proto.grpc.c60.nn.center.Dataset.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DatasetList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DatasetList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DatasetList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.Dataset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dataset list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.Dataset>}
 */
proto.grpc.c60.nn.center.DatasetList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.Dataset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.Dataset, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.Dataset>} value
 * @return {!proto.grpc.c60.nn.center.DatasetList} returns this
*/
proto.grpc.c60.nn.center.DatasetList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.Dataset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.Dataset}
 */
proto.grpc.c60.nn.center.DatasetList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.Dataset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.DatasetList} returns this
 */
proto.grpc.c60.nn.center.DatasetList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.ClassExamples.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.ClassExamples} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ClassExamples.toObject = function(includeInstance, msg) {
  var f, obj = {
    classid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    trainexamples: jspb.Message.getFieldWithDefault(msg, 2, 0),
    validationexamples: jspb.Message.getFieldWithDefault(msg, 3, 0),
    testexamples: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.ClassExamples}
 */
proto.grpc.c60.nn.center.ClassExamples.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.ClassExamples;
  return proto.grpc.c60.nn.center.ClassExamples.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.ClassExamples} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.ClassExamples}
 */
proto.grpc.c60.nn.center.ClassExamples.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClassid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrainexamples(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValidationexamples(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTestexamples(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.ClassExamples.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.ClassExamples} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ClassExamples.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTrainexamples();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getValidationexamples();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTestexamples();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 classId = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.getClassid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.ClassExamples} returns this
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.setClassid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 trainExamples = 2;
 * @return {number}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.getTrainexamples = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.ClassExamples} returns this
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.setTrainexamples = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 validationExamples = 3;
 * @return {number}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.getValidationexamples = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.ClassExamples} returns this
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.setValidationexamples = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 testExamples = 4;
 * @return {number}
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.getTestexamples = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.ClassExamples} returns this
 */
proto.grpc.c60.nn.center.ClassExamples.prototype.setTestexamples = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.ClassesExamplesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.ClassesExamplesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.ClassesExamplesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ClassesExamplesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.ClassExamples.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.ClassesExamplesList}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.ClassesExamplesList;
  return proto.grpc.c60.nn.center.ClassesExamplesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.ClassesExamplesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.ClassesExamplesList}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.ClassExamples;
      reader.readMessage(value,proto.grpc.c60.nn.center.ClassExamples.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.ClassesExamplesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.ClassesExamplesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ClassesExamplesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.ClassExamples.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ClassExamples list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.ClassExamples>}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.ClassExamples>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.ClassExamples, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.ClassExamples>} value
 * @return {!proto.grpc.c60.nn.center.ClassesExamplesList} returns this
*/
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.ClassExamples=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.ClassExamples}
 */
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.ClassExamples, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.ClassesExamplesList} returns this
 */
proto.grpc.c60.nn.center.ClassesExamplesList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.MarkedImage.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.MarkedImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.MarkedImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MarkedImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageurl: jspb.Message.getFieldWithDefault(msg, 1, ""),
    marksList: jspb.Message.toObjectList(msg.getMarksList(),
    proto.grpc.c60.nn.center.Mark.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.MarkedImage}
 */
proto.grpc.c60.nn.center.MarkedImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.MarkedImage;
  return proto.grpc.c60.nn.center.MarkedImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.MarkedImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.MarkedImage}
 */
proto.grpc.c60.nn.center.MarkedImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 2:
      var value = new proto.grpc.c60.nn.center.Mark;
      reader.readMessage(value,proto.grpc.c60.nn.center.Mark.deserializeBinaryFromReader);
      msg.addMarks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.MarkedImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.MarkedImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MarkedImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grpc.c60.nn.center.Mark.serializeBinaryToWriter
    );
  }
};


/**
 * optional string imageURL = 1;
 * @return {string}
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.MarkedImage} returns this
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Mark marks = 2;
 * @return {!Array<!proto.grpc.c60.nn.center.Mark>}
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.getMarksList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.Mark>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.Mark, 2));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.Mark>} value
 * @return {!proto.grpc.c60.nn.center.MarkedImage} returns this
*/
proto.grpc.c60.nn.center.MarkedImage.prototype.setMarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.Mark=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.Mark}
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.addMarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grpc.c60.nn.center.Mark, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.MarkedImage} returns this
 */
proto.grpc.c60.nn.center.MarkedImage.prototype.clearMarksList = function() {
  return this.setMarksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.MarkedImageList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.MarkedImageList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.MarkedImageList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.MarkedImageList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MarkedImageList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.MarkedImage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.MarkedImageList}
 */
proto.grpc.c60.nn.center.MarkedImageList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.MarkedImageList;
  return proto.grpc.c60.nn.center.MarkedImageList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.MarkedImageList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.MarkedImageList}
 */
proto.grpc.c60.nn.center.MarkedImageList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.MarkedImage;
      reader.readMessage(value,proto.grpc.c60.nn.center.MarkedImage.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.MarkedImageList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.MarkedImageList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.MarkedImageList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MarkedImageList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.MarkedImage.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarkedImage list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.MarkedImage>}
 */
proto.grpc.c60.nn.center.MarkedImageList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.MarkedImage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.MarkedImage, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.MarkedImage>} value
 * @return {!proto.grpc.c60.nn.center.MarkedImageList} returns this
*/
proto.grpc.c60.nn.center.MarkedImageList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.MarkedImage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.MarkedImage}
 */
proto.grpc.c60.nn.center.MarkedImageList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.MarkedImage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.MarkedImageList} returns this
 */
proto.grpc.c60.nn.center.MarkedImageList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.Mark.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.Mark.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.Mark} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Mark.toObject = function(includeInstance, msg) {
  var f, obj = {
    classid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    classname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    percentrect: (f = msg.getPercentrect()) && Common_pb.MBR.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.Mark}
 */
proto.grpc.c60.nn.center.Mark.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.Mark;
  return proto.grpc.c60.nn.center.Mark.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.Mark} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.Mark}
 */
proto.grpc.c60.nn.center.Mark.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClassid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassname(value);
      break;
    case 3:
      var value = new Common_pb.MBR;
      reader.readMessage(value,Common_pb.MBR.deserializeBinaryFromReader);
      msg.setPercentrect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.Mark.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.Mark.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.Mark} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Mark.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getClassname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPercentrect();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.MBR.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 classId = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.Mark.prototype.getClassid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Mark} returns this
 */
proto.grpc.c60.nn.center.Mark.prototype.setClassid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Mark} returns this
 */
proto.grpc.c60.nn.center.Mark.prototype.clearClassid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Mark.prototype.hasClassid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string className = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.Mark.prototype.getClassname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Mark} returns this
 */
proto.grpc.c60.nn.center.Mark.prototype.setClassname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional common.MBR percentRect = 3;
 * @return {?proto.common.MBR}
 */
proto.grpc.c60.nn.center.Mark.prototype.getPercentrect = function() {
  return /** @type{?proto.common.MBR} */ (
    jspb.Message.getWrapperField(this, Common_pb.MBR, 3));
};


/**
 * @param {?proto.common.MBR|undefined} value
 * @return {!proto.grpc.c60.nn.center.Mark} returns this
*/
proto.grpc.c60.nn.center.Mark.prototype.setPercentrect = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Mark} returns this
 */
proto.grpc.c60.nn.center.Mark.prototype.clearPercentrect = function() {
  return this.setPercentrect(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Mark.prototype.hasPercentrect = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.ViewImagesCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.ViewImagesCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ViewImagesCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    part: jspb.Message.getFieldWithDefault(msg, 2, 0),
    classes: (f = msg.getClasses()) && Common_pb.ObjectsId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.ViewImagesCondition;
  return proto.grpc.c60.nn.center.ViewImagesCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.ViewImagesCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDatasetid(value);
      break;
    case 2:
      var value = /** @type {!proto.grpc.c60.nn.center.DatasetPart} */ (reader.readEnum());
      msg.setPart(value);
      break;
    case 3:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.ViewImagesCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.ViewImagesCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.ViewImagesCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatasetid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {!proto.grpc.c60.nn.center.DatasetPart} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClasses();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 datasetId = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.getDatasetid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition} returns this
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.setDatasetid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DatasetPart part = 2;
 * @return {!proto.grpc.c60.nn.center.DatasetPart}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.getPart = function() {
  return /** @type {!proto.grpc.c60.nn.center.DatasetPart} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grpc.c60.nn.center.DatasetPart} value
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition} returns this
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.setPart = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition} returns this
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.clearPart = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.hasPart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.ObjectsId classes = 3;
 * @return {?proto.common.ObjectsId}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.getClasses = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 3));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition} returns this
*/
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.setClasses = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.ViewImagesCondition} returns this
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.clearClasses = function() {
  return this.setClasses(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.ViewImagesCondition.prototype.hasClasses = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DatasetPartition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DatasetPartition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetPartition.toObject = function(includeInstance, msg) {
  var f, obj = {
    trainpart: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    validationpart: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DatasetPartition}
 */
proto.grpc.c60.nn.center.DatasetPartition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DatasetPartition;
  return proto.grpc.c60.nn.center.DatasetPartition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DatasetPartition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DatasetPartition}
 */
proto.grpc.c60.nn.center.DatasetPartition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTrainpart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValidationpart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DatasetPartition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DatasetPartition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetPartition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrainpart();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getValidationpart();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float trainPart = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.getTrainpart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.DatasetPartition} returns this
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.setTrainpart = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float validationPart = 2;
 * @return {number}
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.getValidationpart = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.DatasetPartition} returns this
 */
proto.grpc.c60.nn.center.DatasetPartition.prototype.setValidationpart = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.CreateYoloDatasetParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    cvatprojectid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    partition: (f = msg.getPartition()) && proto.grpc.c60.nn.center.DatasetPartition.toObject(includeInstance, f),
    datasetconfig: (f = msg.getDatasetconfig()) && proto.grpc.c60.nn.center.Dataset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.CreateYoloDatasetParams;
  return proto.grpc.c60.nn.center.CreateYoloDatasetParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCvatprojectid(value);
      break;
    case 2:
      var value = new proto.grpc.c60.nn.center.DatasetPartition;
      reader.readMessage(value,proto.grpc.c60.nn.center.DatasetPartition.deserializeBinaryFromReader);
      msg.setPartition(value);
      break;
    case 3:
      var value = new proto.grpc.c60.nn.center.Dataset;
      reader.readMessage(value,proto.grpc.c60.nn.center.Dataset.deserializeBinaryFromReader);
      msg.setDatasetconfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.CreateYoloDatasetParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCvatprojectid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPartition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.c60.nn.center.DatasetPartition.serializeBinaryToWriter
    );
  }
  f = message.getDatasetconfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.c60.nn.center.Dataset.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 cvatProjectId = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.getCvatprojectid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} returns this
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.setCvatprojectid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional DatasetPartition partition = 2;
 * @return {?proto.grpc.c60.nn.center.DatasetPartition}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.getPartition = function() {
  return /** @type{?proto.grpc.c60.nn.center.DatasetPartition} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.DatasetPartition, 2));
};


/**
 * @param {?proto.grpc.c60.nn.center.DatasetPartition|undefined} value
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} returns this
*/
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.setPartition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} returns this
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.clearPartition = function() {
  return this.setPartition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.hasPartition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Dataset datasetConfig = 3;
 * @return {?proto.grpc.c60.nn.center.Dataset}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.getDatasetconfig = function() {
  return /** @type{?proto.grpc.c60.nn.center.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Dataset, 3));
};


/**
 * @param {?proto.grpc.c60.nn.center.Dataset|undefined} value
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} returns this
*/
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.setDatasetconfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.CreateYoloDatasetParams} returns this
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.clearDatasetconfig = function() {
  return this.setDatasetconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.CreateYoloDatasetParams.prototype.hasDatasetconfig = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.MergeDatasets.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.MergeDatasets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.MergeDatasets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MergeDatasets.toObject = function(includeInstance, msg) {
  var f, obj = {
    datasetsidsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    partition: (f = msg.getPartition()) && proto.grpc.c60.nn.center.DatasetPartition.toObject(includeInstance, f),
    datasetconfig: (f = msg.getDatasetconfig()) && proto.grpc.c60.nn.center.Dataset.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.MergeDatasets}
 */
proto.grpc.c60.nn.center.MergeDatasets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.MergeDatasets;
  return proto.grpc.c60.nn.center.MergeDatasets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.MergeDatasets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.MergeDatasets}
 */
proto.grpc.c60.nn.center.MergeDatasets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addDatasetsids(values[i]);
      }
      break;
    case 2:
      var value = new proto.grpc.c60.nn.center.DatasetPartition;
      reader.readMessage(value,proto.grpc.c60.nn.center.DatasetPartition.deserializeBinaryFromReader);
      msg.setPartition(value);
      break;
    case 3:
      var value = new proto.grpc.c60.nn.center.Dataset;
      reader.readMessage(value,proto.grpc.c60.nn.center.Dataset.deserializeBinaryFromReader);
      msg.setDatasetconfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.MergeDatasets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.MergeDatasets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.MergeDatasets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatasetsidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      1,
      f
    );
  }
  f = message.getPartition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.c60.nn.center.DatasetPartition.serializeBinaryToWriter
    );
  }
  f = message.getDatasetconfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.c60.nn.center.Dataset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int32 datasetsIds = 1;
 * @return {!Array<number>}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.getDatasetsidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.setDatasetsidsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.addDatasetsids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.clearDatasetsidsList = function() {
  return this.setDatasetsidsList([]);
};


/**
 * optional DatasetPartition partition = 2;
 * @return {?proto.grpc.c60.nn.center.DatasetPartition}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.getPartition = function() {
  return /** @type{?proto.grpc.c60.nn.center.DatasetPartition} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.DatasetPartition, 2));
};


/**
 * @param {?proto.grpc.c60.nn.center.DatasetPartition|undefined} value
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
*/
proto.grpc.c60.nn.center.MergeDatasets.prototype.setPartition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.clearPartition = function() {
  return this.setPartition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.hasPartition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Dataset datasetConfig = 3;
 * @return {?proto.grpc.c60.nn.center.Dataset}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.getDatasetconfig = function() {
  return /** @type{?proto.grpc.c60.nn.center.Dataset} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Dataset, 3));
};


/**
 * @param {?proto.grpc.c60.nn.center.Dataset|undefined} value
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
*/
proto.grpc.c60.nn.center.MergeDatasets.prototype.setDatasetconfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.MergeDatasets} returns this
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.clearDatasetconfig = function() {
  return this.setDatasetconfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.MergeDatasets.prototype.hasDatasetconfig = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DatasetsCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DatasetsCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetsCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    fulltextsearch: jspb.Message.getFieldWithDefault(msg, 1, ""),
    creation: (f = msg.getCreation()) && Common_pb.TimeInterval.toObject(includeInstance, f),
    imagescount: (f = msg.getImagescount()) && Common_pb.Interval.toObject(includeInstance, f),
    classesid: (f = msg.getClassesid()) && Common_pb.ObjectsId.toObject(includeInstance, f),
    authorcondition: (f = msg.getAuthorcondition()) && Common_pb.ObjectsId.toObject(includeInstance, f),
    projectscondition: (f = msg.getProjectscondition()) && Common_pb.ObjectsId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition}
 */
proto.grpc.c60.nn.center.DatasetsCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DatasetsCondition;
  return proto.grpc.c60.nn.center.DatasetsCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DatasetsCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition}
 */
proto.grpc.c60.nn.center.DatasetsCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulltextsearch(value);
      break;
    case 2:
      var value = new Common_pb.TimeInterval;
      reader.readMessage(value,Common_pb.TimeInterval.deserializeBinaryFromReader);
      msg.setCreation(value);
      break;
    case 4:
      var value = new Common_pb.Interval;
      reader.readMessage(value,Common_pb.Interval.deserializeBinaryFromReader);
      msg.setImagescount(value);
      break;
    case 5:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setClassesid(value);
      break;
    case 6:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setAuthorcondition(value);
      break;
    case 7:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setProjectscondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DatasetsCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DatasetsCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DatasetsCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      Common_pb.TimeInterval.serializeBinaryToWriter
    );
  }
  f = message.getImagescount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.Interval.serializeBinaryToWriter
    );
  }
  f = message.getClassesid();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
  f = message.getAuthorcondition();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
  f = message.getProjectscondition();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fullTextSearch = 1;
 * @return {string}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getFulltextsearch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setFulltextsearch = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearFulltextsearch = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasFulltextsearch = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.TimeInterval creation = 2;
 * @return {?proto.common.TimeInterval}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getCreation = function() {
  return /** @type{?proto.common.TimeInterval} */ (
    jspb.Message.getWrapperField(this, Common_pb.TimeInterval, 2));
};


/**
 * @param {?proto.common.TimeInterval|undefined} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
*/
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setCreation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearCreation = function() {
  return this.setCreation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasCreation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.Interval imagesCount = 4;
 * @return {?proto.common.Interval}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getImagescount = function() {
  return /** @type{?proto.common.Interval} */ (
    jspb.Message.getWrapperField(this, Common_pb.Interval, 4));
};


/**
 * @param {?proto.common.Interval|undefined} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
*/
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setImagescount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearImagescount = function() {
  return this.setImagescount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasImagescount = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.ObjectsId classesId = 5;
 * @return {?proto.common.ObjectsId}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getClassesid = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 5));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
*/
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setClassesid = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearClassesid = function() {
  return this.setClassesid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasClassesid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.ObjectsId authorCondition = 6;
 * @return {?proto.common.ObjectsId}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getAuthorcondition = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 6));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
*/
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setAuthorcondition = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearAuthorcondition = function() {
  return this.setAuthorcondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasAuthorcondition = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ObjectsId projectsCondition = 7;
 * @return {?proto.common.ObjectsId}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.getProjectscondition = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 7));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
*/
proto.grpc.c60.nn.center.DatasetsCondition.prototype.setProjectscondition = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DatasetsCondition} returns this
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.clearProjectscondition = function() {
  return this.setProjectscondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DatasetsCondition.prototype.hasProjectscondition = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.DatasetFormat = {
  NONEFORMAT: 0,
  CAMVID: 1,
  CITYSCAPES: 2,
  COCO: 3,
  COCOKEYPOINTS: 4,
  CVAT4IMAGES: 5,
  CVAT4VIDEO: 6,
  DATUMARO: 7,
  ICDAR_LOCALISATION: 8,
  ICDAR_RECOGNITION: 9,
  ICDAR_SEGMENTATION: 10,
  KITI: 11,
  LABELME: 12,
  LFW: 13,
  MARKET1501: 14,
  MOT: 16,
  MOTSPNG: 17,
  OPENIMAGESV6: 18,
  PASCALVOC1_1: 19,
  SEGMENTATIONMASK1_1: 20,
  TFRECORD: 21,
  VGGFACE2: 22,
  WIDERFACE: 23,
  YOLO1_1: 24
};

/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.DatasetPart = {
  NONEPART: 0,
  TRAIN: 1,
  VALIDATION: 2,
  TEST: 3
};

goog.object.extend(exports, proto.grpc.c60.nn.center);
