import React, { useState } from 'react';
import { Button, Typography, Space } from 'antd';

import { getClientHost } from 'shared/constants';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { StringSearch } from 'proto/Common_pb';
import { createEnhancedClient } from '../../utils/grpc';

const { Text, Title } = Typography;

const HelloWorldPageComponent: React.FC = () => {
    const [datasetsIds, setDatasetsIds] = useState<number[]>();

    const btnClickHandler: () => void = async () => {
        const currentHost = getClientHost();
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getAll(new StringSearch().setText(''), {});
            setDatasetsIds(datasets.getIdsList());
        }
    };

    return (
        <Space>
            <Title level={3}>GRPC hello-world!</Title>
            <Button onClick={btnClickHandler}>GRPC test</Button>
            <Text>{`received Datasets Ids: ${datasetsIds === undefined || datasetsIds?.length ? datasetsIds : '[]'}`}</Text>
        </Space>
    );
};

export default HelloWorldPageComponent;
