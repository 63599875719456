import { Empty, Modal, Tag, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { TrainingRunner } from 'proto/c60/nn/center/Detectors_pb';
import { TrainingQueryServiceClient, TrainingRunnerServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { ObjectsId } from 'proto/Common_pb';
import React, { useState } from 'react';
import { getClientHost } from 'shared/constants';
import { useQuery } from 'react-query';
import { createEnhancedClient } from 'utils/grpc';
import './styles.scss';
import EditableCell from '../editable-cell/editable-cell';
import LoadingCell from '../loading-cell/loading-cell';
import _ from 'lodash';

type TrainingServiceTableProps = {
    setDataSource: React.Dispatch<React.SetStateAction<TrainingRunner.AsObject[] | undefined>>;
    dataSource: TrainingRunner.AsObject[] | undefined;
    loading: boolean;
    refresh(): Promise<void>;
};

const TrainingServiceTable = ({ dataSource, loading, refresh }: TrainingServiceTableProps): JSX.Element => {
    const currentHost = getClientHost();
    const [currentPagination, setCurrentPagination] = useState<number>(1);

    useQuery('refreshservices', () => refresh(), {
        refetchInterval: 30000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const getTrainingQueryById = async (id: number): Promise<any> => {
        if (currentHost) {
            const client = createEnhancedClient(TrainingQueryServiceClient);
            const queryInfo = (await client.getInfo(new ObjectsId().setIdsList([id]), {})).getListList()[0];
            return queryInfo.getDetectorname();
        }
        return 0;
    };

    const deleteService = async (id?: number, name?: string): Promise<void> => {
        Modal.confirm({
            title: `Сервис "${name}" будет удален`,
            content: 'Если сервис занят обучением, исполняемый процесс не будет завершен. Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Отменить',
            okText: 'Удалить',
            async onOk() {
                if (currentHost && id) {
                    const client = createEnhancedClient(TrainingRunnerServiceClient);
                    await client.delete(new ObjectsId().addIds(id), {});
                    refresh();
                }
            },
            onCancel() {},
        });
    };

    const tableColumns: ColumnsType<TrainingRunner.AsObject> = [
        {
            title: 'Название',
            dataIndex: 'title',
            key: 'title',
            render: (value, record) => <EditableCell cellKey='title' record={record} refresh={refresh} value={value} />,
            width: 361,
        },
        {
            title: 'Адрес для подключения',
            dataIndex: 'address',
            key: 'address',
            render: (value, record) => (
                <EditableCell cellKey='address' record={record} refresh={refresh} value={value} />
            ),
            width: 361,
        },
        {
            title: 'Текущее состояние',
            dataIndex: 'online',
            key: 'online',
            render: (value: boolean) => (
                <div className='trainingservice-table-cell'>
                    {value ? (
                        <Tag className='status-tag online'>Доступен</Tag>
                    ) : (
                        <Tag className='status-tag offline'>Недоступен</Tag>
                    )}
                </div>
            ),
            width: 361,
        },
        {
            title: 'Исполняемый запрос',
            key: 'trainingqueryid',
            dataIndex: 'trainingqueryid',
            render: (e) => (
                <div className='trainingservice-table-cell'>
                    <LoadingCell query={getTrainingQueryById} query_value={e} />
                </div>
            ),
            width: 361,
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 130,
            render: (_, record) => (
                <Typography.Link
                    className='trainingservice-table-cell'
                    onClick={() => deleteService(record.id, record.title)}
                >
                    Удалить
                </Typography.Link>
            ),
        },
    ];

    return (
        <Table
            className='trainingservice-table'
            key={`${Date.now()}_loading-done`}
            showSorterTooltip={false}
            dataSource={_.cloneDeep(dataSource)}
            columns={tableColumns}
            pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
                showSizeChanger: false,
                current: currentPagination,
                onChange: (page) => setCurrentPagination(page),
            }}
            loading={{ spinning: loading, tip: 'Загрузка данных...', size: 'small' }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет данных' /> }}
        />
    );
};

export default TrainingServiceTable;
