import { ObjectId, ObjectsId } from 'proto/Common_pb';
import { TrainingQueryServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { QueryState, TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import React from 'react';
import { getClientHost } from 'shared/constants';

const useTraining = (queryId: number) => {
    const clientHost = getClientHost();

    const serviceClient = new TrainingQueryServiceClient(clientHost ?? '');

    const id = new ObjectId().setId(queryId);

    const [trainingQuery, setTrainingQuery] = React.useState<TrainingQuery.AsObject>();

    React.useEffect(() => {
        const getInfo = async () => {
            const ids = new ObjectsId().setIdsList([queryId]);

            const response = await serviceClient.getInfo(ids, null);

            setTrainingQuery(response.toObject().listList[0]);
        };

        if (queryId) getInfo();

        if (queryId && trainingQuery?.state === QueryState.RUNNING) {
            const interval = setInterval(() => {
                console.log('Updated data after 10 sec');
                getInfo();
            }, 10000);

            return () => clearInterval(interval);
        }
    }, [queryId, trainingQuery?.state]);

    const finish = async () => {
        const res = await serviceClient.finishQuery(id, null);

        return res.toObject().possible;
    };

    const cancel = async () => {
        const res = await serviceClient.cancelQuery(id, null);

        return res.toObject().possible;
    };

    return {
        finish,
        cancel,
        trainingQuery,
    };
};

export default useTraining;
