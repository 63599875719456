import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

import type { FormValues } from '../export-dataset-modal';

const { Panel } = Collapse;

interface ConvertProps {
    initialValues: FormValues;
    augmintationValues?: YoloAugmentationConfig.AsObject;
}

const Convert = (props: ConvertProps): JSX.Element => {
    const { initialValues, augmintationValues } = props;

    const [mosaicValue, setMosaicValue] = useState<number>(0);
    const [mixupValue, setMixupValue] = useState<number>(0);
    const [copyPasteValue, setCopyPasteValue] = useState<number>(0);
    const [checkedConvert, setCheckedConvert] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    // Конвертация значений аугментации по умолчанию в значения слайдера
    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    // Заносим значения по умолчанию, полученные с gRPC
    useEffect(() => {
        setMosaicValue(getValueToSlider(augmintationValues?.mosaic));
        setMixupValue(getValueToSlider(augmintationValues?.mixup));
        setCopyPasteValue(getValueToSlider(augmintationValues?.copyPaste));
    }, [augmintationValues]);

    // Если значения не указаны/указаны, выключаем/включаем Switch
    useEffect(() => {
        if (mosaicValue > 0 || mixupValue > 0 || copyPasteValue > 0) {
            setCheckedConvert(true);
        } else {
            setCheckedConvert(false);
        }

        // Добавляем в initialValues информацию по аугментации для создания Датасета
        if (initialValues.exportDatasetParams.augmentation) {
            // Если значения аугментации по умолчанию изменились, указываем значение из слайдера
            if (getValueToSlider(augmintationValues?.mosaic) !== mosaicValue) {
                initialValues.exportDatasetParams.augmentation.mosaic = mosaicValue / 100;
            }

            if (getValueToSlider(augmintationValues?.mixup) !== mixupValue) {
                initialValues.exportDatasetParams.augmentation.mixup = mixupValue / 100;
            }

            if (getValueToSlider(augmintationValues?.copyPaste) !== copyPasteValue) {
                initialValues.exportDatasetParams.augmentation.copyPaste = copyPasteValue / 100;
            }
        }
    }, [mosaicValue, mixupValue, copyPasteValue]);

    // Если выключаем Switch, значения сбрасываются до 0
    useEffect(() => {
        if (!checkedConvert) {
            setMosaicValue(0);
            setMixupValue(0);
            setCopyPasteValue(0);
        }
    }, [checkedConvert]);

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Преобразование</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedConvert}
                                onChange={(value) => setCheckedConvert(value)}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_5'
                >
                    <div>
                        Добавьте преобразование для изображений, чтобы ваша модель имела
                        более высокую устойчивость к изменению окружающей среды, в которой
                        находится объект.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Мозайка
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={mosaicValue}
                                        onChange={(value) => setMosaicValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={mosaicValue}
                                        onChange={(value) => (
                                            value ? setMosaicValue(value) : setMosaicValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Смешивание
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={mixupValue}
                                        onChange={(value) => setMixupValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={mixupValue}
                                        onChange={(value) => (
                                            value ? setMixupValue(value) : setMixupValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Случайная вставка
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={copyPasteValue}
                                        onChange={(value) => setCopyPasteValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={copyPasteValue}
                                        onChange={(value) => (
                                            value ? setCopyPasteValue(value) : setCopyPasteValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Convert;
