import React, { useState, useEffect } from 'react';
import {
    Modal, Form, Select, message,
} from 'antd';
import { SyncOutlined } from '@ant-design/icons';

import { getClientHost } from 'shared/constants';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { DClass, AttributeList } from 'proto/c60/nn/center/DatasetClasses_pb';
import { createEnhancedClient } from '../../utils/grpc';

export interface IDClass extends DClass.AsObject {
    path?: string[];
}

interface DataType {
    key: React.Key;
    tblName?: string;
    tblKind?: number;
    tblAttribute?: number;
    tblColor?: string;
    tblType?: number;
    tblDescription?: string;
    attributes?: AttributeList.AsObject;
    children?: DataType[];
}

interface NodeType {
    value?: string;
    label?: string;
    disabled?: boolean;
}

interface Props {
    typeModalOpen: boolean;
    setTypeModalOpen(boolean: boolean): void;
    currentClassNode?: DataType;
    setCurrentClassNode(object: undefined): void;
}

const TypeEditModal = (props: Props): JSX.Element => {
    const {
        typeModalOpen, setTypeModalOpen, currentClassNode, setCurrentClassNode,
    } = props;

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [selectedNode, setSelectedNode] = useState<NodeType>();
    const [nodeData, setNodeData] = useState<NodeType[]>();

    const currentHost = getClientHost();

    // Формирование пунктов выпадающего списка
    useEffect(() => {
        const formingOption: NodeType[] = [
            {
                value: 'rootNode',
                label: 'Корневой класс',
            },
            {
                value: 'sectionNode',
                label: 'Часть объекта',
            },
            {
                value: 'subspeciesNode',
                label: 'Подвид объекта',
            },
        ];

        if (currentClassNode?.tblType === 0) {
            formingOption[0].disabled = true;
            formingOption[1].disabled = true;
            formingOption[2].disabled = true;
        }

        if (currentClassNode?.tblType === 1) {
            formingOption[1].disabled = true;
        }

        if (currentClassNode?.tblType === 2) {
            formingOption[2].disabled = true;

            if (currentClassNode?.children) {
                formingOption[1].disabled = true;
            }
        }

        setNodeData(formingOption);
    }, [currentClassNode]);

    const handleOk = (): void => {
        setConfirmLoading(true);

        if (selectedNode) {
            (async () => {
                if (currentHost) {
                    const client = createEnhancedClient(DClassServiceClient);

                    if (selectedNode.value === 'rootNode') {
                        await client
                            .insert(
                                new DClass()
                                    .setId(Number(currentClassNode?.key))
                                    .setIspart(false)
                                    .setParentdclassid(0)
                                    .setKind(1),
                                {},
                            )
                            .then(() => {
                                message.success('Тип изменен на - Корневой класс!');
                                setConfirmLoading(false);
                                setTypeModalOpen(false);
                            })
                            .catch((error) => {
                                message.error('Не удалось изменить тип!');
                                console.error(error);
                            });
                    } else if (selectedNode.value === 'sectionNode') {
                        await client
                            .insert(
                                new DClass()
                                    .setId(Number(currentClassNode?.key))
                                    .setIspart(true),
                                {},
                            )
                            .then(() => {
                                message.success('Тип изменен на - Часть объекта!');
                                setConfirmLoading(false);
                                setTypeModalOpen(false);
                            })
                            .catch((error) => {
                                message.error('Не удалось изменить тип!');
                                console.error(error);
                            });
                    } else if (selectedNode.value === 'subspeciesNode') {
                        await client
                            .insert(
                                new DClass()
                                    .setId(Number(currentClassNode?.key))
                                    .setIspart(false),
                                {},
                            )
                            .then(() => {
                                message.success('Тип изменен на - Подвид объекта!');
                                setConfirmLoading(false);
                                setTypeModalOpen(false);
                            })
                            .catch((error) => {
                                message.error('Не удалось изменить тип!');
                                console.error(error);
                            });
                    }
                }
            })();
        }
    };

    // Названия типа классов
    const getClassTypeName = (value: number | undefined): string => {
        switch (value) {
            case 2:
                return 'Подвид объекта';
            case 1:
                return 'Часть объекта';
            default:
                return 'Корневой класс';
        }
    };

    return (
        <Modal
            title={(
                <>
                    <SyncOutlined style={{ marginRight: '10px', color: '#368585' }} />
                    {`Изменить тип класса - ${currentClassNode?.tblName}`}
                </>
            )}
            open={typeModalOpen}
            onOk={() => handleOk()}
            onCancel={() => setTypeModalOpen(false)}
            okButtonProps={{
                disabled: !selectedNode,
            }}
            afterClose={() => {
                setTypeModalOpen(false);
                setCurrentClassNode(undefined);
                setSelectedNode(undefined);
                setConfirmLoading(false);
            }}
            okText='Применить'
            cancelText='Отменить'
            width={650}
            confirmLoading={confirmLoading}
            destroyOnClose
        >
            <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Form layout='horizontal' wrapperCol={{ span: 20 }}>
                    <Form.Item label='Текущий тип'>
                        {getClassTypeName(currentClassNode?.tblType)}
                    </Form.Item>
                    <Form.Item label='Изменить тип на'>
                        <Select
                            options={nodeData}
                            placeholder='Выберите тип'
                            onSelect={(_, node) => setSelectedNode(node)}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default TypeEditModal;
