import React from 'react';

import config from 'config';

interface ClassiViewerItemProps {
    label: string;
    color?: string;
}

export default function ClassiViewerItem(props: ClassiViewerItemProps): JSX.Element {
    const { color, label } = props;

    const backgroundColor = color || config.NEW_LABEL_COLOR;
    let textColor = '#ffffff';
    try {
        const [r, g, b] = [backgroundColor.slice(1, 3), backgroundColor.slice(3, 5), backgroundColor.slice(5, 7)];
        const grayscale = (parseInt(r, 16) + parseInt(g, 16) + parseInt(b, 16)) / 3;
        if (grayscale - 128 >= 0) {
            textColor = '#000000';
        }
    } catch (_: any) {
        // nothing to do
    }

    return (
        <div style={{ background: backgroundColor }} className='class-viewer-item'>
            <div style={{ color: textColor, margin: '0 5px' }}>{label}</div>
        </div>
    );
}
