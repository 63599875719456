import './styles.css';
import React from 'react';

import { Typography } from 'antd';

const { Text } = Typography;

export default function DatasetsTopBar(): JSX.Element {
    return (
        <div className='cvat-task-top-bar'>
            <Text className='classreg-header-text'>Датасеты</Text>
        </div>
    );
}
