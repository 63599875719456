import {
    Form, Input, Modal, notification,
} from 'antd';
import React, { useState } from 'react';
import './styles.scss';
import { getClientHost } from 'shared/constants';
import { TrainingRunnerServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { TrainingRunner } from 'proto/c60/nn/center/Detectors_pb';
import { ImportOutlined } from '@ant-design/icons';

type CreateServiceModalProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrentTabActiveKey: React.Dispatch<React.SetStateAction<'TrainingService' | 'TrainingQuery' | undefined>>;
    refresh(): Promise<void>;
};

const CreateServiceModal = ({
    isModalOpen,
    setIsModalOpen,
    setCurrentTabActiveKey,
    refresh,
}: CreateServiceModalProps): JSX.Element => {
    const currentHost = getClientHost();

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [titleHelp, setTitleHelp] = useState<'Введите название!' | 'Введите уникальное название!' | ''>('');
    const [titleStatus, setTitleStatus] = useState<'error' | ''>('');

    const [addressHelp, setAddressHelp] = useState<'Введите название!' | 'Введите уникальное название!' | ''>('');
    const [addressStatus, setAddressStatus] = useState<'error' | ''>('');

    const handleValidate = async (): Promise<void> => {
        if (currentHost) {
            if (!form?.getFieldValue('title') || form?.getFieldValue('title')?.length === 0) {
                setTitleHelp('Введите название!');
                setTitleStatus('error');
            }

            if (!form?.getFieldValue('address') || form?.getFieldValue('address')?.length === 0) {
                setAddressHelp('Введите название!');
                setAddressStatus('error');
            }

            if (
                !(!form?.getFieldValue('title') || form?.getFieldValue('title')?.length === 0) &&
                !(!form?.getFieldValue('address') || form?.getFieldValue('address')?.length === 0)
            ) {
                const client = new TrainingRunnerServiceClient(currentHost);
                const newService = new TrainingRunner()
                    .setTitle(form.getFieldValue('title'))
                    .setAddress(form.getFieldValue('address'));

                const checkUniqTitleService = await client.checkUniqName(newService, {});
                const checkUniqAddressService = await client.checkUniqAddress(newService, {});

                if (!checkUniqTitleService.getSuccess()) {
                    setTitleHelp('Введите уникальное название!');
                    setTitleStatus('error');
                }

                if (!checkUniqAddressService.getSuccess()) {
                    setAddressHelp('Введите уникальное название!');
                    setAddressStatus('error');
                }

                if (checkUniqAddressService.getSuccess() && checkUniqTitleService.getSuccess()) {
                    try {
                        setConfirmLoading(true);

                        await client.insert(
                            new TrainingRunner()
                                .setTitle(form.getFieldValue('title'))
                                .setAddress(form.getFieldValue('address')),
                            {},
                        );
                    } catch (e: any) {
                        console.log(e);
                        notification.error({
                            message: 'Не удалось добавить сервис',
                            description: e.message,
                        });
                    } finally {
                        setConfirmLoading(false);
                        setIsModalOpen(false);
                        refresh();
                        setCurrentTabActiveKey('TrainingService');
                    }
                }
            }
        }
    };

    return (
        <Modal
            title={(
                <>
                    <ImportOutlined style={{ marginRight: '10px', color: '#368585' }} />
                    Добавить сервис
                </>
            )}
            destroyOnClose
            open={isModalOpen}
            onOk={handleValidate}
            okText='Добавить'
            cancelText='Отменить'
            onCancel={() => setIsModalOpen(false)}
            className='create-service-modal'
            confirmLoading={confirmLoading}
        >
            <Form form={form}>
                <Form.Item label='Название' name='title' required help={titleHelp} status={titleStatus}>
                    <Input
                        onChange={() => {
                            setTitleHelp('');
                            setTitleStatus('');
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label='Адрес для подключения'
                    name='address'
                    required
                    help={addressHelp}
                    status={addressStatus}
                >
                    <Input
                        onChange={() => {
                            setAddressHelp('');
                            setAddressStatus('');
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default React.memo(CreateServiceModal);
