import React, { useEffect, useState } from 'react';
import {
    Form,
    FormInstance,
    Input, InputProps, Tooltip, Typography,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import useDebounce from './useDebounce';

interface WarningInputProps extends InputProps {
    formname: NamePath;
    forminstance: FormInstance;
}

const WarningInput = ({ formname, forminstance, ...props }: WarningInputProps): JSX.Element => {
    const [inputValue, setInputValue] = useState('');
    const [inputStatus, setInputStatus] = useState<'' | 'warning' | 'error' | undefined>('');
    const inputDebounce = useDebounce(inputValue, 500);
    const [isOpen, setIsOpen] = useState(false);

    const handleRoundValue = (e: number): void => {
        if (e) {
            if (Number(e) >= 0 && Number(e) < 33) {
                setInputValue('32');
                forminstance.setFieldValue(formname, '32');
            } else if (Number(e) % 32 !== 0) {
                setInputValue(String(Math.round(Number(e) / 32) * 32));
                forminstance.setFieldValue(formname, String(Math.round(Number(e) / 32) * 32));
            }
        }
    };

    useEffect(() => {
        if (Number(inputValue) % 32 === 0) {
            setInputStatus('');
            setIsOpen(false);
        } else {
            setInputStatus('warning');
            setIsOpen(true);
        }
    }, [inputDebounce]);

    return (
        <Tooltip color='white' showArrow={false} overlayInnerStyle={{ border: '1px solid rgba(0,0,0,0.15)' }} title={<Typography.Text type='warning'>Значение должно быть кратно 32</Typography.Text>} open={isOpen}>
            <Form.Item shouldUpdate noStyle name={formname}>
                <Input
                    {...props}
                    status={inputStatus}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onBlur={() => handleRoundValue(Number(inputValue))}
                />
            </Form.Item>
        </Tooltip>
    );
};

export default WarningInput;
