// source: c60/nn/center/Detectors.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../../../Common_pb.js');
goog.object.extend(proto, Common_pb);
var nn_training_DetectorTraining_pb = require('../../../nn_training/DetectorTraining_pb.js');
goog.object.extend(proto, nn_training_DetectorTraining_pb);
goog.exportSymbol('proto.grpc.c60.nn.center.Detector', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DetectorsList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.Graphic', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.QueryState', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainProcessPoint', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingGraphics', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingQuery', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingQuery.BasisCase', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingQueryList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingQueryOption', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingRunner', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingRunnersList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.TrainingTelemetryOnRunner', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.Detector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.Detector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.Detector.displayName = 'proto.grpc.c60.nn.center.Detector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DetectorsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.DetectorsList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.DetectorsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DetectorsList.displayName = 'proto.grpc.c60.nn.center.DetectorsList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingRunner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingRunner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingRunner.displayName = 'proto.grpc.c60.nn.center.TrainingRunner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingRunnersList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.TrainingRunnersList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingRunnersList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingRunnersList.displayName = 'proto.grpc.c60.nn.center.TrainingRunnersList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpc.c60.nn.center.TrainingQuery.oneofGroups_);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingQuery.displayName = 'proto.grpc.c60.nn.center.TrainingQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingQueryList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.TrainingQueryList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingQueryList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingQueryList.displayName = 'proto.grpc.c60.nn.center.TrainingQueryList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingQueryOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingQueryOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingQueryOption.displayName = 'proto.grpc.c60.nn.center.TrainingQueryOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainProcessPoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainProcessPoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainProcessPoint.displayName = 'proto.grpc.c60.nn.center.TrainProcessPoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.Graphic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.Graphic.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.Graphic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.Graphic.displayName = 'proto.grpc.c60.nn.center.Graphic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingGraphics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingGraphics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingGraphics.displayName = 'proto.grpc.c60.nn.center.TrainingGraphics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.TrainingTelemetryOnRunner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.displayName = 'proto.grpc.c60.nn.center.TrainingTelemetryOnRunner';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.Detector.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.Detector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.Detector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Detector.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    parentdetectorid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    yolomodel: (f = msg.getYolomodel()) && Common_pb.File.toObject(includeInstance, f),
    createdtime: (f = msg.getCreatedtime()) && Common_pb.Time.toObject(includeInstance, f),
    trainquery: (f = msg.getTrainquery()) && proto.grpc.c60.nn.center.TrainingQuery.toObject(includeInstance, f),
    yoloptmodel: (f = msg.getYoloptmodel()) && Common_pb.File.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.Detector}
 */
proto.grpc.c60.nn.center.Detector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.Detector;
  return proto.grpc.c60.nn.center.Detector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.Detector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.Detector}
 */
proto.grpc.c60.nn.center.Detector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentdetectorid(value);
      break;
    case 4:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setYolomodel(value);
      break;
    case 5:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setCreatedtime(value);
      break;
    case 6:
      var value = new proto.grpc.c60.nn.center.TrainingQuery;
      reader.readMessage(value,proto.grpc.c60.nn.center.TrainingQuery.deserializeBinaryFromReader);
      msg.setTrainquery(value);
      break;
    case 7:
      var value = new Common_pb.File;
      reader.readMessage(value,Common_pb.File.deserializeBinaryFromReader);
      msg.setYoloptmodel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.Detector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.Detector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.Detector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Detector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getYolomodel();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
  f = message.getCreatedtime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
  f = message.getTrainquery();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpc.c60.nn.center.TrainingQuery.serializeBinaryToWriter
    );
  }
  f = message.getYoloptmodel();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.Detector.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.Detector.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 parentDetectorId = 3;
 * @return {number}
 */
proto.grpc.c60.nn.center.Detector.prototype.getParentdetectorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.setParentdetectorid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.clearParentdetectorid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Detector.prototype.hasParentdetectorid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.File yoloModel = 4;
 * @return {?proto.common.File}
 */
proto.grpc.c60.nn.center.Detector.prototype.getYolomodel = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 4));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
*/
proto.grpc.c60.nn.center.Detector.prototype.setYolomodel = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.clearYolomodel = function() {
  return this.setYolomodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Detector.prototype.hasYolomodel = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.Time createdTime = 5;
 * @return {?proto.common.Time}
 */
proto.grpc.c60.nn.center.Detector.prototype.getCreatedtime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 5));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
*/
proto.grpc.c60.nn.center.Detector.prototype.setCreatedtime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.clearCreatedtime = function() {
  return this.setCreatedtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Detector.prototype.hasCreatedtime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional TrainingQuery trainQuery = 6;
 * @return {?proto.grpc.c60.nn.center.TrainingQuery}
 */
proto.grpc.c60.nn.center.Detector.prototype.getTrainquery = function() {
  return /** @type{?proto.grpc.c60.nn.center.TrainingQuery} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.TrainingQuery, 6));
};


/**
 * @param {?proto.grpc.c60.nn.center.TrainingQuery|undefined} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
*/
proto.grpc.c60.nn.center.Detector.prototype.setTrainquery = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.clearTrainquery = function() {
  return this.setTrainquery(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Detector.prototype.hasTrainquery = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.File yoloPtModel = 7;
 * @return {?proto.common.File}
 */
proto.grpc.c60.nn.center.Detector.prototype.getYoloptmodel = function() {
  return /** @type{?proto.common.File} */ (
    jspb.Message.getWrapperField(this, Common_pb.File, 7));
};


/**
 * @param {?proto.common.File|undefined} value
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
*/
proto.grpc.c60.nn.center.Detector.prototype.setYoloptmodel = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Detector} returns this
 */
proto.grpc.c60.nn.center.Detector.prototype.clearYoloptmodel = function() {
  return this.setYoloptmodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Detector.prototype.hasYoloptmodel = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.DetectorsList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DetectorsList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DetectorsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DetectorsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DetectorsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.Detector.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DetectorsList}
 */
proto.grpc.c60.nn.center.DetectorsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DetectorsList;
  return proto.grpc.c60.nn.center.DetectorsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DetectorsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DetectorsList}
 */
proto.grpc.c60.nn.center.DetectorsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.Detector;
      reader.readMessage(value,proto.grpc.c60.nn.center.Detector.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DetectorsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DetectorsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DetectorsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DetectorsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.Detector.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Detector list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.Detector>}
 */
proto.grpc.c60.nn.center.DetectorsList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.Detector>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.Detector, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.Detector>} value
 * @return {!proto.grpc.c60.nn.center.DetectorsList} returns this
*/
proto.grpc.c60.nn.center.DetectorsList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.Detector=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.Detector}
 */
proto.grpc.c60.nn.center.DetectorsList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.Detector, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.DetectorsList} returns this
 */
proto.grpc.c60.nn.center.DetectorsList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingRunner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingRunner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingRunner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    trainingqueryid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    online: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner}
 */
proto.grpc.c60.nn.center.TrainingRunner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingRunner;
  return proto.grpc.c60.nn.center.TrainingRunner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingRunner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner}
 */
proto.grpc.c60.nn.center.TrainingRunner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrainingqueryid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnline(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingRunner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingRunner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingRunner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.setAddress = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.clearAddress = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 trainingQueryId = 4;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.getTrainingqueryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.setTrainingqueryid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.clearTrainingqueryid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.hasTrainingqueryid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool online = 5;
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.getOnline = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.setOnline = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.clearOnline = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingRunner.prototype.hasOnline = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.TrainingRunnersList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingRunnersList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingRunnersList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingRunnersList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.TrainingRunner.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingRunnersList}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingRunnersList;
  return proto.grpc.c60.nn.center.TrainingRunnersList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingRunnersList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingRunnersList}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.TrainingRunner;
      reader.readMessage(value,proto.grpc.c60.nn.center.TrainingRunner.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingRunnersList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingRunnersList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingRunnersList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.TrainingRunner.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrainingRunner list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.TrainingRunner>}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.TrainingRunner>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.TrainingRunner, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.TrainingRunner>} value
 * @return {!proto.grpc.c60.nn.center.TrainingRunnersList} returns this
*/
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.TrainingRunner=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.TrainingRunner}
 */
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.TrainingRunner, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.TrainingRunnersList} returns this
 */
proto.grpc.c60.nn.center.TrainingRunnersList.prototype.clearListList = function() {
  return this.setListList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpc.c60.nn.center.TrainingQuery.oneofGroups_ = [[7,8]];

/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.TrainingQuery.BasisCase = {
  BASIS_NOT_SET: 0,
  PARENTDETECTOR: 7,
  ARCHITECTURE: 8
};

/**
 * @return {proto.grpc.c60.nn.center.TrainingQuery.BasisCase}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getBasisCase = function() {
  return /** @type {proto.grpc.c60.nn.center.TrainingQuery.BasisCase} */(jspb.Message.computeOneofCase(this, proto.grpc.c60.nn.center.TrainingQuery.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currentstep: (f = msg.getCurrentstep()) && nn_training_DetectorTraining_pb.ProgressTelemetry.toObject(includeInstance, f),
    trainingrunnerid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    detectorname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cvatauthor: (f = msg.getCvatauthor()) && Common_pb.ObjectRef.toObject(includeInstance, f),
    datasetid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    trainsettings: (f = msg.getTrainsettings()) && nn_training_DetectorTraining_pb.TrainSettings.toObject(includeInstance, f),
    parentdetector: (f = msg.getParentdetector()) && Common_pb.ObjectId.toObject(includeInstance, f),
    architecture: (f = msg.getArchitecture()) && nn_training_DetectorTraining_pb.ArchitectureDescriptor.toObject(includeInstance, f),
    state: jspb.Message.getFieldWithDefault(msg, 10, 0),
    readydetectorid: (f = msg.getReadydetectorid()) && Common_pb.ObjectId.toObject(includeInstance, f),
    creationtime: (f = msg.getCreationtime()) && Common_pb.Time.toObject(includeInstance, f),
    beginruntime: (f = msg.getBeginruntime()) && Common_pb.Time.toObject(includeInstance, f),
    finishtime: (f = msg.getFinishtime()) && Common_pb.Time.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery}
 */
proto.grpc.c60.nn.center.TrainingQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingQuery;
  return proto.grpc.c60.nn.center.TrainingQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery}
 */
proto.grpc.c60.nn.center.TrainingQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new nn_training_DetectorTraining_pb.ProgressTelemetry;
      reader.readMessage(value,nn_training_DetectorTraining_pb.ProgressTelemetry.deserializeBinaryFromReader);
      msg.setCurrentstep(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTrainingrunnerid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetectorname(value);
      break;
    case 4:
      var value = new Common_pb.ObjectRef;
      reader.readMessage(value,Common_pb.ObjectRef.deserializeBinaryFromReader);
      msg.setCvatauthor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDatasetid(value);
      break;
    case 6:
      var value = new nn_training_DetectorTraining_pb.TrainSettings;
      reader.readMessage(value,nn_training_DetectorTraining_pb.TrainSettings.deserializeBinaryFromReader);
      msg.setTrainsettings(value);
      break;
    case 7:
      var value = new Common_pb.ObjectId;
      reader.readMessage(value,Common_pb.ObjectId.deserializeBinaryFromReader);
      msg.setParentdetector(value);
      break;
    case 8:
      var value = new nn_training_DetectorTraining_pb.ArchitectureDescriptor;
      reader.readMessage(value,nn_training_DetectorTraining_pb.ArchitectureDescriptor.deserializeBinaryFromReader);
      msg.setArchitecture(value);
      break;
    case 10:
      var value = /** @type {!proto.grpc.c60.nn.center.QueryState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 11:
      var value = new Common_pb.ObjectId;
      reader.readMessage(value,Common_pb.ObjectId.deserializeBinaryFromReader);
      msg.setReadydetectorid(value);
      break;
    case 12:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setCreationtime(value);
      break;
    case 13:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setBeginruntime(value);
      break;
    case 14:
      var value = new Common_pb.Time;
      reader.readMessage(value,Common_pb.Time.deserializeBinaryFromReader);
      msg.setFinishtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCurrentstep();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      nn_training_DetectorTraining_pb.ProgressTelemetry.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDetectorname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCvatauthor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.ObjectRef.serializeBinaryToWriter
    );
  }
  f = message.getDatasetid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTrainsettings();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      nn_training_DetectorTraining_pb.TrainSettings.serializeBinaryToWriter
    );
  }
  f = message.getParentdetector();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.ObjectId.serializeBinaryToWriter
    );
  }
  f = message.getArchitecture();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      nn_training_DetectorTraining_pb.ArchitectureDescriptor.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getReadydetectorid();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      Common_pb.ObjectId.serializeBinaryToWriter
    );
  }
  f = message.getCreationtime();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
  f = message.getBeginruntime();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
  f = message.getFinishtime();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      Common_pb.Time.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional grpc.c60.nn.training.ProgressTelemetry currentStep = 2;
 * @return {?proto.grpc.c60.nn.training.ProgressTelemetry}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getCurrentstep = function() {
  return /** @type{?proto.grpc.c60.nn.training.ProgressTelemetry} */ (
    jspb.Message.getWrapperField(this, nn_training_DetectorTraining_pb.ProgressTelemetry, 2));
};


/**
 * @param {?proto.grpc.c60.nn.training.ProgressTelemetry|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setCurrentstep = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearCurrentstep = function() {
  return this.setCurrentstep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasCurrentstep = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 trainingRunnerId = 9;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getTrainingrunnerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.setTrainingrunnerid = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearTrainingrunnerid = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasTrainingrunnerid = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string detectorName = 3;
 * @return {string}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getDetectorname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.setDetectorname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.ObjectRef cvatAuthor = 4;
 * @return {?proto.common.ObjectRef}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getCvatauthor = function() {
  return /** @type{?proto.common.ObjectRef} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectRef, 4));
};


/**
 * @param {?proto.common.ObjectRef|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setCvatauthor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearCvatauthor = function() {
  return this.setCvatauthor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasCvatauthor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 datasetId = 5;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getDatasetid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.setDatasetid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional grpc.c60.nn.training.TrainSettings trainSettings = 6;
 * @return {?proto.grpc.c60.nn.training.TrainSettings}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getTrainsettings = function() {
  return /** @type{?proto.grpc.c60.nn.training.TrainSettings} */ (
    jspb.Message.getWrapperField(this, nn_training_DetectorTraining_pb.TrainSettings, 6));
};


/**
 * @param {?proto.grpc.c60.nn.training.TrainSettings|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setTrainsettings = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearTrainsettings = function() {
  return this.setTrainsettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasTrainsettings = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ObjectId parentDetector = 7;
 * @return {?proto.common.ObjectId}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getParentdetector = function() {
  return /** @type{?proto.common.ObjectId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectId, 7));
};


/**
 * @param {?proto.common.ObjectId|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setParentdetector = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.grpc.c60.nn.center.TrainingQuery.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearParentdetector = function() {
  return this.setParentdetector(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasParentdetector = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional grpc.c60.nn.training.ArchitectureDescriptor architecture = 8;
 * @return {?proto.grpc.c60.nn.training.ArchitectureDescriptor}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getArchitecture = function() {
  return /** @type{?proto.grpc.c60.nn.training.ArchitectureDescriptor} */ (
    jspb.Message.getWrapperField(this, nn_training_DetectorTraining_pb.ArchitectureDescriptor, 8));
};


/**
 * @param {?proto.grpc.c60.nn.training.ArchitectureDescriptor|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setArchitecture = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.grpc.c60.nn.center.TrainingQuery.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearArchitecture = function() {
  return this.setArchitecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasArchitecture = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional QueryState state = 10;
 * @return {!proto.grpc.c60.nn.center.QueryState}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getState = function() {
  return /** @type {!proto.grpc.c60.nn.center.QueryState} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.grpc.c60.nn.center.QueryState} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional common.ObjectId readyDetectorId = 11;
 * @return {?proto.common.ObjectId}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getReadydetectorid = function() {
  return /** @type{?proto.common.ObjectId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectId, 11));
};


/**
 * @param {?proto.common.ObjectId|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setReadydetectorid = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearReadydetectorid = function() {
  return this.setReadydetectorid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasReadydetectorid = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional common.Time creationTime = 12;
 * @return {?proto.common.Time}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getCreationtime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 12));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setCreationtime = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearCreationtime = function() {
  return this.setCreationtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasCreationtime = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional common.Time beginRunTime = 13;
 * @return {?proto.common.Time}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getBeginruntime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 13));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setBeginruntime = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearBeginruntime = function() {
  return this.setBeginruntime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasBeginruntime = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional common.Time finishTime = 14;
 * @return {?proto.common.Time}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.getFinishtime = function() {
  return /** @type{?proto.common.Time} */ (
    jspb.Message.getWrapperField(this, Common_pb.Time, 14));
};


/**
 * @param {?proto.common.Time|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
*/
proto.grpc.c60.nn.center.TrainingQuery.prototype.setFinishtime = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQuery} returns this
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.clearFinishtime = function() {
  return this.setFinishtime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQuery.prototype.hasFinishtime = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.TrainingQueryList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingQueryList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingQueryList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingQueryList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQueryList.toObject = function(includeInstance, msg) {
  var f, obj = {
    listList: jspb.Message.toObjectList(msg.getListList(),
    proto.grpc.c60.nn.center.TrainingQuery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryList}
 */
proto.grpc.c60.nn.center.TrainingQueryList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingQueryList;
  return proto.grpc.c60.nn.center.TrainingQueryList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingQueryList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryList}
 */
proto.grpc.c60.nn.center.TrainingQueryList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.TrainingQuery;
      reader.readMessage(value,proto.grpc.c60.nn.center.TrainingQuery.deserializeBinaryFromReader);
      msg.addList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingQueryList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingQueryList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingQueryList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQueryList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.TrainingQuery.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrainingQuery list = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.TrainingQuery>}
 */
proto.grpc.c60.nn.center.TrainingQueryList.prototype.getListList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.TrainingQuery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.TrainingQuery, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.TrainingQuery>} value
 * @return {!proto.grpc.c60.nn.center.TrainingQueryList} returns this
*/
proto.grpc.c60.nn.center.TrainingQueryList.prototype.setListList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.TrainingQuery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.TrainingQuery}
 */
proto.grpc.c60.nn.center.TrainingQueryList.prototype.addList = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.TrainingQuery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryList} returns this
 */
proto.grpc.c60.nn.center.TrainingQueryList.prototype.clearListList = function() {
  return this.setListList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingQueryOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingQueryOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQueryOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    possible: (f = msg.getPossible()) && proto.grpc.c60.nn.center.TrainingQuery.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryOption}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingQueryOption;
  return proto.grpc.c60.nn.center.TrainingQueryOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingQueryOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryOption}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.TrainingQuery;
      reader.readMessage(value,proto.grpc.c60.nn.center.TrainingQuery.deserializeBinaryFromReader);
      msg.setPossible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingQueryOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingQueryOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingQueryOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPossible();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.c60.nn.center.TrainingQuery.serializeBinaryToWriter
    );
  }
};


/**
 * optional TrainingQuery possible = 1;
 * @return {?proto.grpc.c60.nn.center.TrainingQuery}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.getPossible = function() {
  return /** @type{?proto.grpc.c60.nn.center.TrainingQuery} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.TrainingQuery, 1));
};


/**
 * @param {?proto.grpc.c60.nn.center.TrainingQuery|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingQueryOption} returns this
*/
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.setPossible = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingQueryOption} returns this
 */
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.clearPossible = function() {
  return this.setPossible(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingQueryOption.prototype.hasPossible = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainProcessPoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainProcessPoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainProcessPoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    step: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timems: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainProcessPoint;
  return proto.grpc.c60.nn.center.TrainProcessPoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainProcessPoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimems(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainProcessPoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainProcessPoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainProcessPoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTimems();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getValue();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional int32 step = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint} returns this
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 timeMS = 2;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.getTimems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint} returns this
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.setTimems = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional float value = 3;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint} returns this
 */
proto.grpc.c60.nn.center.TrainProcessPoint.prototype.setValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.Graphic.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.Graphic.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.Graphic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.Graphic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Graphic.toObject = function(includeInstance, msg) {
  var f, obj = {
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.grpc.c60.nn.center.TrainProcessPoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.Graphic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.Graphic;
  return proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.Graphic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.TrainProcessPoint;
      reader.readMessage(value,proto.grpc.c60.nn.center.TrainProcessPoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.Graphic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.Graphic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.TrainProcessPoint.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrainProcessPoint points = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.TrainProcessPoint>}
 */
proto.grpc.c60.nn.center.Graphic.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.TrainProcessPoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.TrainProcessPoint, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.TrainProcessPoint>} value
 * @return {!proto.grpc.c60.nn.center.Graphic} returns this
*/
proto.grpc.c60.nn.center.Graphic.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.TrainProcessPoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.TrainProcessPoint}
 */
proto.grpc.c60.nn.center.Graphic.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.TrainProcessPoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.Graphic} returns this
 */
proto.grpc.c60.nn.center.Graphic.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingGraphics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingGraphics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingGraphics.toObject = function(includeInstance, msg) {
  var f, obj = {
    boxloss: (f = msg.getBoxloss()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    clsloss: (f = msg.getClsloss()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    dflloss: (f = msg.getDflloss()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    learningrate: (f = msg.getLearningrate()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    precision: (f = msg.getPrecision()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    recall: (f = msg.getRecall()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    map50: (f = msg.getMap50()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f),
    map5095: (f = msg.getMap5095()) && proto.grpc.c60.nn.center.Graphic.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics}
 */
proto.grpc.c60.nn.center.TrainingGraphics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingGraphics;
  return proto.grpc.c60.nn.center.TrainingGraphics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingGraphics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics}
 */
proto.grpc.c60.nn.center.TrainingGraphics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setBoxloss(value);
      break;
    case 2:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setClsloss(value);
      break;
    case 3:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setDflloss(value);
      break;
    case 4:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setLearningrate(value);
      break;
    case 5:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setPrecision(value);
      break;
    case 6:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setRecall(value);
      break;
    case 7:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setMap50(value);
      break;
    case 8:
      var value = new proto.grpc.c60.nn.center.Graphic;
      reader.readMessage(value,proto.grpc.c60.nn.center.Graphic.deserializeBinaryFromReader);
      msg.setMap5095(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingGraphics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingGraphics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingGraphics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBoxloss();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getClsloss();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getDflloss();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getLearningrate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getPrecision();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getRecall();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getMap50();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
  f = message.getMap5095();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.grpc.c60.nn.center.Graphic.serializeBinaryToWriter
    );
  }
};


/**
 * optional Graphic boxLoss = 1;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getBoxloss = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 1));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setBoxloss = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearBoxloss = function() {
  return this.setBoxloss(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasBoxloss = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Graphic clsLoss = 2;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getClsloss = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 2));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setClsloss = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearClsloss = function() {
  return this.setClsloss(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasClsloss = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Graphic dflLoss = 3;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getDflloss = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 3));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setDflloss = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearDflloss = function() {
  return this.setDflloss(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasDflloss = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Graphic learningRate = 4;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getLearningrate = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 4));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setLearningrate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearLearningrate = function() {
  return this.setLearningrate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasLearningrate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Graphic precision = 5;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getPrecision = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 5));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setPrecision = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearPrecision = function() {
  return this.setPrecision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasPrecision = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Graphic recall = 6;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getRecall = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 6));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setRecall = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearRecall = function() {
  return this.setRecall(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasRecall = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Graphic mAP50 = 7;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getMap50 = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 7));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setMap50 = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearMap50 = function() {
  return this.setMap50(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasMap50 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Graphic mAP50_95 = 8;
 * @return {?proto.grpc.c60.nn.center.Graphic}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.getMap5095 = function() {
  return /** @type{?proto.grpc.c60.nn.center.Graphic} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.Graphic, 8));
};


/**
 * @param {?proto.grpc.c60.nn.center.Graphic|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
*/
proto.grpc.c60.nn.center.TrainingGraphics.prototype.setMap5095 = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingGraphics} returns this
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.clearMap5095 = function() {
  return this.setMap5095(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingGraphics.prototype.hasMap5095 = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.toObject = function(includeInstance, msg) {
  var f, obj = {
    runnerid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    telemetry: (f = msg.getTelemetry()) && nn_training_DetectorTraining_pb.ProgressTelemetry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.TrainingTelemetryOnRunner;
  return proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRunnerid(value);
      break;
    case 2:
      var value = new nn_training_DetectorTraining_pb.ProgressTelemetry;
      reader.readMessage(value,nn_training_DetectorTraining_pb.ProgressTelemetry.deserializeBinaryFromReader);
      msg.setTelemetry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRunnerid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTelemetry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      nn_training_DetectorTraining_pb.ProgressTelemetry.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 runnerId = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.getRunnerid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.setRunnerid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional grpc.c60.nn.training.ProgressTelemetry telemetry = 2;
 * @return {?proto.grpc.c60.nn.training.ProgressTelemetry}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.getTelemetry = function() {
  return /** @type{?proto.grpc.c60.nn.training.ProgressTelemetry} */ (
    jspb.Message.getWrapperField(this, nn_training_DetectorTraining_pb.ProgressTelemetry, 2));
};


/**
 * @param {?proto.grpc.c60.nn.training.ProgressTelemetry|undefined} value
 * @return {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} returns this
*/
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.setTelemetry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.TrainingTelemetryOnRunner} returns this
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.clearTelemetry = function() {
  return this.setTelemetry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.TrainingTelemetryOnRunner.prototype.hasTelemetry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.QueryState = {
  NONESTATE: 0,
  PENDING: 1,
  RUNNING: 2,
  DONE: 3,
  CANCELED: 4
};

goog.object.extend(exports, proto.grpc.c60.nn.center);
