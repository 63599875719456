import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

const { Panel } = Collapse;

interface FlipProps {
    augmentationValues: YoloAugmentationConfig.AsObject | undefined;
    setAugmentationValues: React.Dispatch<React.SetStateAction<YoloAugmentationConfig.AsObject | undefined>>;
    defaultAugmentation: YoloAugmentationConfig.AsObject | undefined;
}

const Flip: React.FC<FlipProps> = ({ augmentationValues, setAugmentationValues, defaultAugmentation }): JSX.Element => {
    const [checkedFlip, setCheckedFlip] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    // Конвертация значений аугментации по умолчанию в значения слайдера
    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    // Заносим значения по умолчанию, полученные с gRPC
    useEffect(() => {
        if (augmentationValues && (augmentationValues.fliplr > 0 || augmentationValues.flipud > 0)) {
            setCheckedFlip(true);
        } else {
            setCheckedFlip(false);
        }
    }, [augmentationValues]);

    const setValue = (valueType: 'fliplr' | 'flipud', value: number) => {
        let newValue = value;
        if (value && value > 100) newValue = 100;
        if (value && value < 0) newValue = 0;
        setAugmentationValues(prev => prev ? { ...prev, [valueType]: newValue /100 } : undefined);
    };

    const switchFlip = (value: boolean) => {
        if (!value) {
            setAugmentationValues(prev => prev ? { ...prev, fliplr: 0, flipud: 0 } : undefined);
        } else {
            if (defaultAugmentation) {
                const fliplr = defaultAugmentation.fliplr;
                const flipud = defaultAugmentation.flipud;
                setAugmentationValues(prev => prev ? { ...prev, fliplr, flipud } : undefined);
            }
        }
        setCheckedFlip(value);
    };

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Отражение</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedFlip}
                                onChange={switchFlip}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_4'
                >
                    <div>
                        Добавьте горизонтальное или вертикальное отражение, чтобы ваша
                        модель была нечувствительна к ориентации объекта.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        По горизонтали
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.fliplr || 0)}
                                        onChange={(value: number) => setValue('fliplr', value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.fliplr || 0)}
                                        onBlur={event => setValue('fliplr', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        По вертикали
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={getValueToSlider(augmentationValues?.flipud || 0)}
                                        onChange={(value: number) => setValue('flipud', value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={getValueToSlider(augmentationValues?.flipud || 0)}
                                        onBlur={event => setValue('flipud', Number(event.target.value))}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Flip;
