import { ObjectsId } from 'proto/Common_pb';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import React from 'react';
import { getClientHost } from 'shared/constants';

const useDatasetInfo = (id?: number) => {
    const currentHost = getClientHost();

    const datasetClient = new DatasetsServiceClient(currentHost ?? '');

    const [dataset, setDataset] = React.useState<Dataset.AsObject>();

    React.useEffect(() => {
        const getInfo = async (datasetId?: number) => {
            const ids = new ObjectsId().setIdsList([datasetId ?? 0]);

            const response = await datasetClient.getInfo(ids, null);

            setDataset(response.toObject().listList[0]);
        };

        if (id) getInfo(id);
    }, [id]);

    return { dataset };
};

export default useDatasetInfo;
