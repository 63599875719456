import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

import type { FormValues } from '../export-dataset-modal';

const { Panel } = Collapse;

interface TransformationProps {
    initialValues: FormValues;
    augmintationValues?: YoloAugmentationConfig.AsObject;
}

const Transformation = (props: TransformationProps): JSX.Element => {
    const { initialValues, augmintationValues } = props;

    const [translateValue, settranslateValue] = useState<number>(0);
    const [scaleValue, setScaleValue] = useState<number>(0);
    const [shearValue, setShearValue] = useState<number>(0);
    const [perspectiveValue, setPerspectiveValue] = useState<number>(0);
    const [checkedTransformation, setCheckedTransformation] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 100) {
            return 100;
        }

        return Number(result);
    };

    // Конвертация значений аугментации по умолчанию в значения слайдера
    const getValueToSlider = (value?: number): number => (
        Math.floor((Number(value) * 100) * 100) / 100
    );

    // Заносим значения по умолчанию, полученные с gRPC
    useEffect(() => {
        settranslateValue(getValueToSlider(augmintationValues?.translate));
        setScaleValue(getValueToSlider(augmintationValues?.scale));
        setShearValue(getValueToSlider(augmintationValues?.shear));
        setPerspectiveValue(getValueToSlider(augmintationValues?.perspective) * 1000);
    }, [augmintationValues]);

    // Если значения не указаны/указаны, выключаем/включаем Switch
    useEffect(() => {
        if (translateValue > 0 || scaleValue > 0 || shearValue > 0 || perspectiveValue > 0) {
            setCheckedTransformation(true);
        } else {
            setCheckedTransformation(false);
        }

        // Добавляем в initialValues информацию по аугментации для создания Датасета
        if (initialValues.exportDatasetParams.augmentation) {
            // Если значения аугментации по умолчанию изменились, указываем значение из слайдера
            if (getValueToSlider(augmintationValues?.translate) !== translateValue) {
                initialValues.exportDatasetParams.augmentation.translate = translateValue / 100;
            }

            if (getValueToSlider(augmintationValues?.scale) !== scaleValue) {
                initialValues.exportDatasetParams.augmentation.scale = scaleValue / 100;
            }

            if (getValueToSlider(augmintationValues?.shear) !== shearValue) {
                initialValues.exportDatasetParams.augmentation.shear = shearValue / 100;
            }

            if (getValueToSlider(augmintationValues?.perspective) !== perspectiveValue) {
                initialValues.exportDatasetParams.augmentation.perspective = perspectiveValue / 100000;
            }
        }
    }, [translateValue, scaleValue, shearValue, perspectiveValue]);

    // Если выключаем Switch, значения сбрасываются до 0
    useEffect(() => {
        if (!checkedTransformation) {
            settranslateValue(0);
            setScaleValue(0);
            setShearValue(0);
            setPerspectiveValue(0);
        }
    }, [checkedTransformation]);

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Трансформирование</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedTransformation}
                                onChange={(value) => setCheckedTransformation(value)}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_3'
                >
                    <div>
                        Добавьте вариативности с помощью трансформирования изображений,
                        чтобы ваша модель была более устойчивой к углу наклона камеры и
                        масштабу объекта съемки.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Сдвиг
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={translateValue}
                                        onChange={(value) => settranslateValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={translateValue}
                                        onChange={(value) => (
                                            value ? settranslateValue(value) : settranslateValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Масштаб
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={scaleValue}
                                        onChange={(value) => setScaleValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={scaleValue}
                                        onChange={(value) => (
                                            value ? setScaleValue(value) : setScaleValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Искажение
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={shearValue}
                                        onChange={(value) => setShearValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={shearValue}
                                        onChange={(value) => (
                                            value ? setShearValue(value) : setShearValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Перспектива
                                    </div>
                                </Col>
                                <Col span={14}>
                                    <Slider
                                        marks={{ 0: '0%', 100: '100%' }}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        value={perspectiveValue}
                                        onChange={(value) => setPerspectiveValue(value)}
                                        tooltip={{
                                            formatter: (value?: number): string => `${value}%`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        value={perspectiveValue}
                                        onChange={(value) => (
                                            value ? setPerspectiveValue(value) : setPerspectiveValue(0)
                                        )}
                                        min={0}
                                        max={100}
                                        step={0.01}
                                        controls={false}
                                        addonAfter='%'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Transformation;
