import React, { useState, useEffect } from 'react';
import {
    Modal, Form, TreeSelect, Switch, message,
} from 'antd';
import { CheckOutlined, PullRequestOutlined } from '@ant-design/icons';

import { getClientHost } from 'shared/constants';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { StringSearch, ObjectsId } from 'proto/Common_pb';
import { DClass, AttributeList } from 'proto/c60/nn/center/DatasetClasses_pb';
import { createEnhancedClient } from '../../utils/grpc';

export interface IDClass extends DClass.AsObject {
    path?: string[];
}

interface DataType {
    key: React.Key;
    tblName?: string;
    tblKind?: number;
    tblAttribute?: number;
    tblColor?: string;
    tblType?: number;
    tblDescription?: string;
    attributes?: AttributeList.AsObject;
    children?: DataType[];
}

interface TreeType {
    key: React.Key;
    value?: string;
    title?: string;
    type?: number;
    children?: DataType[];
}

interface Props {
    nodeModalOpen: boolean;
    setNodeModalOpen(boolean: boolean): void;
    currentClassNode?: DataType;
    setCurrentClassNode(object: undefined): void;
}

const MoveToNodeModal = (props: Props): JSX.Element => {
    const {
        nodeModalOpen, setNodeModalOpen, currentClassNode, setCurrentClassNode,
    } = props;

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [isRootClass, setIsRootClass] = useState<boolean>(false);
    const [selectedNode, setSelectedNode] = useState<TreeType>();

    const [datasetsIds, setDatasetsIds] = useState<number[]>();
    const [classesTree, setClassesTree] = useState<TreeType[]>();

    const currentHost = getClientHost();

    // 2 - Формируем дочерние объекты данных для древовидного списка, без классов с типом Часть
    const getTreeChild = (parents: TreeType[], classesList: IDClass[]): TreeType[] => {
        let haveParent = false;

        parents.map((element: TreeType) => {
            classesList.forEach((section) => {
                if (!section.ispart) {
                    if (currentClassNode?.key !== section.id) {
                        if (element.key === section.parentdclassid) {
                            const child: TreeType = {
                                key: Number(section.id),
                                title: section.title,
                                value: section.title,
                                type: 2,
                            };

                            element.children = element.children || [];
                            haveParent = true;

                            element.children.push(child);
                        }
                    }
                }
            });

            if (haveParent && element.children) {
                return getTreeChild(element.children, classesList);
            }

            return element;
        });

        return parents;
    };

    // 1 - Формируем объект данных для древовидного списка по существующим классам
    const getClassesBase = (classesList: IDClass[]): void => {
        const treeForming: TreeType[] = classesList.reduce((acc: TreeType[], current) => {
            if (current.parentdclassid === 0) {
                const generate: TreeType = {
                    key: Number(current.id),
                    title: current.title,
                    value: current.title,
                    type: 0,
                };

                if (currentClassNode?.key !== current.id) {
                    acc.push(generate);
                }
            }

            return acc;
        }, []);

        const treeData: TreeType[] = getTreeChild(JSON.parse(JSON.stringify(treeForming)), classesList);

        setClassesTree(treeData);
    };

    //! Получаем список ID существующих классов
    const getDatasetsIds: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getAll(new StringSearch().setText(''), {});
            setDatasetsIds(datasets.getIdsList().sort((a, b) => a - b));
        }
    };

    //! Получаем информацию по существующим классам
    const getDatasetsInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getInfo(new ObjectsId().setIdsList(datasetsIds || []), {});
            const classesList = datasets.toObject();
            getClassesBase(classesList.classesList.sort((value) => (value.ispart ? -1 : 1)));
        }
    };

    // Запуск функции получения списка ID - GRPC
    useEffect(() => {
        getDatasetsIds();
    }, [nodeModalOpen]);

    // Запуск функции получения объекта данных существующих классов - GRPC
    useEffect(() => {
        getDatasetsInfo();
    }, [datasetsIds]);

    const handleOk = (): void => {
        setConfirmLoading(true);

        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);

                if (isRootClass) {
                    await client
                        .insert(
                            new DClass()
                                .setId(Number(currentClassNode?.key))
                                .setIspart(false)
                                .setParentdclassid(0)
                                .setKind(1),
                            {},
                        )
                        .then(() => {
                            message.success('Класс перемещен в другой узел!');
                            getDatasetsIds();
                            setConfirmLoading(false);
                            setNodeModalOpen(false);
                        })
                        .catch((error) => {
                            message.error('Не удалось переместить класс в другой узел!');
                            console.error(error);
                        });
                } else {
                    await client
                        .insert(
                            new DClass()
                                .setId(Number(currentClassNode?.key))
                                .setParentdclassid(Number(selectedNode?.key))
                                .setKind(1),
                            {},
                        )
                        .then(() => {
                            message.success('Класс перемещен в другой узел!');
                            getDatasetsIds();
                            setConfirmLoading(false);
                            setNodeModalOpen(false);
                        })
                        .catch((error) => {
                            message.error('Не удалось переместить класс в другой узел!');
                            console.error(error);
                        });
                }
            }
        })();
    };

    return (
        <Modal
            title={(
                <>
                    <PullRequestOutlined style={{ marginRight: '10px', color: '#368585' }} />
                    Перенести в другой узел
                </>
            )}
            open={nodeModalOpen}
            onOk={() => handleOk()}
            onCancel={() => setNodeModalOpen(false)}
            okButtonProps={{
                disabled: !isRootClass && !selectedNode,
            }}
            afterClose={() => {
                setNodeModalOpen(false);
                setCurrentClassNode(undefined);
                setSelectedNode(undefined);
                setIsRootClass(false);
                setConfirmLoading(false);
            }}
            okText='Применить'
            cancelText='Отменить'
            width={650}
            confirmLoading={confirmLoading}
            destroyOnClose
        >
            <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                <Form layout='horizontal' wrapperCol={{ span: 20 }}>
                    <Form.Item label='Класс'>
                        <b>{currentClassNode?.tblName}</b>
                    </Form.Item>
                    <Form.Item label='Сделать Корневым классом' valuePropName='checked'>
                        <Switch
                            defaultChecked={isRootClass}
                            onChange={(value) => setIsRootClass(value)}
                            checkedChildren={<CheckOutlined />}
                            disabled={currentClassNode?.tblType === 0}
                        />
                    </Form.Item>
                    <Form.Item label='Перенести в'>
                        <TreeSelect
                            treeLine
                            treeDefaultExpandAll
                            treeData={classesTree}
                            disabled={isRootClass}
                            placeholder={isRootClass ? 'Корневой узел' : 'Выберите узел'}
                            onSelect={(_, node) => setSelectedNode(node)}
                        />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default MoveToNodeModal;
