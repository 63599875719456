// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useCallback } from 'react';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MenuInfo } from 'rc-menu/lib/interface';
import { DimensionType } from 'cvat-core-wrapper';

interface Props {
    taskID: number;
    projectID: number | null;
    taskMode: string;
    bugTracker: string;
    loaders: any[];
    dumpers: any[];
    inferenceIsActive: boolean;
    taskDimension: DimensionType;
    backupIsActive: boolean;
    onClickMenu: (params: MenuInfo) => void;
}

export enum Actions {
    LOAD_TASK_ANNO = 'load_task_anno',
    EXPORT_TASK_DATASET = 'export_task_dataset',
    DELETE_TASK = 'delete_task',
    RUN_AUTO_ANNOTATION = 'run_auto_annotation',
    MOVE_TASK_TO_PROJECT = 'move_task_to_project',
    OPEN_BUG_TRACKER = 'open_bug_tracker',
    BACKUP_TASK = 'backup_task',
}

function ActionsMenuComponent(props: Props): JSX.Element {
    const {
        taskID,
        projectID,
        // bugTracker,
        // inferenceIsActive,
        // backupIsActive,
        onClickMenu,
    } = props;

    const onClickMenuWrapper = useCallback(
        (params: MenuInfo) => {
            if (!params) {
                return;
            }

            // Глушилка кнопки
            if (params.key === Actions.EXPORT_TASK_DATASET) {
                console.log('Создать датасет из задачи');
                return;
            }

            if (params.key === Actions.DELETE_TASK) {
                Modal.confirm({
                    title: `Задача ${taskID} будет удалена`,
                    content: 'Все данные (изображения, аннотации) будут потеряны. Продолжить?',
                    className: 'cvat-modal-confirm-delete-task',
                    onOk: () => {
                        onClickMenu(params);
                    },
                    okButtonProps: {
                        type: 'primary',
                        danger: true,
                    },
                    cancelText: 'Отменить',
                    okText: 'Удалить',
                });
            } else {
                onClickMenu(params);
            }
        },
        [taskID],
    );

    return (
        <Menu selectable={false} className='cvat-actions-menu' onClick={onClickMenuWrapper}>
            {/* <Menu.Item key={Actions.LOAD_TASK_ANNO}>Загрузить аннотацию</Menu.Item> */}
            {/* //? <Menu.Item key={Actions.EXPORT_TASK_DATASET}>Создать датасет из задачи</Menu.Item> */}
            {/* {!!bugTracker && <Menu.Item key={Actions.OPEN_BUG_TRACKER}>Открыть отслеживание ошибок</Menu.Item>}
            <Menu.Item disabled={inferenceIsActive} key={Actions.RUN_AUTO_ANNOTATION}>
                Автоматическая аннотация
            </Menu.Item>
            <Menu.Item
                key={Actions.BACKUP_TASK}
                disabled={backupIsActive}
                icon={backupIsActive && <LoadingOutlined id='cvat-backup-task-loading' />}
            >
                Резервная копия
            </Menu.Item> */}
            <Menu.Divider />
            { projectID === null && (
                <Menu.Item key={Actions.MOVE_TASK_TO_PROJECT}>Привязать к проекту</Menu.Item>
            )}
            <Menu.Item key={Actions.DELETE_TASK}>Удалить</Menu.Item>
        </Menu>
    );
}

export default React.memo(ActionsMenuComponent);
