import React, { useEffect, useState } from 'react';

import { Image, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { MarkedImage, Dataset } from 'proto/c60/nn/center/DataSets_pb';

import { ClassesListForFilter } from 'components/dataset-page/dataset-page';

interface DatasetSubsetImageItemProps {
    imageData: MarkedImage.AsObject;
    datasetData?: Dataset.AsObject;
    datasetClassesList: ClassesListForFilter[];
}

const DatasetSubsetImageItem = (props: DatasetSubsetImageItemProps): JSX.Element => {
    const {
        imageData,
        datasetData,
        datasetClassesList,
    } = props;

    // Ссылка на превью изображения из датасета
    const [miniImageURL, setMiniImageURL] = useState<string>('');
    // Ссылка на изображениt из датасета
    const [imageURL, setImageURL] = useState<string>('');
    // Изображение Base64 с нанесенной разметкой в виде прямоугольников
    const [imageWithAnnotation, setImageWithAnnotation] = useState<string>('');
    // Статус загрузки изображений
    const [loadImages, setLoadImages] = useState<boolean>(true);
    const [isPreviewVisible, setIsPreviewVisible] = useState(false);
    const [isPreviewOpenFirstTime, setIsPreviewOpenFirstTime] = useState(true);

    // Формирование ссылки на изображение из архива бэка
    useEffect(() => {
        const datasetLink = datasetData?.ziparchive?.url;
        const LinkWithoutDomen = datasetLink?.replace(/^.*(?=\/center\/download.*)/, '');

        const miniImgUrl = `${LinkWithoutDomen}/mini_inner?entity=${imageData.imageurl}`;

        setMiniImageURL(miniImgUrl);
        setImageURL(`${LinkWithoutDomen}/inner?entity=${imageData.imageurl}`);

        const miniImage = new window.Image();
        // miniImage.crossOrigin = 'anonymous';
        miniImage.src = miniImgUrl;
        miniImage.onload = function() {
            setLoadImages(false);
        }
    }, [imageData]);

    // Нанесение разметки на изображение
    useEffect(() => {
        if (isPreviewOpenFirstTime && isPreviewVisible) {
            const baseImage = new window.Image();
            baseImage.crossOrigin = 'anonymous';
            baseImage.src = imageURL;

            const canvas = document.createElement('canvas');

            baseImage.onload = function () {
                canvas.width = baseImage.width;
                canvas.height = baseImage.height;

                const imageWidth = baseImage.width;
                const imageHeight = baseImage.height;

                const context = canvas.getContext('2d');

                if (context !== null) {
                    context.drawImage(baseImage, 0, 0);

                    imageData.marksList.forEach((marks) => {
                        if (marks.percentrect) {
                            const yoloX = marks.percentrect.xmin;
                            const yoloY = marks.percentrect.ymin;
                            const yoloWidth = marks.percentrect?.xmax;
                            const yoloHeight = marks.percentrect?.ymax;

                            const width = yoloWidth * imageWidth;
                            const height = yoloHeight * imageHeight;

                            const xLeft = (yoloX - yoloWidth / 2) * imageWidth;
                            const yTop = (yoloY - yoloHeight / 2) * imageHeight;

                            context.beginPath();
                            context.rect(
                                Math.round(xLeft),
                                Math.round(yTop),
                                Math.round(width),
                                Math.round(height),
                            );

                            for (const classEl of datasetClassesList) {
                                if (classEl.value === marks.classid) {
                                    context.fillStyle = `${classEl.color}28`;
                                    context.fill();
                                    context.lineWidth = 2;
                                    context.strokeStyle = `${classEl.color}`;
                                    context.stroke();
                                }
                            }
                        }
                    });
                }

                const dataURL = canvas.toDataURL();

                setImageWithAnnotation(dataURL);
            };
            setIsPreviewOpenFirstTime(false);
        }
    }, [isPreviewVisible]);

    return (
        <Spin spinning={loadImages} tip='Загрузка...'>
            <div className='img-dataset-item' onClick={() => {
                setIsPreviewVisible(true);
            }}>
                <Image
                    width={110}
                    height={110}
                    rootClassName='subset-img-dataset-container'
                    src={miniImageURL}
                    preview={{
                        rootClassName: 'subset-img-dataset-item',
                        src: imageWithAnnotation,
                        mask: <EyeOutlined />,
                        // onVisibleChange: visible => {
                        //     setIsPreviewVisible(visible);
                        // }
                    }}
                />
            </div>
        </Spin>
    );
};

export default DatasetSubsetImageItem;
