// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { exportActions } from 'actions/export-actions';
import { LeftOutlined, MoreOutlined, PictureOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import { Project } from 'reducers';
import ActionsMenu from 'components/projects-page/actions-menu';

interface DetailsComponentProps {
    projectInstance: Project;
}

export default function ProjectTopBar(props: DetailsComponentProps): JSX.Element {
    const { projectInstance } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <div className='cvat-task-top-bar'>
            <div>
                <Button
                    className='cvat-page-actions-button'
                    onClick={() => history.push('/projects')}
                    type='default'
                    size='middle'
                >
                    <LeftOutlined />
                    Назад к проектам
                </Button>
            </div>
            <div className='cvat-project-top-bar-actions-buttons'>
                <Dropdown overlay={<ActionsMenu projectInstance={projectInstance} />}>
                    <Button size='middle' className='cvat-page-actions-button wo-mrgn-icon'>
                        <MoreOutlined />
                        <Text className='cvat-text-color'>Действия</Text>
                    </Button>
                </Dropdown>
                <Button
                    size='middle'
                    type='primary'
                    className='cvat-project-top-bar-create-dataset-button'
                    onClick={() => {
                        dispatch(exportActions.openExportDatasetModal(projectInstance));
                    }}
                >
                    <PictureOutlined />
                    <Text className='cvat-text-color'>Создать датасет</Text>
                </Button>
            </div>
        </div>
    );
}
