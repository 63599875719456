import { convertMs } from 'components/model-details/api/helpers';
import React from 'react';
import { LineChart, CartesianGrid, YAxis, XAxis, Tooltip, Legend, Line, ResponsiveContainer } from 'recharts';

type OwnProps = {
    data?: any[];
    xAxisName?: string;
    yAxisName?: string;
    title?: string;
};

const XAXIS_DATAPOINTS = 6;
const DEFAULT_XAXIS_NAME = 'timems';
const DEFAULT_YAXISNAME = 'value';
const TICK_COUNT = 10;

// меньше пяти минут - отображаем в секундах
// - меньше пяти часов - отображаем в минутах
// - меньше пяти дней - отображаем в часах
// - больше пяти дней - отображаем в днях (сутках)
function getTimeFormatterSuffix(timems: number) {
    const fiveMinMs = 5 * 60 * 1000;
    const fiveHrsMs = 5 * 3600 * 1000;
    const fiveDaysMs = 5 * 24 * 3600 * 1000;

    if (+timems < fiveMinMs) return ' с';

    if (+timems < fiveHrsMs) return ' мин';

    if (+timems < fiveDaysMs) return ' ч';

    if (+timems > fiveDaysMs) return ' дн';
}

const Chart: React.FC<OwnProps> = ({
    data,
    xAxisName = DEFAULT_XAXIS_NAME,
    yAxisName = DEFAULT_YAXISNAME,
    title = '',
}) => {
    let xAxisInterval = Math.round((data?.length ?? 0) / XAXIS_DATAPOINTS);

    const timeSuffix = getTimeFormatterSuffix(data?.at(-1).timems);

    const timeTickFormatter = (val: number, suffix?: string) => {
        if (xAxisName === 'step') return val.toString();

        switch (suffix) {
            case ' с':
                return convertMs(+val, 's') + suffix;
            case ' мин':
                return convertMs(+val, 'm') + suffix;
            case ' ч':
                return convertMs(+val, 'h') + suffix;
            case ' дн':
                return convertMs(+val, 'd') + suffix;
            default:
                return '';
        }
    };

    const timeLabelFormatter = (val: number) => {
        if (xAxisName === 'timems') return `Время: ${timeTickFormatter(val, timeSuffix)}`;

        return `Эпоха: ${val}`;
    };

    return (
        <div className='chartWrapperTitle'>
            <ResponsiveContainer width={470} minHeight={245} minWidth={470}>
                <LineChart width={400} height={300} data={data ?? []}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        tickFormatter={(val) => timeTickFormatter(val, timeSuffix)}
                        dataKey={xAxisName}
                        interval={xAxisInterval}
                        tickCount={TICK_COUNT}
                    />
                    <YAxis dataKey={yAxisName} tickCount={TICK_COUNT} />
                    <Tooltip labelFormatter={timeLabelFormatter} />
                    <Legend verticalAlign='top' align='left' height={36} />
                    <Line
                        dot={false}
                        type='monotone'
                        name={title}
                        dataKey='value'
                        stroke='#82ca9d'
                        activeDot={{ r: 3 }}
                        isAnimationActive={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;
