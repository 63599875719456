import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

type LoadingCellProps = {
    query(e?: string | number): Promise<any>;
    query_value?: string | number;
};

const LoadingCell = ({ query, query_value }: LoadingCellProps): JSX.Element => {
    const [cellValue, setCellValue] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getQuery = async (): Promise<void> => {
        setIsLoading(true);
        if (query_value && query) {
            try {
                const value = await query(query_value);
                setCellValue(value);
            } catch (e) {
                console.warn(e);
                setCellValue('ОШИБКА');
            } finally {
                setIsLoading(false);
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getQuery();
    }, []);

    return <Spin spinning={isLoading}>{cellValue}</Spin>;
};

export default LoadingCell;
