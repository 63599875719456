// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal';

export interface Props {
    membershipInstance: any;
    onRemoveMembership(): void;
    onUpdateMembershipRole(role: string): void;
}

function MemberItem(props: Props): JSX.Element {
    const {
        membershipInstance, onRemoveMembership, onUpdateMembershipRole,
    } = props;
    const {
        user, joined_date: joinedDate, role, invitation,
    } = membershipInstance;
    const { username, firstName, lastName } = user;

    moment.locale('ru');

    return (
        <Row className='cvat-organization-member-item' justify='space-between'>
            <Col span={5} className='cvat-organization-member-item-username'>
                <Text strong>{username}</Text>
            </Col>
            <Col span={6} className='cvat-organization-member-item-name'>
                <Text strong>{`${firstName || ''} ${lastName || ''}`}</Text>
            </Col>
            <Col span={8} className='cvat-organization-member-item-dates'>
                {invitation ? (
                    <Text type='secondary'>
                        {`Приглашен ${moment(invitation.created_date).fromNow()} ${invitation.owner ? `пользователем ${invitation.owner.username}` : ''}`}
                    </Text>
                ) : null}
                {joinedDate ? <Text type='secondary'>{`Добавлен ${moment(joinedDate).fromNow()}`}</Text> : null}
            </Col>
            <Col span={3} className='cvat-organization-member-item-role'>
                <Select
                    onChange={(_role: string) => {
                        onUpdateMembershipRole(_role);
                    }}
                    value={role}
                    disabled={role === 'owner'}
                >
                    {role === 'owner' ? (
                        <Select.Option value='owner'>Владелец</Select.Option>
                    ) : (
                        <>
                            <Select.Option value='worker'>Разметчик</Select.Option>
                            <Select.Option value='supervisor'>Руководитель</Select.Option>
                            <Select.Option value='maintainer'>Сопровождающий</Select.Option>
                        </>
                    )}
                </Select>
            </Col>
            <Col span={1} className='cvat-organization-member-item-remove'>
                {role !== 'owner' ? (
                    <DeleteOutlined
                        onClick={() => {
                            Modal.confirm({
                                className: 'cvat-modal-organization-member-remove',
                                title: `Вы удаляете "${username}" из организации`,
                                content: 'Пользователь больше не будет иметь доступ к данным организации. Продолжить?',
                                okText: 'Да, удалить',
                                cancelText: 'Отмена',
                                okButtonProps: {
                                    danger: true,
                                },
                                onOk: () => {
                                    onRemoveMembership();
                                },
                            });
                        }}
                    />
                ) : null}
            </Col>
        </Row>
    );
}

export default React.memo(MemberItem);
