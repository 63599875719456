import { Button, Dropdown, Empty, Input, InputRef, Modal, Space, Tag, Tooltip, Typography, notification } from 'antd';
import moment from 'moment';
import Table, { ColumnType, ColumnsType } from 'antd/lib/table';
import React, { useEffect, useRef, useState } from 'react';
import {
    CheckCircleOutlined,
    DownloadOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    StopOutlined,
} from '@ant-design/icons';
import './styles.scss';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { getClientHost } from 'shared/constants';
import { ObjectId, ObjectsId } from 'proto/Common_pb';
import { DetectorServiceClient, TrainingQueryServiceClient } from 'proto/c60/nn/center/DetectorsServiceClientPb';
import { useQuery } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { createEnhancedClient } from 'utils/grpc';
import { TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import LoadingCell from '../loading-cell/loading-cell';
import _ from 'lodash';
import { buildDurationFromMS } from './helpers';

type TrainingQueryTableProps = {
    setDataSource: React.Dispatch<React.SetStateAction<TrainingQuery.AsObject[] | undefined>>;
    dataSource: TrainingQuery.AsObject[] | undefined;
    loading: boolean;
    refresh(): Promise<void>;
};

const TrainingQueryTable = ({ dataSource, setDataSource, loading, refresh }: TrainingQueryTableProps): JSX.Element => {
    const currentHost = getClientHost();
    const searchInput = useRef<InputRef>(null);
    const history = useHistory();

    const [currentPagination, setCurrentPagination] = useState<number>(1);

    useQuery('refreshqueries', () => refresh(), {
        refetchInterval: 30000,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const getDatasetImagesCountById = async (id: number): Promise<any> => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const datasetInfo = (await client.getInfo(new ObjectsId().setIdsList([id]), {})).getListList()[0];
            return (
                datasetInfo.getTestsetimages() + datasetInfo.getTrainsetimages() + datasetInfo.getValidationsetimages()
            );
        }
        return 0;
    };

    const getDatasetNameById = async (id: number): Promise<any> => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const datasetInfo = (await client.getInfo(new ObjectsId().setIdsList([id]), {})).getListList()[0];
            return datasetInfo.getTitle();
        }
        return 0;
    };

    const cancelQuery = async (id?: number, name?: string): Promise<any> => {
        Modal.confirm({
            title: `Процесс "${name}" будет отменен`,
            content: 'Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Оставить',
            okText: 'Отменить',
            async onOk() {
                if (currentHost && id) {
                    const client = createEnhancedClient(TrainingQueryServiceClient);
                    try {
                        await client.cancelQuery(new ObjectId().setId(id), {});
                        refresh();
                    } catch (e: any) {
                        notification.error({
                            message: 'Не удалось остановить процесс обучения нейросетей',
                            description: e.message,
                        });
                    }
                }
            },
            onCancel() {},
        });
    };

    const finishQuery = async (id?: number, name?: string): Promise<any> => {
        Modal.confirm({
            title: `Процесс "${name}" будет завершен`,
            content: 'Продолжить?',
            className: 'cvat-modal-confirm-remove-project',
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            cancelText: 'Отменить',
            okText: 'Завершить',
            async onOk() {
                if (currentHost && id) {
                    const client = createEnhancedClient(TrainingQueryServiceClient);
                    try {
                        await client.finishQuery(new ObjectId().setId(id), {});
                        refresh();
                    } catch (e: any) {
                        notification.error({
                            message: 'Не удалось завершить процесс обучения нейросетей',
                            description: e.message,
                        });
                    }
                }
            },
            onCancel() {},
        });
    };

    const downloadQuery = async (id?: number): Promise<any> => {
        if (currentHost && id) {
            const client = createEnhancedClient(DetectorServiceClient);
            try {
                const currentDetector = (await client.getInfo(new ObjectsId().addIds(id), {})).getListList()[0];
                const downloadUrl = currentDetector?.getYolomodel()?.getUrl();
                if (downloadUrl) {
                    notification.success({
                        message: 'Скачивание процесса обучения',
                        description: 'Процесс скачивания процесса обучения успешно начат',
                    });
                    document.location = downloadUrl?.replace(/^.*(?=\/center\/download.*)/, '');
                }
                refresh();
            } catch (e: any) {
                notification.error({
                    message: 'Не удалось завершить процесс обучения нейросетей',
                    description: e.message,
                });
            }
        }
    };

    const resumeCanceledQuery = async (id?: number): Promise<any> => {
        if (currentHost && id) {
            const client = createEnhancedClient(TrainingQueryServiceClient);
            try {
                const resumeQuery = (await client.resumeCanceledQuery(new ObjectId().setId(id), {})).toObject();

                const newDataSource = _.cloneDeep(dataSource);
                const findIndexQuery = newDataSource?.findIndex((a) => a.id == id);
                if (newDataSource && findIndexQuery && resumeQuery) {
                    newDataSource[findIndexQuery] = resumeQuery;
                    setDataSource(newDataSource);
                }
            } catch (e: any) {
                notification.error({
                    message: 'Не удалось возобновить процесс обучения нейросетей',
                    description: e.message,
                });
            }
        }
    };

    const getColumnSearchProps = (dataIndex: keyof TrainingQuery.AsObject): ColumnType<TrainingQuery.AsObject> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()} aria-hidden='true'>
                <Input
                    ref={searchInput}
                    placeholder='Найти...'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            close();
                        }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                clearFilters();
                            }

                            confirm({ closeDropdown: false });
                            close();
                        }}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Сбросить
                    </Button>
                    <Button type='link' size='small' onClick={() => close()}>
                        Закрыть
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#00F8E0' : undefined }} />,
        onFilter: (value, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const tableColumns: ColumnsType<TrainingQuery.AsObject> = [
        {
            title: 'Название',
            dataIndex: 'detectorname',
            key: 'detectorname',
            width: 300,
            ...getColumnSearchProps('detectorname'),
        },
        {
            title: 'Дата создания',
            dataIndex: 'creationtime',
            key: 'creationtime',
            width: 140,
            render: (_, record) => (
                <Typography.Text>
                    {record.creationtime && new Date(record.creationtime?.unixtime).toLocaleDateString('ru-RU')}
                </Typography.Text>
            ),
        },
        {
            title: 'Датасет',
            width: 145,
            dataIndex: 'datasetid',
            render: (e) => (
                <Link to={`/dataset?info=${e}`}>
                    <LoadingCell query={getDatasetNameById} query_value={e} />
                </Link>
            ),
        },
        {
            title: 'Прогресс',
            dataIndex: 'state',
            key: 'state',
            width: 155,
            filters: [
                {
                    text: 'Ошибка',
                    value: 0,
                },
                {
                    text: 'Ожидание',
                    value: 1,
                },
                {
                    text: 'Обучение',
                    value: 2,
                },
                {
                    text: 'Завершено',
                    value: 3,
                },
                {
                    text: 'Отменено',
                    value: 4,
                },
            ],
            onFilter: (value: any, record) => record.state === value,
            render: (e, record) => {
                switch (e) {
                    case 0:
                        return <Tag className='status-tag nonestate'>Ошибка</Tag>;

                    case 1:
                        return <Tag className='status-tag pending'>Ожидание</Tag>;

                    case 2:
                        return <Tag className='status-tag running'>Обучение</Tag>;

                    case 3:
                        return <Tag className='status-tag done'>Завершено</Tag>;

                    case 4:
                        if (record.currentstep?.error)
                            return (
                                <Tooltip title={record.currentstep.error}>
                                    <Tag className='status-tag canceled'>Ошибка</Tag>
                                </Tooltip>
                            );

                        return <Tag className='status-tag canceled'>Отменено</Tag>;

                    default:
                        return <></>;
                }
            },
        },
        {
            title: 'Пройдено эпох',
            key: 'steps',
            width: 135,
            render: (_, e) => {
                if (e.currentstep?.step && e.trainsettings?.epochs) {
                    return (
                        <Typography.Text>
                            {`${
                                e.currentstep.step < e.trainsettings.epochs
                                    ? e.currentstep.step
                                    : e.currentstep.step - 1
                            }
                            ${' '}
                            из
                            ${' '}
                            ${e.trainsettings.epochs}`}
                        </Typography.Text>
                    );
                }
                return <Typography.Text>-</Typography.Text>;
            },
        },
        {
            title: 'Время обучения',
            key: 'remaindurationms',
            width: 190,
            render: (_, e) => {
                if (e.currentstep?.remaindurationms || e.currentstep?.durationms) {
                    if (e.state === 3) {
                        return (
                            <Typography.Text>
                                {`${String(buildDurationFromMS(e.currentstep?.durationms).hours).padStart(
                                    2,
                                    '0',
                                )}:${String(buildDurationFromMS(e.currentstep?.durationms).minutes).padStart(2, '0')}`}
                            </Typography.Text>
                        );
                    }
                    return (
                        <Typography.Text>
                            {`${String(buildDurationFromMS(e.currentstep?.durationms).hours).padStart(2, '0')}:${String(
                                buildDurationFromMS(e.currentstep?.durationms).minutes,
                            ).padStart(2, '0')}
                            ${' '}
                            из
                            ${' '}
                            ${String(buildDurationFromMS(e.currentstep?.durationms).hours).padStart(2, '0')}:${String(
                                buildDurationFromMS(e.currentstep?.durationms).minutes,
                            ).padStart(2, '0')}`}
                        </Typography.Text>
                    );
                }
                return <Typography.Text>-</Typography.Text>;
            },
        },
        {
            title: 'Изображения',
            width: 135,
            dataIndex: 'datasetid',
            render: (e) => <LoadingCell query={getDatasetImagesCountById} query_value={e} />,
        },
        {
            title: 'Классы',
            key: 'classescount',
            width: 115,
            dataIndex: ['architecture', 'classescount'],
            sortDirections: ['ascend', 'descend'],
            // @ts-expect-error
            sorter: (a, b) => a.architecture?.classescount - b.architecture?.classescount,
        },
        {
            title: 'Точность',
            width: 115,
            dataIndex: ['currentstep', 'precision'],
            sortDirections: ['ascend', 'descend'],
            // @ts-expect-error
            sorter: (a, b) => a.currentstep?.precision - b.currentstep?.precision,
            render: (e: number) => <Typography.Text>{e ? Math.floor(Number(e.toFixed(2)) * 100) : 0}%</Typography.Text>,
        },
        {
            title: 'Действия',
            width: 130,
            dataIndex: '',
            render: (_, record) => (
                <Dropdown.Button
                    type='link'
                    className='actions-button'
                    icon={<EllipsisOutlined rotate={90} />}
                    overlay={
                        <div className='training-query-actions-more'>
                            <Button
                                type='link'
                                onClick={() => history.push(`learning/model/${record.id}`)}
                                icon={<ExclamationCircleOutlined />}
                            >
                                Открыть
                            </Button>
                            {(record.state === 1 || record.state === 2) && (
                                <Button
                                    icon={<StopOutlined />}
                                    type='link'
                                    danger
                                    onClick={() => cancelQuery(record.id, record.detectorname)}
                                >
                                    Отменить
                                </Button>
                            )}
                            {record.state === 2 && (
                                <Button
                                    icon={<CheckCircleOutlined />}
                                    type='link'
                                    onClick={() => finishQuery(record.id, record.detectorname)}
                                >
                                    Завершить
                                </Button>
                            )}
                            {record.state === 3 && (
                                <Button
                                    onClick={() => downloadQuery(record.readydetectorid?.id)}
                                    type='link'
                                    icon={<DownloadOutlined />}
                                >
                                    Скачать
                                </Button>
                            )}
                            {record.state === 4 && (
                                <Button
                                    onClick={() => resumeCanceledQuery(record.id)}
                                    type='link'
                                    icon={<ReloadOutlined />}
                                >
                                    Возобновить обучение
                                </Button>
                            )}
                        </div>
                    }
                />
            ),
        },
    ];

    return (
        <Table
            className='trainingquery-table'
            key={`${Date.now()}_loading-done`}
            showSorterTooltip={false}
            dataSource={_.cloneDeep(dataSource)}
            columns={tableColumns}
            pagination={{
                pageSize: 11,
                hideOnSinglePage: true,
                showSizeChanger: false,
                current: currentPagination,
                onChange: (page) => setCurrentPagination(page),
            }}
            loading={{ spinning: loading, tip: 'Загрузка данных...', size: 'small' }}
            locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='Нет данных' />,
                filterReset: 'Сбросить',
            }}
        />
    );
};
export default TrainingQueryTable;
