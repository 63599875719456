import moment from 'moment';

const buildDurationFromMS = (
    durationms: number,
): {
    hours: number;
    minutes: number;
    seconds: number;
} => {
    const dur = moment.duration(durationms, 'milliseconds');

    const hours = Math.floor(dur.asHours());
    const minutes = Math.floor(dur.asMinutes()) - hours * 60;
    const seconds = Math.floor(dur.asSeconds()) - hours * 60 * 60 - minutes * 60;

    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
    };
};

export { buildDurationFromMS };
