/**
 * @fileoverview gRPC-Web generated client stub for grpc.c60.nn.center
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.2
// source: c60/nn/center/Detectors.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as Common_pb from '../../../Common_pb';
import * as c60_nn_center_Detectors_pb from '../../../c60/nn/center/Detectors_pb';


export class TrainingRunnerServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptortestConnection = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/testConnection',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_Detectors_pb.TrainingRunner,
    Common_pb.Check,
    (request: c60_nn_center_Detectors_pb.TrainingRunner) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  testConnection(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  testConnection(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  testConnection(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/testConnection',
        request,
        metadata || {},
        this.methodDescriptortestConnection,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/testConnection',
    request,
    metadata || {},
    this.methodDescriptortestConnection);
  }

  methodDescriptorgetAvailable = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/getAvailable',
    grpcWeb.MethodType.UNARY,
    Common_pb.StringSearch,
    Common_pb.ObjectsId,
    (request: Common_pb.StringSearch) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/getAvailable',
        request,
        metadata || {},
        this.methodDescriptorgetAvailable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/getAvailable',
    request,
    metadata || {},
    this.methodDescriptorgetAvailable);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    c60_nn_center_Detectors_pb.TrainingRunnersList,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingRunnersList.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingRunnersList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingRunnersList) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingRunnersList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingRunnersList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptorinsert = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/insert',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_Detectors_pb.TrainingRunner,
    c60_nn_center_Detectors_pb.TrainingRunner,
    (request: c60_nn_center_Detectors_pb.TrainingRunner) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingRunner.deserializeBinary
  );

  insert(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingRunner>;

  insert(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingRunner) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingRunner>;

  insert(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingRunner) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/insert',
        request,
        metadata || {},
        this.methodDescriptorinsert,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/insert',
    request,
    metadata || {},
    this.methodDescriptorinsert);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

  methodDescriptorcheckUniqName = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/checkUniqName',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_Detectors_pb.TrainingRunner,
    Common_pb.Check,
    (request: c60_nn_center_Detectors_pb.TrainingRunner) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkUniqName(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkUniqName(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkUniqName(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/checkUniqName',
        request,
        metadata || {},
        this.methodDescriptorcheckUniqName,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/checkUniqName',
    request,
    metadata || {},
    this.methodDescriptorcheckUniqName);
  }

  methodDescriptorcheckUniqAddress = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingRunnerService/checkUniqAddress',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_Detectors_pb.TrainingRunner,
    Common_pb.Check,
    (request: c60_nn_center_Detectors_pb.TrainingRunner) => {
      return request.serializeBinary();
    },
    Common_pb.Check.deserializeBinary
  );

  checkUniqAddress(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.Check>;

  checkUniqAddress(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void): grpcWeb.ClientReadableStream<Common_pb.Check>;

  checkUniqAddress(
    request: c60_nn_center_Detectors_pb.TrainingRunner,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.Check) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingRunnerService/checkUniqAddress',
        request,
        metadata || {},
        this.methodDescriptorcheckUniqAddress,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingRunnerService/checkUniqAddress',
    request,
    metadata || {},
    this.methodDescriptorcheckUniqAddress);
  }

}

export class TrainingQueryServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAvailable = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/getAvailable',
    grpcWeb.MethodType.UNARY,
    Common_pb.StringSearch,
    Common_pb.ObjectsId,
    (request: Common_pb.StringSearch) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/getAvailable',
        request,
        metadata || {},
        this.methodDescriptorgetAvailable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/getAvailable',
    request,
    metadata || {},
    this.methodDescriptorgetAvailable);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    c60_nn_center_Detectors_pb.TrainingQueryList,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingQueryList.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingQueryList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryList) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingQueryList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptorstartQuery = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/startQuery',
    grpcWeb.MethodType.UNARY,
    c60_nn_center_Detectors_pb.TrainingQuery,
    c60_nn_center_Detectors_pb.TrainingQuery,
    (request: c60_nn_center_Detectors_pb.TrainingQuery) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingQuery.deserializeBinary
  );

  startQuery(
    request: c60_nn_center_Detectors_pb.TrainingQuery,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingQuery>;

  startQuery(
    request: c60_nn_center_Detectors_pb.TrainingQuery,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQuery) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingQuery>;

  startQuery(
    request: c60_nn_center_Detectors_pb.TrainingQuery,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQuery) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/startQuery',
        request,
        metadata || {},
        this.methodDescriptorstartQuery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/startQuery',
    request,
    metadata || {},
    this.methodDescriptorstartQuery);
  }

  methodDescriptorresumeCanceledQuery = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/resumeCanceledQuery',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectId,
    c60_nn_center_Detectors_pb.TrainingQuery,
    (request: Common_pb.ObjectId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingQuery.deserializeBinary
  );

  resumeCanceledQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingQuery>;

  resumeCanceledQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQuery) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingQuery>;

  resumeCanceledQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQuery) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/resumeCanceledQuery',
        request,
        metadata || {},
        this.methodDescriptorresumeCanceledQuery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/resumeCanceledQuery',
    request,
    metadata || {},
    this.methodDescriptorresumeCanceledQuery);
  }

  methodDescriptorcancelQuery = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/cancelQuery',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectId,
    c60_nn_center_Detectors_pb.TrainingQueryOption,
    (request: Common_pb.ObjectId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingQueryOption.deserializeBinary
  );

  cancelQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingQueryOption>;

  cancelQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryOption) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingQueryOption>;

  cancelQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryOption) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/cancelQuery',
        request,
        metadata || {},
        this.methodDescriptorcancelQuery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/cancelQuery',
    request,
    metadata || {},
    this.methodDescriptorcancelQuery);
  }

  methodDescriptorfinishQuery = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/finishQuery',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectId,
    c60_nn_center_Detectors_pb.TrainingQueryOption,
    (request: Common_pb.ObjectId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingQueryOption.deserializeBinary
  );

  finishQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingQueryOption>;

  finishQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryOption) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingQueryOption>;

  finishQuery(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingQueryOption) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/finishQuery',
        request,
        metadata || {},
        this.methodDescriptorfinishQuery,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/finishQuery',
    request,
    metadata || {},
    this.methodDescriptorfinishQuery);
  }

  methodDescriptorwatchTraining = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/watchTraining',
    grpcWeb.MethodType.SERVER_STREAMING,
    Common_pb.ObjectId,
    c60_nn_center_Detectors_pb.TrainingTelemetryOnRunner,
    (request: Common_pb.ObjectId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingTelemetryOnRunner.deserializeBinary
  );

  watchTraining(
    request: Common_pb.ObjectId,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingTelemetryOnRunner> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/grpc.c60.nn.center.TrainingQueryService/watchTraining',
      request,
      metadata || {},
      this.methodDescriptorwatchTraining);
  }

  methodDescriptorgetTrainGraphics = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.TrainingQueryService/getTrainGraphics',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectId,
    c60_nn_center_Detectors_pb.TrainingGraphics,
    (request: Common_pb.ObjectId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.TrainingGraphics.deserializeBinary
  );

  getTrainGraphics(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.TrainingGraphics>;

  getTrainGraphics(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingGraphics) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.TrainingGraphics>;

  getTrainGraphics(
    request: Common_pb.ObjectId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.TrainingGraphics) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.TrainingQueryService/getTrainGraphics',
        request,
        metadata || {},
        this.methodDescriptorgetTrainGraphics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.TrainingQueryService/getTrainGraphics',
    request,
    metadata || {},
    this.methodDescriptorgetTrainGraphics);
  }

}

export class DetectorServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetAvailable = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DetectorService/getAvailable',
    grpcWeb.MethodType.UNARY,
    Common_pb.StringSearch,
    Common_pb.ObjectsId,
    (request: Common_pb.StringSearch) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  getAvailable(
    request: Common_pb.StringSearch,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DetectorService/getAvailable',
        request,
        metadata || {},
        this.methodDescriptorgetAvailable,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DetectorService/getAvailable',
    request,
    metadata || {},
    this.methodDescriptorgetAvailable);
  }

  methodDescriptorgetInfo = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DetectorService/getInfo',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    c60_nn_center_Detectors_pb.DetectorsList,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    c60_nn_center_Detectors_pb.DetectorsList.deserializeBinary
  );

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<c60_nn_center_Detectors_pb.DetectorsList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.DetectorsList) => void): grpcWeb.ClientReadableStream<c60_nn_center_Detectors_pb.DetectorsList>;

  getInfo(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: c60_nn_center_Detectors_pb.DetectorsList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DetectorService/getInfo',
        request,
        metadata || {},
        this.methodDescriptorgetInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DetectorService/getInfo',
    request,
    metadata || {},
    this.methodDescriptorgetInfo);
  }

  methodDescriptordelete = new grpcWeb.MethodDescriptor(
    '/grpc.c60.nn.center.DetectorService/delete',
    grpcWeb.MethodType.UNARY,
    Common_pb.ObjectsId,
    Common_pb.ObjectsId,
    (request: Common_pb.ObjectsId) => {
      return request.serializeBinary();
    },
    Common_pb.ObjectsId.deserializeBinary
  );

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null): Promise<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void): grpcWeb.ClientReadableStream<Common_pb.ObjectsId>;

  delete(
    request: Common_pb.ObjectsId,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: Common_pb.ObjectsId) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grpc.c60.nn.center.DetectorService/delete',
        request,
        metadata || {},
        this.methodDescriptordelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grpc.c60.nn.center.DetectorService/delete',
    request,
    metadata || {},
    this.methodDescriptordelete);
  }

}

