import './styles.css';
import React from 'react';
import { Button, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Text } = Typography;

export default function ClassificationsTopBar({ handleAdd }: any): JSX.Element {
    return (
        <div className='cvat-task-top-bar'>
            <Text className='classreg-header-text'>Классификатор</Text>
            <div>
                <Button size='middle' type='primary' className='cvat-project-top-bar-create-dataset-button' onClick={() => handleAdd()}>
                    <PlusOutlined />
                    Добавить корневой класс
                </Button>
            </div>
        </div>
    );
}
