import React from 'react';
import {
    Form, Input, Switch, Row, Col,
} from 'antd';
import { FormInstance } from 'antd/lib/form';

import {
    Dumper, Project, Task, Job,
} from 'cvat-core-wrapper';

import type { FormValues } from './export-dataset-modal';
import FormatSelectDataset from './format-select-dataset';

interface ExportDatasetProps {
    form: FormInstance<any>;
    initialValues: FormValues;
    handleExport?: ((values: any) => void);
    dumpers: Dumper[];
    instance: Project | Task | Job | null;
    current: string[];
    datasetFormat: string;
    setDatasetFormat: ((values: string) => void);
}

const ExportDataset = (props: ExportDatasetProps): JSX.Element => {
    const {
        form, initialValues, handleExport, dumpers, instance, current, datasetFormat, setDatasetFormat,
    } = props;

    return (
        <Form
            name='Create dataset'
            form={form}
            layout='vertical'
            initialValues={{ ...initialValues, selectedFormat: datasetFormat }}
            onFinish={handleExport}
        >
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Формат</div>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name='selectedFormat'
                        rules={[{ required: true, message: 'Необходимо выбрать формат' }]}
                        noStyle
                    >
                        <FormatSelectDataset
                            setDatasetFormat={setDatasetFormat}
                            datasetFormat={datasetFormat}
                            dumpers={dumpers}
                            instance={instance}
                            current={current}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Сохранить изображения</div>
                </Col>
                <Col span={18} className='modal-export-param-headers'>
                    <Form.Item
                        name='saveImages'
                        valuePropName='checked'
                        noStyle
                    >
                        <Switch className='cvat-modal-export-save-images' />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ marginBottom: 33 }}>
                <Col span={6}>
                    <div className='modal-export-param-headers'>Название датасета</div>
                </Col>
                <Col span={18}>
                    <Form.Item
                        name='customName'
                        noStyle
                    >
                        <Input
                            placeholder='Укажите название датасета'
                            suffix='.zip'
                            className='cvat-modal-export-filename-input'
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ExportDataset;
