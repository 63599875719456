// source: c60/nn/center/DatasetClasses.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

var Common_pb = require('../../../Common_pb.js');
goog.object.extend(proto, Common_pb);
goog.exportSymbol('proto.grpc.c60.nn.center.Attribute', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.Attribute.ContentCase', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.AttributeList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DClass', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DClassList', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.DClassType', null, global);
goog.exportSymbol('proto.grpc.c60.nn.center.NumberDescription', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.NumberDescription = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.NumberDescription, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.NumberDescription.displayName = 'proto.grpc.c60.nn.center.NumberDescription';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpc.c60.nn.center.Attribute.oneofGroups_);
};
goog.inherits(proto.grpc.c60.nn.center.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.Attribute.displayName = 'proto.grpc.c60.nn.center.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.AttributeList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.AttributeList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.AttributeList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.AttributeList.displayName = 'proto.grpc.c60.nn.center.AttributeList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.center.DClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DClass.displayName = 'proto.grpc.c60.nn.center.DClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.center.DClassList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.center.DClassList.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.center.DClassList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.center.DClassList.displayName = 'proto.grpc.c60.nn.center.DClassList';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.NumberDescription.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.NumberDescription} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.NumberDescription.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    step: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.NumberDescription}
 */
proto.grpc.c60.nn.center.NumberDescription.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.NumberDescription;
  return proto.grpc.c60.nn.center.NumberDescription.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.NumberDescription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.NumberDescription}
 */
proto.grpc.c60.nn.center.NumberDescription.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMax(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.NumberDescription.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.NumberDescription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.NumberDescription.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getStep();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional float min = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.NumberDescription} returns this
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float max = 2;
 * @return {number}
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.NumberDescription} returns this
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float step = 3;
 * @return {number}
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.NumberDescription} returns this
 */
proto.grpc.c60.nn.center.NumberDescription.prototype.setStep = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpc.c60.nn.center.Attribute.oneofGroups_ = [[4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.Attribute.ContentCase = {
  CONTENT_NOT_SET: 0,
  SELECTMANY: 4,
  RADIO: 5,
  CHECKBOX: 6,
  TEXT: 7,
  NUMBER: 8
};

/**
 * @return {proto.grpc.c60.nn.center.Attribute.ContentCase}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getContentCase = function() {
  return /** @type {proto.grpc.c60.nn.center.Attribute.ContentCase} */(jspb.Message.computeOneofCase(this, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mutable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    selectmany: (f = msg.getSelectmany()) && Common_pb.StringList.toObject(includeInstance, f),
    radio: (f = msg.getRadio()) && Common_pb.StringList.toObject(includeInstance, f),
    checkbox: (f = msg.getCheckbox()) && Common_pb.BooleanMsg.toObject(includeInstance, f),
    text: (f = msg.getText()) && Common_pb.StringMsg.toObject(includeInstance, f),
    number: (f = msg.getNumber()) && proto.grpc.c60.nn.center.NumberDescription.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.Attribute}
 */
proto.grpc.c60.nn.center.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.Attribute;
  return proto.grpc.c60.nn.center.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.Attribute}
 */
proto.grpc.c60.nn.center.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMutable(value);
      break;
    case 4:
      var value = new Common_pb.StringList;
      reader.readMessage(value,Common_pb.StringList.deserializeBinaryFromReader);
      msg.setSelectmany(value);
      break;
    case 5:
      var value = new Common_pb.StringList;
      reader.readMessage(value,Common_pb.StringList.deserializeBinaryFromReader);
      msg.setRadio(value);
      break;
    case 6:
      var value = new Common_pb.BooleanMsg;
      reader.readMessage(value,Common_pb.BooleanMsg.deserializeBinaryFromReader);
      msg.setCheckbox(value);
      break;
    case 7:
      var value = new Common_pb.StringMsg;
      reader.readMessage(value,Common_pb.StringMsg.deserializeBinaryFromReader);
      msg.setText(value);
      break;
    case 8:
      var value = new proto.grpc.c60.nn.center.NumberDescription;
      reader.readMessage(value,proto.grpc.c60.nn.center.NumberDescription.deserializeBinaryFromReader);
      msg.setNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMutable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSelectmany();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getRadio();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      Common_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getCheckbox();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      Common_pb.BooleanMsg.serializeBinaryToWriter
    );
  }
  f = message.getText();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.StringMsg.serializeBinaryToWriter
    );
  }
  f = message.getNumber();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.grpc.c60.nn.center.NumberDescription.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool mutable = 3;
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getMutable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.setMutable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional common.StringList selectMany = 4;
 * @return {?proto.common.StringList}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getSelectmany = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, Common_pb.StringList, 4));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
*/
proto.grpc.c60.nn.center.Attribute.prototype.setSelectmany = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearSelectmany = function() {
  return this.setSelectmany(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasSelectmany = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional common.StringList radio = 5;
 * @return {?proto.common.StringList}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getRadio = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, Common_pb.StringList, 5));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
*/
proto.grpc.c60.nn.center.Attribute.prototype.setRadio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearRadio = function() {
  return this.setRadio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasRadio = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional common.BooleanMsg checkbox = 6;
 * @return {?proto.common.BooleanMsg}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getCheckbox = function() {
  return /** @type{?proto.common.BooleanMsg} */ (
    jspb.Message.getWrapperField(this, Common_pb.BooleanMsg, 6));
};


/**
 * @param {?proto.common.BooleanMsg|undefined} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
*/
proto.grpc.c60.nn.center.Attribute.prototype.setCheckbox = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearCheckbox = function() {
  return this.setCheckbox(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasCheckbox = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.StringMsg text = 7;
 * @return {?proto.common.StringMsg}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getText = function() {
  return /** @type{?proto.common.StringMsg} */ (
    jspb.Message.getWrapperField(this, Common_pb.StringMsg, 7));
};


/**
 * @param {?proto.common.StringMsg|undefined} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
*/
proto.grpc.c60.nn.center.Attribute.prototype.setText = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearText = function() {
  return this.setText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasText = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional NumberDescription number = 8;
 * @return {?proto.grpc.c60.nn.center.NumberDescription}
 */
proto.grpc.c60.nn.center.Attribute.prototype.getNumber = function() {
  return /** @type{?proto.grpc.c60.nn.center.NumberDescription} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.NumberDescription, 8));
};


/**
 * @param {?proto.grpc.c60.nn.center.NumberDescription|undefined} value
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
*/
proto.grpc.c60.nn.center.Attribute.prototype.setNumber = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.grpc.c60.nn.center.Attribute.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.Attribute} returns this
 */
proto.grpc.c60.nn.center.Attribute.prototype.clearNumber = function() {
  return this.setNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.Attribute.prototype.hasNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.AttributeList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.AttributeList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.AttributeList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.AttributeList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.AttributeList.toObject = function(includeInstance, msg) {
  var f, obj = {
    attributesList: jspb.Message.toObjectList(msg.getAttributesList(),
    proto.grpc.c60.nn.center.Attribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.AttributeList}
 */
proto.grpc.c60.nn.center.AttributeList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.AttributeList;
  return proto.grpc.c60.nn.center.AttributeList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.AttributeList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.AttributeList}
 */
proto.grpc.c60.nn.center.AttributeList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.Attribute;
      reader.readMessage(value,proto.grpc.c60.nn.center.Attribute.deserializeBinaryFromReader);
      msg.addAttributes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.AttributeList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.AttributeList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.AttributeList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.AttributeList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttributesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.Attribute.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Attribute attributes = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.Attribute>}
 */
proto.grpc.c60.nn.center.AttributeList.prototype.getAttributesList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.Attribute, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.Attribute>} value
 * @return {!proto.grpc.c60.nn.center.AttributeList} returns this
*/
proto.grpc.c60.nn.center.AttributeList.prototype.setAttributesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.Attribute}
 */
proto.grpc.c60.nn.center.AttributeList.prototype.addAttributes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.AttributeList} returns this
 */
proto.grpc.c60.nn.center.AttributeList.prototype.clearAttributesList = function() {
  return this.setAttributesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DClass.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kind: jspb.Message.getFieldWithDefault(msg, 3, 0),
    color: (f = msg.getColor()) && Common_pb.SolidColor.toObject(includeInstance, f),
    attributes: (f = msg.getAttributes()) && proto.grpc.c60.nn.center.AttributeList.toObject(includeInstance, f),
    ispart: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    childrendclassid: (f = msg.getChildrendclassid()) && Common_pb.ObjectsId.toObject(includeInstance, f),
    parentdclassid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    description: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DClass}
 */
proto.grpc.c60.nn.center.DClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DClass;
  return proto.grpc.c60.nn.center.DClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DClass}
 */
proto.grpc.c60.nn.center.DClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {!proto.grpc.c60.nn.center.DClassType} */ (reader.readEnum());
      msg.setKind(value);
      break;
    case 4:
      var value = new Common_pb.SolidColor;
      reader.readMessage(value,Common_pb.SolidColor.deserializeBinaryFromReader);
      msg.setColor(value);
      break;
    case 5:
      var value = new proto.grpc.c60.nn.center.AttributeList;
      reader.readMessage(value,proto.grpc.c60.nn.center.AttributeList.deserializeBinaryFromReader);
      msg.setAttributes(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspart(value);
      break;
    case 7:
      var value = new Common_pb.ObjectsId;
      reader.readMessage(value,Common_pb.ObjectsId.deserializeBinaryFromReader);
      msg.setChildrendclassid(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentdclassid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.grpc.c60.nn.center.DClassType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getColor();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      Common_pb.SolidColor.serializeBinaryToWriter
    );
  }
  f = message.getAttributes();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpc.c60.nn.center.AttributeList.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getChildrendclassid();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      Common_pb.ObjectsId.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.grpc.c60.nn.center.DClass.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.grpc.c60.nn.center.DClass.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DClassType kind = 3;
 * @return {!proto.grpc.c60.nn.center.DClassType}
 */
proto.grpc.c60.nn.center.DClass.prototype.getKind = function() {
  return /** @type {!proto.grpc.c60.nn.center.DClassType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grpc.c60.nn.center.DClassType} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setKind = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearKind = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasKind = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.SolidColor color = 4;
 * @return {?proto.common.SolidColor}
 */
proto.grpc.c60.nn.center.DClass.prototype.getColor = function() {
  return /** @type{?proto.common.SolidColor} */ (
    jspb.Message.getWrapperField(this, Common_pb.SolidColor, 4));
};


/**
 * @param {?proto.common.SolidColor|undefined} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
*/
proto.grpc.c60.nn.center.DClass.prototype.setColor = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearColor = function() {
  return this.setColor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasColor = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AttributeList attributes = 5;
 * @return {?proto.grpc.c60.nn.center.AttributeList}
 */
proto.grpc.c60.nn.center.DClass.prototype.getAttributes = function() {
  return /** @type{?proto.grpc.c60.nn.center.AttributeList} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.center.AttributeList, 5));
};


/**
 * @param {?proto.grpc.c60.nn.center.AttributeList|undefined} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
*/
proto.grpc.c60.nn.center.DClass.prototype.setAttributes = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearAttributes = function() {
  return this.setAttributes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasAttributes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool isPart = 6;
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.getIspart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setIspart = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearIspart = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasIspart = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional common.ObjectsId childrenDClassId = 7;
 * @return {?proto.common.ObjectsId}
 */
proto.grpc.c60.nn.center.DClass.prototype.getChildrendclassid = function() {
  return /** @type{?proto.common.ObjectsId} */ (
    jspb.Message.getWrapperField(this, Common_pb.ObjectsId, 7));
};


/**
 * @param {?proto.common.ObjectsId|undefined} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
*/
proto.grpc.c60.nn.center.DClass.prototype.setChildrendclassid = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearChildrendclassid = function() {
  return this.setChildrendclassid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasChildrendclassid = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 parentDClassId = 8;
 * @return {number}
 */
proto.grpc.c60.nn.center.DClass.prototype.getParentdclassid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setParentdclassid = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearParentdclassid = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasParentdclassid = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string description = 9;
 * @return {string}
 */
proto.grpc.c60.nn.center.DClass.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.center.DClass} returns this
 */
proto.grpc.c60.nn.center.DClass.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.center.DClass.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.center.DClassList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.center.DClassList.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.center.DClassList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.center.DClassList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DClassList.toObject = function(includeInstance, msg) {
  var f, obj = {
    classesList: jspb.Message.toObjectList(msg.getClassesList(),
    proto.grpc.c60.nn.center.DClass.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.center.DClassList}
 */
proto.grpc.c60.nn.center.DClassList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.center.DClassList;
  return proto.grpc.c60.nn.center.DClassList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.center.DClassList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.center.DClassList}
 */
proto.grpc.c60.nn.center.DClassList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.center.DClass;
      reader.readMessage(value,proto.grpc.c60.nn.center.DClass.deserializeBinaryFromReader);
      msg.addClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.center.DClassList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.center.DClassList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.center.DClassList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.center.DClassList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.c60.nn.center.DClass.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DClass classes = 1;
 * @return {!Array<!proto.grpc.c60.nn.center.DClass>}
 */
proto.grpc.c60.nn.center.DClassList.prototype.getClassesList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.center.DClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.center.DClass, 1));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.center.DClass>} value
 * @return {!proto.grpc.c60.nn.center.DClassList} returns this
*/
proto.grpc.c60.nn.center.DClassList.prototype.setClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.c60.nn.center.DClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.center.DClass}
 */
proto.grpc.c60.nn.center.DClassList.prototype.addClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.c60.nn.center.DClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.center.DClassList} returns this
 */
proto.grpc.c60.nn.center.DClassList.prototype.clearClassesList = function() {
  return this.setClassesList([]);
};


/**
 * @enum {number}
 */
proto.grpc.c60.nn.center.DClassType = {
  NONECLASS: 0,
  RECTANGLE: 1,
  POLYGON: 2,
  POLYLINE: 3,
  POINT: 4,
  ELLIPSE: 5,
  CUBOID: 6,
  MASK: 7,
  TAG: 8
};

goog.object.extend(exports, proto.grpc.c60.nn.center);
