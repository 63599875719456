import React, { useEffect, useState } from 'react';
import {
    Row, Col, Collapse, Switch, Slider, InputNumber,
} from 'antd';

import { YoloAugmentationConfig } from 'proto/nn_training/DetectorTraining_pb';

const { Panel } = Collapse;

interface RotationProps {
    augmentationValues: YoloAugmentationConfig.AsObject | undefined;
    setAugmentationValues: React.Dispatch<React.SetStateAction<YoloAugmentationConfig.AsObject | undefined>>;
    defaultAugmentation: YoloAugmentationConfig.AsObject | undefined;
}

const Rotation: React.FC<RotationProps> = ({ augmentationValues, setAugmentationValues, defaultAugmentation }) => {
    const [checkedRotation, setCheckedRotation] = useState<boolean>(false);

    // Коррекция ввода значения в Input (Оставляем только цифры)
    const inputParser = (value: string | undefined): number => {
        const result = Number(value?.replace(/[^0-9.,]/g, '').replace(/[,]/g, '.')).toFixed(2);

        if (Number(result) === 0) {
            return 0;
        }

        if (Number(result) > 180) {
            return 180;
        }

        return Number(result);
    };

    useEffect(() => {
        if (augmentationValues && augmentationValues?.degrees > 0) {
            setCheckedRotation(true);
        } else {
            setCheckedRotation(false);
        }
    }, [augmentationValues?.degrees]);

    const setValue = (event: React.FocusEvent<HTMLInputElement, Element>) => {
        let newValue = Number(event.target.value);
        if (newValue > 180) newValue = 180;
        if (newValue < 0) newValue = 0;
        setAugmentationValues(prev => prev ? { ...prev, degrees: newValue } : undefined)
    };

    const switchCheckedRotation = (value: boolean) => {
        if (!value) {
            setAugmentationValues(prev => prev ? { ...prev, degrees: 0 } : undefined);
        } else {
            setAugmentationValues(prev => prev ? { ...prev, degrees: defaultAugmentation?.degrees || 0 } : undefined);
        }
    };

    return (
        <Col span={24}>
            <Collapse style={{ borderRadius: 6 }}>
                <Panel
                    style={{ alignItems: 'center', borderRadius: 6 }}
                    header={<div>Вращение</div>}
                    extra={(
                        <div onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                            <Switch
                                checked={checkedRotation}
                                onChange={switchCheckedRotation}
                            />
                        </div>
                    )}
                    showArrow={false}
                    key='Augment_2'
                >
                    <div>
                        Добавьте вращение, чтобы ваша модель была более устойчивой к
                        повороту камеры.
                    </div>
                    <Row gutter={[32, 12]} style={{ marginTop: 25 }}>
                        <Col span={24}>
                            <Row gutter={[32, 12]}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Вращение
                                    </div>
                                </Col>
                                <Col span={13}>
                                    <Slider
                                        marks={{ 0: '0°', 180: '±180°' }}
                                        min={0}
                                        max={180}
                                        step={1}
                                        value={augmentationValues?.degrees}
                                        onChange={(value: number) => setAugmentationValues(prev => prev ? { ...prev, degrees: value } : undefined)}
                                        tooltip={{
                                            formatter: (value?: number): string | undefined => `±${value}°`,
                                        }}
                                        trackStyle={{ background: '#368585', height: '5px' }}
                                        railStyle={{
                                            background: '#CECECE',
                                            borderRadius: '5px',
                                        }}
                                        handleStyle={{
                                            background: '#ffffff',
                                            border: 'solid 2px #384952',
                                            width: '18px',
                                            height: '18px',
                                            marginTop: '-7px',
                                        }}
                                    />
                                </Col>
                                <Col span={5}>
                                    <InputNumber
                                        value={augmentationValues?.degrees}
                                        onBlur={setValue}
                                        min={0}
                                        max={180}
                                        step={1}
                                        controls={false}
                                        addonAfter='°'
                                        parser={(value): number => inputParser(value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
    );
};

export default Rotation;
