// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import JobCard from './job-card';

function JobsContentComponent(): JSX.Element {
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const previews = useSelector((state: CombinedState) => state.jobs.previews);

    return (
        <div className='cvat-jobs-list-content'>
            <div className='cvat-jobs-page-list'>
                {jobs.map((job: any, idx: number): JSX.Element => (
                    <JobCard preview={previews[idx]} job={job} key={job.id} />
                ))}
            </div>
        </div>
    );
}

export default React.memo(JobsContentComponent);
