import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CalendarOutlined } from '@ant-design/icons';
import {
    Divider, InputNumber, Select, Button, DatePicker,
} from 'antd';
// import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

moment.locale('ru');

interface CustomDatePickerProps {
    timeIntervalField: number[] | undefined;
    setTimeIntervalField(value: number[] | undefined): void;
}

const CustomDatePicker = (props: CustomDatePickerProps): JSX.Element => {
    const { timeIntervalField, setTimeIntervalField } = props;

    const [firstRender, setFirstRender] = useState<boolean>(true);
    const [selectedDateValue, setSelectedDateValue] = useState<string | undefined>(undefined);
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
    const [selectOpen, setSelectOpen] = useState<boolean | undefined>(undefined);
    const [hourInputValue, setHourInputValue] = useState<number>(0);
    const [dayInputValue, setDayInputValue] = useState<number>(0);
    const [intervalStartInputValue, setIntervalStartInputValue] = useState<number | null>(null);
    const [intervalEndInputValue, setIntervalEndInputValue] = useState<number | null>(null);

    const declinationOfNumbers = (number: number, words: string[], cases = [2, 0, 1, 1, 1, 2]): string => words[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];

    const getNumberOfHoursText = (): string => {
        let value = hourInputValue;

        if (hourInputValue === null) {
            setHourInputValue(0);
            value = 0;
        }

        return `${declinationOfNumbers(value, ['Последний', 'Последние', 'Последние'])} ${value} ${declinationOfNumbers(
            value,
            ['час', 'часа', 'часов'],
        )}`;
    };

    // Последние N часов - Указываем значение в поле при вводе в Input
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            setSelectedDateValue(getNumberOfHoursText());
            setSelectedOption('numberOfHours');
        }
    }, [hourInputValue]);

    const getNumberOfDaysText = (): string => {
        let value = dayInputValue;

        if (hourInputValue === null) {
            setDayInputValue(0);
            value = 0;
        }

        return `${declinationOfNumbers(value, ['Последний', 'Последние', 'Последние'])} ${value} ${declinationOfNumbers(
            value,
            ['день', 'дня', 'суток'],
        )}`;
    };

    // Последние N часов - Указываем значение в поле при вводе в Input
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            setSelectedDateValue(getNumberOfDaysText());
            setSelectedOption('numberOfDays');
        }
    }, [dayInputValue]);

    const getIntervalText = (): void => {
        if (!intervalStartInputValue && !intervalEndInputValue) {
            setSelectedDateValue(undefined);
            setSelectedOption(undefined);
        }

        if (intervalStartInputValue && !intervalEndInputValue) {
            setSelectedDateValue(`Начиная с ${new Date(intervalStartInputValue).toLocaleDateString('ru-RU')}`);
            setSelectedOption('datePicker');
        }

        if (!intervalStartInputValue && intervalEndInputValue) {
            setSelectedDateValue(`До ${new Date(intervalEndInputValue).toLocaleDateString('ru-RU')}`);
            setSelectedOption('datePicker');
        }

        if (intervalStartInputValue && intervalEndInputValue) {
            // Если начальная дата позже конечной, меняем местами
            if (intervalStartInputValue > intervalEndInputValue) {
                setIntervalStartInputValue(intervalEndInputValue);
                setIntervalEndInputValue(intervalStartInputValue);
            }

            setSelectedDateValue(
                `${new Date(intervalStartInputValue).toLocaleDateString('ru-RU')} \xA0\xA0\xA0\xA0\xA0⇀\xA0 ${new Date(
                    intervalEndInputValue,
                ).toLocaleDateString('ru-RU')}`,
            );
            setSelectedOption('datePicker');
        }
    };

    // Интервал
    useEffect(() => getIntervalText(), [intervalStartInputValue, intervalEndInputValue]);

    // Заносим значения указанной даты в переменную
    useEffect(() => {
        // Если выбрано - Последний час
        if (selectedOption === 'lastHour') {
            setTimeIntervalField([Date.now() - 3600000, Date.now()]);
        }

        // Если выбрано - Последние сутки
        if (selectedOption === 'lastDay') {
            setTimeIntervalField([Date.now() - 86400000, Date.now()]);
        }

        // Если выбрано - Последняя неделя
        if (selectedOption === 'lastWeek') {
            setTimeIntervalField([Date.now() - 604800000, Date.now()]);
        }

        // Если выбрано - Последние N часов
        if (selectedOption === 'numberOfHours') {
            setTimeIntervalField([
                Date.now() - Number(hourInputValue) * 3600000,
                Date.now(),
            ]);
        }

        // Если выбрано - Последние N суток
        if (selectedOption === 'numberOfDays') {
            setTimeIntervalField([
                Date.now() - Number(dayInputValue) * 86400000,
                Date.now(),
            ]);
        }

        // Если выбрано - Интервал
        if (selectedOption === 'datePicker') {
            if (intervalStartInputValue && !intervalEndInputValue) {
                setTimeIntervalField([intervalStartInputValue, Date.now()]);
            }

            if (!intervalStartInputValue && intervalEndInputValue) {
                setTimeIntervalField([0, intervalEndInputValue]);
            }

            if (intervalStartInputValue && intervalEndInputValue) {
                setTimeIntervalField([intervalStartInputValue, intervalEndInputValue]);
            }
        }

        // Если ничего не выбрано
        if (!selectedOption) {
            setTimeIntervalField(undefined);
        }
    }, [selectedOption, hourInputValue, dayInputValue, intervalStartInputValue, intervalEndInputValue]);

    // Обработка кнопки Очистить все фильтры
    useEffect(() => {
        if (!timeIntervalField) {
            setSelectedDateValue(undefined);
            setSelectedOption(undefined);
            setSelectOpen(undefined);
            setHourInputValue(0);
            setDayInputValue(0);
            setIntervalStartInputValue(null);
            setIntervalEndInputValue(null);
        }
    }, [timeIntervalField]);

    return (
        <Select
            style={{ width: '100%' }}
            suffixIcon={<CalendarOutlined />}
            value={selectedDateValue}
            open={selectOpen}
            placeholder='Укажите Дату/Интервал'
            onDropdownVisibleChange={() => setSelectOpen(undefined)}
            dropdownRender={() => (
                <>
                    <div>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'lastHour' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                setSelectedDateValue('Последний час');
                                setSelectedOption('lastHour');
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            Последний час
                        </Button>
                    </div>
                    <div>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'lastDay' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                setSelectedDateValue('Последние сутки');
                                setSelectedOption('lastDay');
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            Последние сутки
                        </Button>
                    </div>
                    <div>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'lastWeek' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                setSelectedDateValue('Последняя неделя');
                                setSelectedOption('lastWeek');
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            Последняя неделя
                        </Button>
                    </div>

                    <Divider style={{ margin: '8px 0' }} />

                    <div>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'numberOfHours' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                setSelectedDateValue(getNumberOfHoursText());
                                setSelectedOption('numberOfHours');
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            {`${declinationOfNumbers(hourInputValue, ['Последний', 'Последние', 'Последние'])}:`}
                            <div
                                style={{ margin: '0 10px' }}
                                onClick={(event) => event.stopPropagation()}
                                aria-hidden='true'
                            >
                                <InputNumber
                                    value={hourInputValue}
                                    controls={false}
                                    onChange={(value) => {
                                        setHourInputValue(Number(String(value).replace(/[^0-9]/g, '')));
                                    }}
                                    onPressEnter={() => setSelectOpen(false)}
                                    formatter={(value) => String(value).replace(/[^0-9]/g, '')}
                                    precision={0}
                                    size='small'
                                    min={0}
                                />
                            </div>
                            {declinationOfNumbers(hourInputValue, ['час', 'часа', 'часов'])}
                        </Button>
                    </div>
                    <div>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'numberOfDays' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                setSelectedDateValue(getNumberOfDaysText());
                                setSelectedOption('numberOfDays');
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            {`${declinationOfNumbers(dayInputValue, ['Последний', 'Последние', 'Последние'])}:`}
                            <div
                                style={{ margin: '0 10px' }}
                                onClick={(event) => event.stopPropagation()}
                                aria-hidden='true'
                            >
                                <InputNumber
                                    value={dayInputValue}
                                    controls={false}
                                    onChange={(value) => {
                                        setDayInputValue(Number(String(value).replace(/[^0-9]/g, '')));
                                    }}
                                    onPressEnter={() => setSelectOpen(false)}
                                    formatter={(value) => String(value).replace(/[^0-9]/g, '')}
                                    precision={0}
                                    size='small'
                                    min={0}
                                />
                            </div>
                            {declinationOfNumbers(dayInputValue, ['день', 'дня', 'суток'])}
                        </Button>
                    </div>

                    <Divider style={{ margin: '8px 0' }} />

                    <div
                        onMouseDown={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                        }}
                        aria-hidden='true'
                    >
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */}
                        <label style={{ marginLeft: 15 }}>Интервал:</label>
                        <Button
                            className={`button-option-date-select${
                                selectedOption === 'datePicker' ? ' selected-custom-option' : ''
                            }`}
                            onClick={() => {
                                getIntervalText();
                                setSelectOpen(false);
                            }}
                            type='text'
                        >
                            <div>
                                <div className='button-option-date-range' style={{ marginBottom: 10 }}>
                                    <div style={{ width: 75, display: 'flex' }}>Начало:</div>
                                    <div style={{ margin: '0 8px' }} onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                                        <DatePicker
                                            locale={locale}
                                            value={
                                                intervalStartInputValue ?
                                                    moment(
                                                        new Date(intervalStartInputValue).toLocaleDateString('ru-RU'),
                                                        'DD.MM.YYYY',
                                                    ) :
                                                    null
                                            }
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    const val = value.toDate();
                                                    const newDate = new Date(
                                                        `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`,
                                                    );
                                                    setIntervalStartInputValue(newDate.getTime());
                                                } else {
                                                    setIntervalStartInputValue(null);
                                                }
                                            }}
                                            format='DD.MM.YYYY'
                                            size='small'
                                        />
                                    </div>
                                </div>
                                <div className='button-option-date-range'>
                                    <div style={{ width: 75, display: 'flex' }}>Конец:</div>
                                    <div style={{ margin: '0 8px' }} onClick={(event) => event.stopPropagation()} aria-hidden='true'>
                                        <DatePicker
                                            locale={locale}
                                            value={
                                                intervalEndInputValue ?
                                                    moment(
                                                        new Date(intervalEndInputValue).toLocaleDateString('ru-RU'),
                                                        'DD.MM.YYYY',
                                                    ) :
                                                    null
                                            }
                                            onChange={(value) => {
                                                if (value !== null) {
                                                    const val = value.toDate();
                                                    const newDate = new Date(
                                                        `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`,
                                                    );
                                                    setIntervalEndInputValue(newDate.getTime());
                                                } else {
                                                    setIntervalEndInputValue(null);
                                                }
                                            }}
                                            format='DD.MM.YYYY'
                                            size='small'
                                        />
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                </>
            )}
        />
    );
};

export default CustomDatePicker;
