import React, { useState, useEffect } from 'react';
import {
    Col, Row, Slider, Statistic, Switch, message
} from 'antd';

import { createEnhancedClient } from 'utils/grpc';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset, MergeDatasets, DatasetPartition } from 'proto/c60/nn/center/DataSets_pb';
import { ObjectsId } from 'proto/Common_pb';

import { DatasetsTableDataType } from './datasets-page';

interface RangeSliderProps {
    datasetsServiceclient: DatasetsServiceClient | undefined;
    datasets: DatasetsTableDataType[] | undefined;
    setMergeDatasets: React.Dispatch<React.SetStateAction<MergeDatasets.AsObject>>;
    open: boolean;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ datasetsServiceclient, datasets, setMergeDatasets, open }) => {
    // Информация по датасетам
    const [datasetsInfo, setDatasetsInfo] = useState<Dataset[]>();
    const [isDatasetsLoading, setIsDatasetsLoading] = useState(false);
    // Общее количество изображений в Проекте/Задаче
    const [summaryImagesCount, setSummaryImagesCount] = useState<number>(0);
    // Общее количество изображений в Выборке Train (Обучающая)
    const [trainSubsetCount, setTrainSubsetCount] = useState<number>(0);
    // Общее количество изображений в Выборке Validation (Контрольная)
    const [validationSubsetCount, setValidationSubsetCount] = useState<number>(0);
    // Общее количество изображений в Выборке Test (Тестовая)
    const [testSubsetCount, setTestSubsetCount] = useState<number>(0);
    // Начальное значение ползунков в Выборке
    const [defaultSubsetCount, setDefaultSubsetCount] = useState<[number, number] | undefined>();
    // Отрисовка зеленой полосы слайдера Выборки Train (Обучающая)
    const [railColor, setRailColor] = useState<string>('0');
    // Switch выборки - Использовать собственные настройки выборки
    const [customSubset, setCustomSubset] = useState<boolean>(false);

    // Вывод в Tooltip значений количества изображений в выборках
    const formatter = (value: number | undefined): number | undefined => {
        if (value) {
            return value === trainSubsetCount ? value : value - trainSubsetCount;
        }

        return undefined;
    };

    const getDatasetsInfo = async () => {
        if (datasetsServiceclient && datasets?.length && open) {
            setIsDatasetsLoading(true);

            try {
                const ids = new ObjectsId().setIdsList(datasets.map(dataset => Number(dataset.key)));
                const info = await datasetsServiceclient.getInfo(ids, {});
                setDatasetsInfo(info.getListList());

                // Вычисляем общее количество изображений в Проекте/Задаче
                const imageCount = info.getListList().reduce(
                    (accumulator, dataset) => accumulator + dataset.getTrainsetimages() + dataset.getValidationsetimages() + dataset.getTestsetimages(),
                    0,
                );
                setSummaryImagesCount(imageCount);

                // Вычисляем общее количество изображений в Выборке Train (Обучающая)
                const trainCount = info.getListList().reduce(
                    (accumulator, dataset) => accumulator + dataset.getTrainsetimages(),
                    0,
                );
                setTrainSubsetCount(trainCount);

                // Вычисляем общее количество изображений в Выборке Validation (Контрольная)
                const validationCount = info.getListList().reduce(
                    (accumulator, dataset) => accumulator + dataset.getValidationsetimages(),
                    0,
                );
                setValidationSubsetCount(validationCount);

                // Вычисляем общее количество изображений в Выборке Test (Тестовая)
                const testCount = info.getListList().reduce(
                    (accumulator, dataset) => accumulator + dataset.getTestsetimages(),
                    0,
                );
                setTestSubsetCount(testCount);

                setDefaultSubsetCount([trainCount, trainCount + validationCount]);
            } catch(err) {
                if (err instanceof Error) {
                    message.error(`Ошибка получения датасетов: ${err.message}`);
                    console.error(err.message);
                }
            } finally {
                setIsDatasetsLoading(false);
            }
        }
    };

    useEffect(() => {
        getDatasetsInfo();
    }, [datasetsServiceclient, datasets, open]);

    // Вычисляем процент заполнения Слайдера зеленым цветом Выборки Train (Обучающая)
    useEffect(() => setRailColor(((100 / summaryImagesCount) * trainSubsetCount).toFixed()), [trainSubsetCount]);

    // При изменении значения Switch выборки - Подставляем в слайдер значения из проекта
    useEffect(() => {
        if (defaultSubsetCount) {
            setTrainSubsetCount(defaultSubsetCount[0]);
            setValidationSubsetCount(defaultSubsetCount[1] - defaultSubsetCount[0]);
        }
    }, [customSubset]);

    useEffect(() => {
        if (!customSubset) {
            // setMergeDatasets(prev => ({ ...prev, partition: undefined }));
            const trainsetimages = defaultSubsetCount?.length ? defaultSubsetCount[0] : undefined;
            const validationsetimages = defaultSubsetCount?.length ? defaultSubsetCount[1] : undefined;
            const testsetimages = defaultSubsetCount?.length && summaryImagesCount ? summaryImagesCount - defaultSubsetCount[0] - defaultSubsetCount[1] : undefined;
            setMergeDatasets(prev => ({ ...prev, datasetconfig: { ...prev.datasetconfig, trainsetimages, validationsetimages, testsetimages } }));
        } else {
            // setMergeDatasets(prev => ({...prev, partition: new DatasetPartition().setTrainpart(trainSubsetCount).setValidationpart(validationSubsetCount).toObject() }));
            setMergeDatasets(prev => ({ ...prev, datasetconfig: { ...prev.datasetconfig, trainsetimages: trainSubsetCount, validationsetimages: validationSubsetCount, testsetimages: testSubsetCount } }));
        }
    }, [trainSubsetCount, validationSubsetCount, testSubsetCount, customSubset]);

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Switch
                    checked={customSubset}
                    onChange={(value) => setCustomSubset(value)}
                    checkedChildren='УКАЗАНИЕ ЗНАЧЕНИЙ'
                    unCheckedChildren='ПО УМОЛЧАНИЮ'
                />
            </Col>
            <Col span={24}>
                {summaryImagesCount ? (
                    <Slider
                        range={{ draggableTrack: true }}
                        value={[trainSubsetCount, trainSubsetCount + validationSubsetCount]}
                        onChange={(value: number[]) => {
                            setRailColor(((100 / summaryImagesCount) * value[0]).toFixed());
                            setTrainSubsetCount(value[0]);
                            setValidationSubsetCount(value[1] - value[0]);
                            setTestSubsetCount(summaryImagesCount - value[1]);
                        }}
                        min={0}
                        max={summaryImagesCount}
                        tooltip={{ formatter }}
                        trackStyle={[{ background: '#384952', height: '7px' }]}
                        railStyle={{
                            background: `linear-gradient(to right, #368585 ${railColor}%, #CECECE 0%)`,
                            borderRadius: '5px',
                            height: 7,
                        }}
                        handleStyle={[
                            {
                                background: '#ffffff',
                                border: 'solid 2px #384952',
                                width: '20px',
                                height: '20px',
                                marginTop: '-7px',
                            },
                            {
                                background: '#ffffff',
                                border: 'solid 2px #384952',
                                width: '20px',
                                height: '20px',
                                marginTop: '-7px',
                            },
                        ]}
                        disabled={!customSubset}
                    />
                ) : (<div style={{ fontWeight: 600 }}>В проекте нет ни одного изображения</div>) }
            </Col>
            <Col span={8}>
                <Statistic title='Обучающая' value={trainSubsetCount} />
            </Col>
            <Col span={8}>
                <Statistic title='Контрольная' value={validationSubsetCount} />
            </Col>
            <Col span={8}>
                <Statistic title='Тестовая' value={testSubsetCount} />
            </Col>
        </Row>
    );
};

export default RangeSlider;
