import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { getClientHost } from 'shared/constants';
import { DatasetsServiceClient } from 'proto/c60/nn/center/DataSetsServiceClientPb';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { StringSearch, ObjectsId } from 'proto/Common_pb';
import { DClass } from 'proto/c60/nn/center/DatasetClasses_pb';

import { Row, Col, Spin } from 'antd';
import { createEnhancedClient } from '../../utils/grpc';

import DatasetTopBar from './top-bar';
import DatasetAboutPage from './dataset-about-page';
import DatasetSubsetPage from './dataset-subset-page';

export interface ClassesList {
    id: number;
    title: string;
    color: string
}

export interface ClassesListForFilter {
    title: string;
    value: number;
    children?: ClassesListForFilter[];
    color?: string;
}

const DatasetPage = (): JSX.Element => {
    const location = useLocation();

    // ID текущего Датасета
    const [datasetID, setDatasetID] = useState<number[] | undefined>(undefined);
    // Информация о текущем датасете из gRPC
    const [datasetData, setDatasetData] = useState<Dataset.AsObject | undefined>(undefined);
    // ID всех существующих классов
    const [classesIds, setClassesIds] = useState<number[] | undefined>(undefined);
    // Сформированный массив классов - type ClassesList
    const [classesInfo, setClassesInfo] = useState<ClassesList[] | undefined>(undefined);

    const [aboutPageSpan, setAboutPageSpan] = useState(12);
    const [subsetPageSpan, setSubsetPageSpan] = useState(12);

    const currentHost = getClientHost();

    //! Получаем информацию по датасету
    const getDatasetInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DatasetsServiceClient);
            const dataset = await client.getInfo(new ObjectsId().setIdsList(datasetID || []), {});
            const paramList = dataset.toObject();
            setDatasetData(paramList.listList[0]);
        }
    };

    // Формируем объект Классов
    const getClassesBase = (classesList: DClass.AsObject[]): void => {
        const getClasses = classesList.map((element) => {
            const current = {
                id: element.id,
                title: element.title,
                color: element.color?.hexrgb,
            } as ClassesList;

            return current;
        });

        setClassesInfo(getClasses);
    };

    //! Получаем список ID существующих Классов
    const getClassesIds: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const classes = await client.getAll(new StringSearch().setText(''), {});
            setClassesIds(classes.getIdsList().sort((a, b) => a - b));
        }
    };

    //! Получаем информацию по существующим классам
    const getClassesInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const classes = await client.getInfo(new ObjectsId().setIdsList(classesIds || []), {});
            const classesList = classes.toObject();
            getClassesBase(classesList.classesList);
        }
    };

    // Получаем ID датасета и Список ID всех классов - gRPC
    useEffect(() => {
        setDatasetID([Number(new URLSearchParams(location.search).get('info'))]);
        getClassesIds();
    }, []);

    // Если получен ID датасета - Получаем информацию по датасету - gRPC
    useEffect(() => {
        if (datasetID) {
            getDatasetInfo();
        }
    }, [datasetID]);

    // Если получены ID классов - Получаем информацию по существующим классам - gRPC
    useEffect(() => {
        if (classesIds) {
            getClassesInfo();
        }
    }, [classesIds]);

    useEffect(() => {
        const windowWidth = window.innerWidth;
        if (windowWidth > 2048) {
            const aboutDesiredWidth = 850;
            const spinContainerWidth = windowWidth - 406;
            const aboutSpan = Math.round((aboutDesiredWidth / spinContainerWidth) * 24);
            setAboutPageSpan(aboutSpan);
            setSubsetPageSpan(24 - aboutSpan);
        }
    }, []);

    return (
        <div className='cvat-projects-page'>
            <DatasetTopBar datasetID={datasetID} datasetData={datasetData} />
            <Spin spinning={!datasetData} tip='Загрузка...'>
                <Row gutter={16}>
                    <Col span={aboutPageSpan}>
                        <div className='dataset-paje-blocks'>
                            <DatasetAboutPage
                                datasetData={datasetData}
                                classesInfo={classesInfo}
                            />
                        </div>
                    </Col>
                    <Col span={subsetPageSpan}>
                        <div className='dataset-paje-blocks'>
                            <DatasetSubsetPage
                                datasetID={datasetID}
                                datasetData={datasetData}
                                classesInfo={classesInfo}
                            />
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default DatasetPage;
