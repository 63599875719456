import md5 from 'md5';
import { getClientHost } from 'shared/constants';

type GetClient = <T extends Record<string, any>>(type: { new(host: string): T }) => T;

const clients: Record<string, any> = {};

const getClient: GetClient = (Type) => {
    const currentHost = getClientHost();

    if (!currentHost) console.error('Couldn\'t find host');
    const key = md5(Type.toString());

    if (!(key in clients)) {
        clients[key] = new Type(currentHost!);
    }

    return clients[key];
};

const setupMetadataInjector = (custom: object, client: Record<string, (...args: any[]) => void>) => {
    for (const property of Object.getOwnPropertyNames(Object.getPrototypeOf(client))) {
        if (property === 'constructor') {
            continue;
        }

        const base = client[property];

        if (typeof base !== 'function') {
            continue;
        }

        client[property] = function (request: unknown, metadata: Record<string, string>, callback?: () => void) {
            return base.call(this, request, { ...custom, ...metadata }, callback);
        };
    }
};

export const createEnhancedClient: GetClient = <T extends Record<string, any>>(type: { new(host: string): T }): T => {
    const instance = getClient(type);
    const token = window.localStorage.getItem('token')?.slice(1, -1);
    if (!token) return instance;

    setupMetadataInjector({ Authorization: `Token ${token}` }, instance);

    return instance;
};
