// source: nn_training/DetectorTraining.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.grpc.c60.nn.training.ArchitectureDescriptor', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.EmptyArg', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.FileURL', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.ImageSize', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.InputChannel', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.InputDescriptor', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.NetworkSize', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.ProgressTelemetry', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.ProgressTelemetry.ResultCase', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.Status', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.TrainOptimizer', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.TrainParameters', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.TrainSettings', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.TrainingStop', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.VisualSensor', null, global);
goog.exportSymbol('proto.grpc.c60.nn.training.YoloAugmentationConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.ImageSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.ImageSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.ImageSize.displayName = 'proto.grpc.c60.nn.training.ImageSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.InputDescriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.c60.nn.training.InputDescriptor.repeatedFields_, null);
};
goog.inherits(proto.grpc.c60.nn.training.InputDescriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.InputDescriptor.displayName = 'proto.grpc.c60.nn.training.InputDescriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.InputChannel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.InputChannel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.InputChannel.displayName = 'proto.grpc.c60.nn.training.InputChannel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.ArchitectureDescriptor, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.ArchitectureDescriptor.displayName = 'proto.grpc.c60.nn.training.ArchitectureDescriptor';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.FileURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.FileURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.FileURL.displayName = 'proto.grpc.c60.nn.training.FileURL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.YoloAugmentationConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.YoloAugmentationConfig.displayName = 'proto.grpc.c60.nn.training.YoloAugmentationConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.TrainParameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.TrainParameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.TrainParameters.displayName = 'proto.grpc.c60.nn.training.TrainParameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.TrainSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.TrainSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.TrainSettings.displayName = 'proto.grpc.c60.nn.training.TrainSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.ProgressTelemetry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_);
};
goog.inherits(proto.grpc.c60.nn.training.ProgressTelemetry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.ProgressTelemetry.displayName = 'proto.grpc.c60.nn.training.ProgressTelemetry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.TrainingStop = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.TrainingStop, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.TrainingStop.displayName = 'proto.grpc.c60.nn.training.TrainingStop';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.EmptyArg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.EmptyArg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.EmptyArg.displayName = 'proto.grpc.c60.nn.training.EmptyArg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.c60.nn.training.Status = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.c60.nn.training.Status, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.c60.nn.training.Status.displayName = 'proto.grpc.c60.nn.training.Status';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.ImageSize.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.ImageSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.ImageSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ImageSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    width: jspb.Message.getFieldWithDefault(msg, 1, 0),
    height: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.ImageSize}
 */
proto.grpc.c60.nn.training.ImageSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.ImageSize;
  return proto.grpc.c60.nn.training.ImageSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.ImageSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.ImageSize}
 */
proto.grpc.c60.nn.training.ImageSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWidth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.ImageSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.ImageSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.ImageSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ImageSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWidth();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHeight();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 width = 1;
 * @return {number}
 */
proto.grpc.c60.nn.training.ImageSize.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ImageSize} returns this
 */
proto.grpc.c60.nn.training.ImageSize.prototype.setWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 height = 2;
 * @return {number}
 */
proto.grpc.c60.nn.training.ImageSize.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ImageSize} returns this
 */
proto.grpc.c60.nn.training.ImageSize.prototype.setHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.c60.nn.training.InputDescriptor.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.InputDescriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.InputDescriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.InputDescriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    size: (f = msg.getSize()) && proto.grpc.c60.nn.training.ImageSize.toObject(includeInstance, f),
    channelsList: jspb.Message.toObjectList(msg.getChannelsList(),
    proto.grpc.c60.nn.training.InputChannel.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.InputDescriptor}
 */
proto.grpc.c60.nn.training.InputDescriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.InputDescriptor;
  return proto.grpc.c60.nn.training.InputDescriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.InputDescriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.InputDescriptor}
 */
proto.grpc.c60.nn.training.InputDescriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.training.ImageSize;
      reader.readMessage(value,proto.grpc.c60.nn.training.ImageSize.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    case 3:
      var value = new proto.grpc.c60.nn.training.InputChannel;
      reader.readMessage(value,proto.grpc.c60.nn.training.InputChannel.deserializeBinaryFromReader);
      msg.addChannels(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.InputDescriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.InputDescriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.InputDescriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.c60.nn.training.ImageSize.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.grpc.c60.nn.training.InputChannel.serializeBinaryToWriter
    );
  }
};


/**
 * optional ImageSize size = 1;
 * @return {?proto.grpc.c60.nn.training.ImageSize}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.getSize = function() {
  return /** @type{?proto.grpc.c60.nn.training.ImageSize} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.ImageSize, 1));
};


/**
 * @param {?proto.grpc.c60.nn.training.ImageSize|undefined} value
 * @return {!proto.grpc.c60.nn.training.InputDescriptor} returns this
*/
proto.grpc.c60.nn.training.InputDescriptor.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.InputDescriptor} returns this
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.hasSize = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated InputChannel channels = 3;
 * @return {!Array<!proto.grpc.c60.nn.training.InputChannel>}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.getChannelsList = function() {
  return /** @type{!Array<!proto.grpc.c60.nn.training.InputChannel>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.c60.nn.training.InputChannel, 3));
};


/**
 * @param {!Array<!proto.grpc.c60.nn.training.InputChannel>} value
 * @return {!proto.grpc.c60.nn.training.InputDescriptor} returns this
*/
proto.grpc.c60.nn.training.InputDescriptor.prototype.setChannelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.grpc.c60.nn.training.InputChannel=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.c60.nn.training.InputChannel}
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.addChannels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.grpc.c60.nn.training.InputChannel, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.c60.nn.training.InputDescriptor} returns this
 */
proto.grpc.c60.nn.training.InputDescriptor.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.InputChannel.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.InputChannel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.InputChannel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.InputChannel.toObject = function(includeInstance, msg) {
  var f, obj = {
    sensor: jspb.Message.getFieldWithDefault(msg, 1, 0),
    size: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.InputChannel}
 */
proto.grpc.c60.nn.training.InputChannel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.InputChannel;
  return proto.grpc.c60.nn.training.InputChannel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.InputChannel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.InputChannel}
 */
proto.grpc.c60.nn.training.InputChannel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.grpc.c60.nn.training.VisualSensor} */ (reader.readEnum());
      msg.setSensor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.InputChannel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.InputChannel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.InputChannel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.InputChannel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSensor();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional VisualSensor sensor = 1;
 * @return {!proto.grpc.c60.nn.training.VisualSensor}
 */
proto.grpc.c60.nn.training.InputChannel.prototype.getSensor = function() {
  return /** @type {!proto.grpc.c60.nn.training.VisualSensor} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.grpc.c60.nn.training.VisualSensor} value
 * @return {!proto.grpc.c60.nn.training.InputChannel} returns this
 */
proto.grpc.c60.nn.training.InputChannel.prototype.setSensor = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 size = 2;
 * @return {number}
 */
proto.grpc.c60.nn.training.InputChannel.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.InputChannel} returns this
 */
proto.grpc.c60.nn.training.InputChannel.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.ArchitectureDescriptor.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.ArchitectureDescriptor} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.toObject = function(includeInstance, msg) {
  var f, obj = {
    input: (f = msg.getInput()) && proto.grpc.c60.nn.training.InputDescriptor.toObject(includeInstance, f),
    networksize: jspb.Message.getFieldWithDefault(msg, 2, 0),
    classescount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.ArchitectureDescriptor;
  return proto.grpc.c60.nn.training.ArchitectureDescriptor.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.ArchitectureDescriptor} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.c60.nn.training.InputDescriptor;
      reader.readMessage(value,proto.grpc.c60.nn.training.InputDescriptor.deserializeBinaryFromReader);
      msg.setInput(value);
      break;
    case 2:
      var value = /** @type {!proto.grpc.c60.nn.training.NetworkSize} */ (reader.readEnum());
      msg.setNetworksize(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setClassescount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.ArchitectureDescriptor.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.ArchitectureDescriptor} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInput();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.c60.nn.training.InputDescriptor.serializeBinaryToWriter
    );
  }
  f = message.getNetworksize();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getClassescount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional InputDescriptor input = 1;
 * @return {?proto.grpc.c60.nn.training.InputDescriptor}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.getInput = function() {
  return /** @type{?proto.grpc.c60.nn.training.InputDescriptor} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.InputDescriptor, 1));
};


/**
 * @param {?proto.grpc.c60.nn.training.InputDescriptor|undefined} value
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor} returns this
*/
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.setInput = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor} returns this
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.clearInput = function() {
  return this.setInput(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.hasInput = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional NetworkSize networkSize = 2;
 * @return {!proto.grpc.c60.nn.training.NetworkSize}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.getNetworksize = function() {
  return /** @type {!proto.grpc.c60.nn.training.NetworkSize} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grpc.c60.nn.training.NetworkSize} value
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor} returns this
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.setNetworksize = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional int32 classesCount = 3;
 * @return {number}
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.getClassescount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ArchitectureDescriptor} returns this
 */
proto.grpc.c60.nn.training.ArchitectureDescriptor.prototype.setClassescount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.FileURL.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.FileURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.FileURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.FileURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.FileURL}
 */
proto.grpc.c60.nn.training.FileURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.FileURL;
  return proto.grpc.c60.nn.training.FileURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.FileURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.FileURL}
 */
proto.grpc.c60.nn.training.FileURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.FileURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.FileURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.FileURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.FileURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.grpc.c60.nn.training.FileURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.FileURL} returns this
 */
proto.grpc.c60.nn.training.FileURL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.YoloAugmentationConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.YoloAugmentationConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    hsvH: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    hsvS: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    hsvV: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    degrees: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    translate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    scale: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    shear: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    perspective: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    flipud: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    fliplr: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    mosaic: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    mixup: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    copyPaste: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.YoloAugmentationConfig;
  return proto.grpc.c60.nn.training.YoloAugmentationConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.YoloAugmentationConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHsvH(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHsvS(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setHsvV(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDegrees(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTranslate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScale(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setShear(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPerspective(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFlipud(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFliplr(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMosaic(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMixup(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCopyPaste(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.YoloAugmentationConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.YoloAugmentationConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHsvH();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getHsvS();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getHsvV();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getDegrees();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getTranslate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getScale();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getShear();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getPerspective();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getFlipud();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getFliplr();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMosaic();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getMixup();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getCopyPaste();
  if (f !== 0.0) {
    writer.writeFloat(
      13,
      f
    );
  }
};


/**
 * optional float hsv_h = 1;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getHsvH = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setHsvH = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float hsv_s = 2;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getHsvS = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setHsvS = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float hsv_v = 3;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getHsvV = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setHsvV = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float degrees = 4;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getDegrees = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setDegrees = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float translate = 5;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getTranslate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setTranslate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float scale = 6;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getScale = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setScale = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float shear = 7;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getShear = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setShear = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float perspective = 8;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getPerspective = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setPerspective = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float flipud = 9;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getFlipud = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setFlipud = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float fliplr = 10;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getFliplr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setFliplr = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float mosaic = 11;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getMosaic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setMosaic = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float mixup = 12;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getMixup = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setMixup = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional float copy_paste = 13;
 * @return {number}
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.getCopyPaste = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.YoloAugmentationConfig} returns this
 */
proto.grpc.c60.nn.training.YoloAugmentationConfig.prototype.setCopyPaste = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.TrainParameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.TrainParameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainParameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryid: jspb.Message.getFieldWithDefault(msg, 9, 0),
    architecture: (f = msg.getArchitecture()) && proto.grpc.c60.nn.training.ArchitectureDescriptor.toObject(includeInstance, f),
    previousmodel: (f = msg.getPreviousmodel()) && proto.grpc.c60.nn.training.FileURL.toObject(includeInstance, f),
    yolozipdataset: (f = msg.getYolozipdataset()) && proto.grpc.c60.nn.training.FileURL.toObject(includeInstance, f),
    augmentation: (f = msg.getAugmentation()) && proto.grpc.c60.nn.training.YoloAugmentationConfig.toObject(includeInstance, f),
    datasetimgsize: (f = msg.getDatasetimgsize()) && proto.grpc.c60.nn.training.ImageSize.toObject(includeInstance, f),
    projectname: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    settings: (f = msg.getSettings()) && proto.grpc.c60.nn.training.TrainSettings.toObject(includeInstance, f),
    prevepochs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    prevtraintimems: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.TrainParameters}
 */
proto.grpc.c60.nn.training.TrainParameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.TrainParameters;
  return proto.grpc.c60.nn.training.TrainParameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.TrainParameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.TrainParameters}
 */
proto.grpc.c60.nn.training.TrainParameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQueryid(value);
      break;
    case 1:
      var value = new proto.grpc.c60.nn.training.ArchitectureDescriptor;
      reader.readMessage(value,proto.grpc.c60.nn.training.ArchitectureDescriptor.deserializeBinaryFromReader);
      msg.setArchitecture(value);
      break;
    case 2:
      var value = new proto.grpc.c60.nn.training.FileURL;
      reader.readMessage(value,proto.grpc.c60.nn.training.FileURL.deserializeBinaryFromReader);
      msg.setPreviousmodel(value);
      break;
    case 3:
      var value = new proto.grpc.c60.nn.training.FileURL;
      reader.readMessage(value,proto.grpc.c60.nn.training.FileURL.deserializeBinaryFromReader);
      msg.setYolozipdataset(value);
      break;
    case 4:
      var value = new proto.grpc.c60.nn.training.YoloAugmentationConfig;
      reader.readMessage(value,proto.grpc.c60.nn.training.YoloAugmentationConfig.deserializeBinaryFromReader);
      msg.setAugmentation(value);
      break;
    case 5:
      var value = new proto.grpc.c60.nn.training.ImageSize;
      reader.readMessage(value,proto.grpc.c60.nn.training.ImageSize.deserializeBinaryFromReader);
      msg.setDatasetimgsize(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectname(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = new proto.grpc.c60.nn.training.TrainSettings;
      reader.readMessage(value,proto.grpc.c60.nn.training.TrainSettings.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrevepochs(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrevtraintimems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.TrainParameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.TrainParameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainParameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryid();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getArchitecture();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.c60.nn.training.ArchitectureDescriptor.serializeBinaryToWriter
    );
  }
  f = message.getPreviousmodel();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.c60.nn.training.FileURL.serializeBinaryToWriter
    );
  }
  f = message.getYolozipdataset();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.c60.nn.training.FileURL.serializeBinaryToWriter
    );
  }
  f = message.getAugmentation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpc.c60.nn.training.YoloAugmentationConfig.serializeBinaryToWriter
    );
  }
  f = message.getDatasetimgsize();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpc.c60.nn.training.ImageSize.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.grpc.c60.nn.training.TrainSettings.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int32 queryId = 9;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getQueryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.setQueryid = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional ArchitectureDescriptor architecture = 1;
 * @return {?proto.grpc.c60.nn.training.ArchitectureDescriptor}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getArchitecture = function() {
  return /** @type{?proto.grpc.c60.nn.training.ArchitectureDescriptor} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.ArchitectureDescriptor, 1));
};


/**
 * @param {?proto.grpc.c60.nn.training.ArchitectureDescriptor|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setArchitecture = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearArchitecture = function() {
  return this.setArchitecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasArchitecture = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FileURL previousModel = 2;
 * @return {?proto.grpc.c60.nn.training.FileURL}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getPreviousmodel = function() {
  return /** @type{?proto.grpc.c60.nn.training.FileURL} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.FileURL, 2));
};


/**
 * @param {?proto.grpc.c60.nn.training.FileURL|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setPreviousmodel = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearPreviousmodel = function() {
  return this.setPreviousmodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasPreviousmodel = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional FileURL yoloZipDataset = 3;
 * @return {?proto.grpc.c60.nn.training.FileURL}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getYolozipdataset = function() {
  return /** @type{?proto.grpc.c60.nn.training.FileURL} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.FileURL, 3));
};


/**
 * @param {?proto.grpc.c60.nn.training.FileURL|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setYolozipdataset = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearYolozipdataset = function() {
  return this.setYolozipdataset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasYolozipdataset = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional YoloAugmentationConfig augmentation = 4;
 * @return {?proto.grpc.c60.nn.training.YoloAugmentationConfig}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getAugmentation = function() {
  return /** @type{?proto.grpc.c60.nn.training.YoloAugmentationConfig} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.YoloAugmentationConfig, 4));
};


/**
 * @param {?proto.grpc.c60.nn.training.YoloAugmentationConfig|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setAugmentation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearAugmentation = function() {
  return this.setAugmentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasAugmentation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ImageSize datasetImgSize = 5;
 * @return {?proto.grpc.c60.nn.training.ImageSize}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getDatasetimgsize = function() {
  return /** @type{?proto.grpc.c60.nn.training.ImageSize} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.ImageSize, 5));
};


/**
 * @param {?proto.grpc.c60.nn.training.ImageSize|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setDatasetimgsize = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearDatasetimgsize = function() {
  return this.setDatasetimgsize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasDatasetimgsize = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string projectName = 6;
 * @return {string}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getProjectname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.setProjectname = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearProjectname = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasProjectname = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.setName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional TrainSettings settings = 8;
 * @return {?proto.grpc.c60.nn.training.TrainSettings}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getSettings = function() {
  return /** @type{?proto.grpc.c60.nn.training.TrainSettings} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.TrainSettings, 8));
};


/**
 * @param {?proto.grpc.c60.nn.training.TrainSettings|undefined} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
*/
proto.grpc.c60.nn.training.TrainParameters.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int32 prevEpochs = 10;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getPrevepochs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.setPrevepochs = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearPrevepochs = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasPrevepochs = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int64 prevTrainTimeMS = 11;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.getPrevtraintimems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.setPrevtraintimems = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainParameters} returns this
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.clearPrevtraintimems = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainParameters.prototype.hasPrevtraintimems = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.TrainSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.TrainSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    epochs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    patience: jspb.Message.getFieldWithDefault(msg, 2, 0),
    batch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    saveimgproportions: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    freeze: jspb.Message.getFieldWithDefault(msg, 5, 0),
    boxgain: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    classgain: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    dflgain: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    weightdecay: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    learnbeginrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    learnfinalrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    amp: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    optimizer: jspb.Message.getFieldWithDefault(msg, 13, 0),
    deterministic: jspb.Message.getBooleanFieldWithDefault(msg, 14, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.TrainSettings}
 */
proto.grpc.c60.nn.training.TrainSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.TrainSettings;
  return proto.grpc.c60.nn.training.TrainSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.TrainSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.TrainSettings}
 */
proto.grpc.c60.nn.training.TrainSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEpochs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPatience(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBatch(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSaveimgproportions(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFreeze(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBoxgain(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClassgain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDflgain(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setWeightdecay(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLearnbeginrate(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLearnfinalrate(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAmp(value);
      break;
    case 13:
      var value = /** @type {!proto.grpc.c60.nn.training.TrainOptimizer} */ (reader.readEnum());
      msg.setOptimizer(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeterministic(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.TrainSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.TrainSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSaveimgproportions();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getAmp();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {!proto.grpc.c60.nn.training.TrainOptimizer} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeEnum(
      13,
      f
    );
  }
  f = message.getDeterministic();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional int32 epochs = 1;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getEpochs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setEpochs = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearEpochs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasEpochs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 patience = 2;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getPatience = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setPatience = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearPatience = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasPatience = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 batch = 3;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getBatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setBatch = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearBatch = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasBatch = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool saveImgProportions = 4;
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getSaveimgproportions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setSaveimgproportions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 freeze = 5;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getFreeze = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setFreeze = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearFreeze = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasFreeze = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float boxGain = 6;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getBoxgain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setBoxgain = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearBoxgain = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasBoxgain = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional float classGain = 7;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getClassgain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setClassgain = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearClassgain = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasClassgain = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional float dflGain = 8;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getDflgain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setDflgain = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearDflgain = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasDflgain = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional float weightDecay = 9;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getWeightdecay = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setWeightdecay = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearWeightdecay = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasWeightdecay = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional float learnBeginRate = 10;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getLearnbeginrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setLearnbeginrate = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearLearnbeginrate = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasLearnbeginrate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional float learnFinalRate = 11;
 * @return {number}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getLearnfinalrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setLearnfinalrate = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearLearnfinalrate = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasLearnfinalrate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool amp = 12;
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getAmp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setAmp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional TrainOptimizer optimizer = 13;
 * @return {!proto.grpc.c60.nn.training.TrainOptimizer}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getOptimizer = function() {
  return /** @type {!proto.grpc.c60.nn.training.TrainOptimizer} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {!proto.grpc.c60.nn.training.TrainOptimizer} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setOptimizer = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.clearOptimizer = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.hasOptimizer = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool deterministic = 14;
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.getDeterministic = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.training.TrainSettings} returns this
 */
proto.grpc.c60.nn.training.TrainSettings.prototype.setDeterministic = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_ = [[17,18]];

/**
 * @enum {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.ResultCase = {
  RESULT_NOT_SET: 0,
  READYMODEL: 17,
  ERROR: 18
};

/**
 * @return {proto.grpc.c60.nn.training.ProgressTelemetry.ResultCase}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getResultCase = function() {
  return /** @type {proto.grpc.c60.nn.training.ProgressTelemetry.ResultCase} */(jspb.Message.computeOneofCase(this, proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.ProgressTelemetry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.ProgressTelemetry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ProgressTelemetry.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryid: jspb.Message.getFieldWithDefault(msg, 19, 0),
    durationms: jspb.Message.getFieldWithDefault(msg, 1, 0),
    stagename: jspb.Message.getFieldWithDefault(msg, 2, ""),
    remaindurationms: jspb.Message.getFieldWithDefault(msg, 3, 0),
    workers: jspb.Message.getFieldWithDefault(msg, 4, 0),
    boxloss: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    clsloss: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    dflloss: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    step: jspb.Message.getFieldWithDefault(msg, 8, 0),
    learningrate: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    precision: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    recall: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    map50: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    map5095: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    modelparameters: jspb.Message.getFieldWithDefault(msg, 13, 0),
    modelgflops: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    modelspeedPytorchms: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    readymodel: (f = msg.getReadymodel()) && proto.grpc.c60.nn.training.FileURL.toObject(includeInstance, f),
    error: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.ProgressTelemetry;
  return proto.grpc.c60.nn.training.ProgressTelemetry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.ProgressTelemetry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQueryid(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationms(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStagename(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRemaindurationms(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBoxloss(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setClsloss(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDflloss(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStep(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLearningrate(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPrecision(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRecall(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMap50(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setMap5095(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModelparameters(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setModelgflops(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setModelspeedPytorchms(value);
      break;
    case 17:
      var value = new proto.grpc.c60.nn.training.FileURL;
      reader.readMessage(value,proto.grpc.c60.nn.training.FileURL.deserializeBinaryFromReader);
      msg.setReadymodel(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.ProgressTelemetry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.ProgressTelemetry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.ProgressTelemetry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryid();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getDurationms();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getStagename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRemaindurationms();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getWorkers();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getBoxloss();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getClsloss();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getDflloss();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getStep();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLearningrate();
  if (f !== 0.0) {
    writer.writeFloat(
      16,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getRecall();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMap50();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getMap5095();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getModelparameters();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getModelgflops();
  if (f !== 0.0) {
    writer.writeFloat(
      14,
      f
    );
  }
  f = message.getModelspeedPytorchms();
  if (f !== 0.0) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = message.getReadymodel();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.grpc.c60.nn.training.FileURL.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 queryId = 19;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getQueryid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setQueryid = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 durationMS = 1;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getDurationms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setDurationms = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string stageName = 2;
 * @return {string}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getStagename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setStagename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 remainDurationMS = 3;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getRemaindurationms = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setRemaindurationms = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 workers = 4;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getWorkers = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setWorkers = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float boxLoss = 5;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getBoxloss = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setBoxloss = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float clsLoss = 6;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getClsloss = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setClsloss = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float dflLoss = 7;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getDflloss = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setDflloss = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional int32 step = 8;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getStep = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setStep = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional float learningRate = 16;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getLearningrate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setLearningrate = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional float precision = 9;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float recall = 10;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getRecall = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setRecall = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float mAP50 = 11;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getMap50 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setMap50 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float mAP50_95 = 12;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getMap5095 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setMap5095 = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional int64 modelParameters = 13;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getModelparameters = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setModelparameters = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional float modelGFLOPs = 14;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getModelgflops = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setModelgflops = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional float modelSpeed_PyTorchMs = 15;
 * @return {number}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getModelspeedPytorchms = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setModelspeedPytorchms = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional FileURL readyModel = 17;
 * @return {?proto.grpc.c60.nn.training.FileURL}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getReadymodel = function() {
  return /** @type{?proto.grpc.c60.nn.training.FileURL} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.FileURL, 17));
};


/**
 * @param {?proto.grpc.c60.nn.training.FileURL|undefined} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
*/
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setReadymodel = function(value) {
  return jspb.Message.setOneofWrapperField(this, 17, proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.clearReadymodel = function() {
  return this.setReadymodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.hasReadymodel = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string error = 18;
 * @return {string}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.setError = function(value) {
  return jspb.Message.setOneofField(this, 18, proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grpc.c60.nn.training.ProgressTelemetry} returns this
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.clearError = function() {
  return jspb.Message.setOneofField(this, 18, proto.grpc.c60.nn.training.ProgressTelemetry.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.ProgressTelemetry.prototype.hasError = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.TrainingStop.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.TrainingStop.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.TrainingStop} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainingStop.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnresult: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.TrainingStop}
 */
proto.grpc.c60.nn.training.TrainingStop.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.TrainingStop;
  return proto.grpc.c60.nn.training.TrainingStop.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.TrainingStop} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.TrainingStop}
 */
proto.grpc.c60.nn.training.TrainingStop.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReturnresult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.TrainingStop.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.TrainingStop.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.TrainingStop} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.TrainingStop.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnresult();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool returnResult = 1;
 * @return {boolean}
 */
proto.grpc.c60.nn.training.TrainingStop.prototype.getReturnresult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.c60.nn.training.TrainingStop} returns this
 */
proto.grpc.c60.nn.training.TrainingStop.prototype.setReturnresult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.EmptyArg.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.EmptyArg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.EmptyArg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.EmptyArg.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.EmptyArg}
 */
proto.grpc.c60.nn.training.EmptyArg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.EmptyArg;
  return proto.grpc.c60.nn.training.EmptyArg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.EmptyArg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.EmptyArg}
 */
proto.grpc.c60.nn.training.EmptyArg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.EmptyArg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.EmptyArg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.EmptyArg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.EmptyArg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.c60.nn.training.Status.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.c60.nn.training.Status.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.c60.nn.training.Status} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.Status.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: jspb.Message.getFieldWithDefault(msg, 1, ""),
    currentstep: (f = msg.getCurrentstep()) && proto.grpc.c60.nn.training.ProgressTelemetry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.c60.nn.training.Status}
 */
proto.grpc.c60.nn.training.Status.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.c60.nn.training.Status;
  return proto.grpc.c60.nn.training.Status.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.c60.nn.training.Status} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.c60.nn.training.Status}
 */
proto.grpc.c60.nn.training.Status.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 2:
      var value = new proto.grpc.c60.nn.training.ProgressTelemetry;
      reader.readMessage(value,proto.grpc.c60.nn.training.ProgressTelemetry.deserializeBinaryFromReader);
      msg.setCurrentstep(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.c60.nn.training.Status.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.c60.nn.training.Status.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.c60.nn.training.Status} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.c60.nn.training.Status.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCurrentstep();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.c60.nn.training.ProgressTelemetry.serializeBinaryToWriter
    );
  }
};


/**
 * optional string version = 1;
 * @return {string}
 */
proto.grpc.c60.nn.training.Status.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.c60.nn.training.Status} returns this
 */
proto.grpc.c60.nn.training.Status.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProgressTelemetry currentStep = 2;
 * @return {?proto.grpc.c60.nn.training.ProgressTelemetry}
 */
proto.grpc.c60.nn.training.Status.prototype.getCurrentstep = function() {
  return /** @type{?proto.grpc.c60.nn.training.ProgressTelemetry} */ (
    jspb.Message.getWrapperField(this, proto.grpc.c60.nn.training.ProgressTelemetry, 2));
};


/**
 * @param {?proto.grpc.c60.nn.training.ProgressTelemetry|undefined} value
 * @return {!proto.grpc.c60.nn.training.Status} returns this
*/
proto.grpc.c60.nn.training.Status.prototype.setCurrentstep = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.c60.nn.training.Status} returns this
 */
proto.grpc.c60.nn.training.Status.prototype.clearCurrentstep = function() {
  return this.setCurrentstep(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.c60.nn.training.Status.prototype.hasCurrentstep = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * @enum {number}
 */
proto.grpc.c60.nn.training.VisualSensor = {
  NONESENSOR: 0,
  OPTICS: 1,
  THERMAL: 2,
  LIDAR: 3,
  RADAR: 4
};

/**
 * @enum {number}
 */
proto.grpc.c60.nn.training.NetworkSize = {
  NONESIZE: 0,
  NANO: 1,
  SMALL: 2,
  MEDIUM: 3,
  LARGE: 4,
  XLARGE: 5
};

/**
 * @enum {number}
 */
proto.grpc.c60.nn.training.TrainOptimizer = {
  NONEOPTIMIZER: 0,
  SGD: 1,
  ADAM: 2,
  ADAMAX: 3,
  ADAMW: 4,
  NADAM: 5,
  RADAM: 6,
  RMSPROP: 7,
  AUTO: 8
};

goog.object.extend(exports, proto.grpc.c60.nn.training);
