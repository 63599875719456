import {
    Button, message, Modal, Space, TreeSelect, Typography,
} from 'antd';

import { SerializedAttribute } from 'cvat-core-wrapper';
import { AttributeList, DClass } from 'proto/c60/nn/center/DatasetClasses_pb';

import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { ObjectsId, StringSearch } from 'proto/Common_pb';
import React, { useEffect, useState } from 'react';

import { getClientHost } from 'shared/constants';
import { createEnhancedClient } from '../../utils/grpc';
import ClassRegistryComponent from '../class-registry/class-registry';
import ClassificatorViewer from './classificator-viewer';
import { idGenerator, LabelOptColor } from './common';

const { SHOW_ALL } = TreeSelect;
const { Text } = Typography;

interface DataTree {
    key: React.Key;
    title?: string;
    value?: number;
    color?: string;
    type?: number;
    attributes?: AttributeList.AsObject;
    children?: DataTree[];
}

type LabelType = 'rectangle' | 'polygon' | 'polyline' | 'points' | 'ellipse' | 'cuboid' | 'skeleton' | 'mask' | 'tag';

interface JSONGRPCData {
    attributes: SerializedAttribute[];
    color: string;
    id: number;
    isPart: boolean;
    parentDClassId: number;
    name: string;
    type: LabelType;
}

interface LabelClassificatorProps {
    labels: LabelOptColor[];
    onAdd: (labels: LabelOptColor[]) => void;
    onDelete: (label: LabelOptColor) => void;
}

const LabelClassificator = (props: LabelClassificatorProps): JSX.Element => {
    const { labels, onAdd, onDelete } = props;
    const [datasetsIds, setDatasetsIds] = useState<number[]>();
    const [treeData, setTreeData] = useState<DataTree[]>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const [grpcDatasetObject, setGrpcDatasetObject] = useState<object[]>([]);
    const [selectedClassesName, setSelectedClassesName] = useState<string[]>();
    const [savedClassesName, setSavedClassesName] = useState<string[]>();

    const currentHost = getClientHost();

    // 2 - Формируем дочерние объекты данных для древовидного списка по существующим классам
    const getClassesChild = (parents: DataTree[], classesList: DClass.AsObject[]): DataTree[] => {
        let haveParent = false;

        parents.map((element: DataTree) => {
            classesList.forEach((section) => {
                if (element.key === section.parentdclassid) {
                    const child: DataTree = {
                        key: Number(section.id),
                        title: section.title,
                        value: Number(section.id),
                        attributes: section.attributes,
                    };

                    child.color = section.color?.hexrgb;

                    // Определяем тип - 1 - 'Часть объекта', 2 - 'Подвид объекта'
                    if (section.ispart) {
                        child.type = 1;
                    } else {
                        child.type = 2;
                    }

                    element.children = element.children || [];
                    haveParent = true;

                    element.children.push(child);
                }
            });

            if (haveParent && element.children) {
                return getClassesChild(element.children, classesList);
            }

            return element;
        });

        return parents;
    };

    // 1 - Формируем древовидный список по существующим классам
    const getClassesBase = (classesList: DClass.AsObject[]): void => {
        const rootForming: DataTree[] = classesList.reduce((acc: DataTree[], current) => {
            if (current.parentdclassid === 0) {
                const generate: DataTree = {
                    key: Number(current.id),
                    title: current.title,
                    value: Number(current.id),
                    attributes: current.attributes,
                };

                generate.color = current.color?.hexrgb;

                // Указываем тип - 0 - 'Корневой класс'
                generate.type = 0;

                acc.push(generate);
            }

            return acc;
        }, []);

        const baseData: DataTree[] = getClassesChild(JSON.parse(JSON.stringify(rootForming)), classesList);

        setTreeData(baseData);
    };

    //! Получаем список ID существующих классов
    const getDatasetsIds: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getAll(new StringSearch().setText(''), {});
            setDatasetsIds(datasets.getIdsList().sort((a, b) => a - b));
        }
    };

    //! Получаем информацию по существующим классам
    const getDatasetsInfo: () => void = async () => {
        if (currentHost) {
            const client = createEnhancedClient(DClassServiceClient);
            const datasets = await client.getInfo(new ObjectsId().setIdsList(datasetsIds || []), {});
            const classesList = datasets.toObject();
            setGrpcDatasetObject(classesList.classesList);
            getClassesBase(classesList.classesList.sort((value) => (value.ispart ? -1 : 1)));
        }
    };

    // Запуск функции получения списка ID - GRPC
    useEffect(() => {
        getDatasetsIds();
    }, []);

    // Запуск функции получения объекта данных существующих классов - GRPC
    useEffect(() => {
        getDatasetsInfo();
    }, [datasetsIds]);

    // Заносим имена существующих на бэке классов
    useEffect(() => {
        const nameValues = labels.map((element) => element.name);
        setSavedClassesName(nameValues);
    }, [labels]);

    // Приводим Класс к структуре
    const convertLabels = (obj: JSONGRPCData[]): LabelOptColor[] => {
        const convertResult = obj.map((newClass: JSONGRPCData): LabelOptColor => {
            const converted: LabelOptColor = {
                name: newClass.name,
                id: idGenerator(),
                color: newClass.color,
                type: newClass.type,
                attributes: (newClass.attributes || []).map((attribute): SerializedAttribute => {
                    if (Object.prototype.hasOwnProperty.call(attribute, 'id')) {
                        attribute.id = idGenerator();
                    }

                    return attribute;
                }),
            };

            return converted;
        });

        return convertResult;
    };

    // Добавление классов в проект - Кнопка Добавить
    const handleSubmit = (): void => {
        // Вычисляем, каких классов нет в проекте, из выбранных в классификаторе
        const unsavedLabelsName: string[] | undefined = selectedClassesName?.filter(
            (label) => !new Set(savedClassesName).has(label),
        );

        // Массив ID классов, которых нет в проекте
        const unsavedLabelsIDs: number[] = [];

        // Заполняем массив ID классов, которых нет в проекте
        grpcDatasetObject?.forEach((obj: DClass.AsObject): void => {
            const currentObj = Object.assign(obj);

            if (unsavedLabelsName?.includes(currentObj.title)) {
                unsavedLabelsIDs.push(currentObj.id);
            }
        });

        // Создаем переменную с структурой объектов соответствующих требованию бэка
        const unsavedLabels: LabelOptColor[] = [];

        // !Получаем JSON класса с атрибутами
        (async () => {
            if (currentHost) {
                const client = createEnhancedClient(DClassServiceClient);
                const datasets = await client.getCvatJsonInfo(new ObjectsId().setIdsList(unsavedLabelsIDs), {});
                const jsonInfoToObject = await JSON.parse(datasets.toObject().text);

                unsavedLabels.push(...convertLabels(jsonInfoToObject));
            }
        })()
            .then(() => {
                // Объединяем в массив уже существующие в проекте Классы и новые, добавленные Классы
                const newLabelsSet = [...labels, ...unsavedLabels];

                // Отправляем на бэк новый набор Классов
                onAdd(newLabelsSet);
                message.success('Классы добавлены');
            })
            .catch((error) => {
                message.error('Не удалось добавить Классы!');
                console.error(error);
            });
    };

    return (
        <>
            <Text strong className='cvat-text-color'>
                Классы
            </Text>
            <br />
            <Space align='start' style={{ marginTop: 7 }}>
                <TreeSelect
                    treeData={treeData}
                    onChange={(_, label) => {
                        setSelectedClassesName(label as string[]);
                    }}
                    treeCheckable
                    treeDefaultExpandAll
                    treeLine
                    treeIcon
                    allowClear
                    showCheckedStrategy={SHOW_ALL}
                    placeholder='Выберите классы'
                    maxTagCount='responsive'
                    style={{ width: 500, borderRadius: 6 }}
                    filterTreeNode={(search, item) => (
                        String(item.title).toLowerCase().indexOf(search.toLowerCase()) >= 0
                    )}
                />
                <Button type='primary' style={{ borderRadius: 6 }} onClick={() => handleSubmit()}>
                    Добавить в проект
                </Button>
                <Button type='dashed' style={{ borderRadius: 6, marginLeft: 15 }} onClick={() => setModalIsOpen(true)}>
                    Посмотреть Классификатор
                </Button>
            </Space>

            <ClassificatorViewer labels={labels} onDelete={onDelete} />

            <Modal
                title='Классификатор'
                open={modalIsOpen}
                onCancel={() => setModalIsOpen(false)}
                footer={[
                    <Button type='primary' onClick={() => setModalIsOpen(false)}>
                        Закрыть
                    </Button>,
                ]}
                width={1580}
                closable
                destroyOnClose
            >
                <ClassRegistryComponent />
            </Modal>
        </>
    );
};

export default LabelClassificator;
