import { Badge, Button, Descriptions, DescriptionsProps, Input, Typography } from 'antd';
import { Dataset } from 'proto/c60/nn/center/DataSets_pb';
import { Detector, TrainingQuery } from 'proto/c60/nn/center/Detectors_pb';
import { NetworkSize, VisualSensor } from 'proto/nn_training/DetectorTraining_pb';
import React from 'react';
import { useHistory } from 'react-router';
import useTraining from '../api/useTraining';

type OwnProps = {
    dataset?: Dataset.AsObject;
    trainingQuery?: TrainingQuery.AsObject;
};

const DatasetForm: React.FC<OwnProps> = ({ dataset, trainingQuery }) => {
    const { title, cvatProjectname, imagesize, id } = dataset ?? {};

    const history = useHistory();

    const { trainingQuery: parentTrainingQuery } = useTraining(trainingQuery?.parentdetector?.id ?? 0);

    const networkSizeToRus = (networksize: NetworkSize) => {
        switch (networksize) {
            case NetworkSize.LARGE:
                return 'Большой(large)';
            case NetworkSize.NANO:
                return 'Минимальный(nano)';
            case NetworkSize.SMALL:
                return 'Маленький(small)';
            case NetworkSize.MEDIUM:
                return 'Средний(medium)';
            case NetworkSize.XLARGE:
                return 'Максимальный(XL)';
            default:
                return 'None';
        }
    };

    const sensorTypeToRus = (sensorType: VisualSensor) => {
        switch (sensorType) {
            case VisualSensor.OPTICS:
                return 'Оптический';

            case VisualSensor.LIDAR:
                return 'Лидар';

            case VisualSensor.RADAR:
                return 'Радар';

            case VisualSensor.THERMAL:
                return 'Инфракрасный';
            default:
                return 'Отсутствует';
        }
    };

    return (
        <div className='datasetForm'>
            <div className='datasetParamContainer'>
                <Typography.Text strong>Датасет</Typography.Text>
                <div className='inputWrapper'>
                    <Input disabled size='middle' value={title} />
                    <Button type='primary' onClick={() => history.push(`/dataset?info=${id}`)}>
                        Перейти
                    </Button>
                </div>
            </div>

            <div className='datasetParamContainer'>
                <Typography.Text strong>На основе проекта</Typography.Text>
                <div className='inputWrapper'>
                    <Input disabled size='middle' value={cvatProjectname} />
                </div>
            </div>

            <div className='datasetParamContainer'>
                <Typography.Text strong>Родительская модель</Typography.Text>
                <div className='inputWrapper'>
                    <Input disabled size='middle' value={parentTrainingQuery?.detectorname ?? ''} />
                    <Button
                        type='primary'
                        onClick={() => history.push(`/learning/model/${trainingQuery?.parentdetector?.id}`)}
                        disabled={trainingQuery?.parentdetector?.id ? false : true}
                    >
                        Перейти
                    </Button>
                </div>
            </div>

            <div className='datasetParamContainer'>
                <Typography.Text strong>Размер изображений</Typography.Text>
                <div className='inputWrapper'>
                    <Input
                        disabled
                        size='middle'
                        addonBefore='Ширина'
                        value={
                            parentTrainingQuery
                                ? parentTrainingQuery.architecture?.input?.size?.width
                                : trainingQuery?.architecture?.input?.size?.width
                        }
                    />
                    <Input
                        disabled
                        size='middle'
                        addonBefore='Высота'
                        value={
                            parentTrainingQuery
                                ? parentTrainingQuery.architecture?.input?.size?.height
                                : trainingQuery?.architecture?.input?.size?.height
                        }
                    />
                </div>
            </div>

            <div className='datasetParamContainer'>
                <Typography.Text strong>Тип используемого сенсора</Typography.Text>
                <div className='inputWrapper'>
                    {/* <Input
                        disabled
                        size='middle'
                        value={VisualSensor?.[trainingQuery?.architecture?.input?.channelsList[0]?.sensor ?? 0]}
                    /> */}
                    <Descriptions title='' bordered style={{ width: '100%' }} size='small' className='descriptions'>
                        <Descriptions.Item label='Тип сенсора'>
                            {sensorTypeToRus(
                                trainingQuery?.architecture?.input?.channelsList[0]?.sensor ?? VisualSensor.NONESENSOR,
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label='Цветовая модель'>
                            {trainingQuery?.architecture?.input?.channelsList[0]?.size === 3 ? 'RGB' : null}
                            {trainingQuery?.architecture?.input?.channelsList[0]?.size === 1 ? 'Grayscale' : null}
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>

            {/* <div className='datasetParamContainer'>
                <Typography.Text strong>Цветовая модель</Typography.Text>
                <div className='inputWrapper'>
                    <Input disabled size='middle' />
                </div>
            </div> */}

            <div className='datasetParamContainer'>
                <Typography.Text strong>Размер модели</Typography.Text>
                <div className='inputWrapper'>
                    <Input
                        disabled
                        size='middle'
                        value={networkSizeToRus(trainingQuery?.architecture?.networksize ?? 0)}
                    />
                </div>
            </div>
        </div>
    );
};

export default DatasetForm;
