import React from 'react';

import { Row, Col, Progress } from 'antd';

export interface StatisticStructure {
    classId: number;
    title: string;
    trainExamples: number;
    validationExamples: number;
    testExamples: number;
    countExamples: number;
}
interface DataseAboutStatisticItemComponentProps {
    data: StatisticStructure;
    maxCount: number;
}

const DataseAboutStatisticItemComponent = (props: DataseAboutStatisticItemComponentProps): JSX.Element => {
    const { data, maxCount } = props;

    return (
        <Row gutter={[16, 5]}>
            <Col span={12}>
                <div className='dataset-paje-component-statistic-item-cell'>
                    <div className='dataset-paje-component-statistic-subpoint'>{data.title}</div>
                    <div style={{ fontSize: 10, color: '#636569' }}>{`Всего изображений: ${data.countExamples}`}</div>
                </div>
            </Col>
            <Col span={4}>
                <div className='dataset-paje-component-statistic-subset-cell'>
                    <div className='dataset-paje-component-statistic-subpoint'>{data.trainExamples}</div>
                    <div style={{ fontSize: 10 }}>Обучающая</div>
                </div>
            </Col>
            <Col span={4}>
                <div className='dataset-paje-component-statistic-subset-cell'>
                    <div className='dataset-paje-component-statistic-subpoint'>{data.validationExamples}</div>
                    <div style={{ fontSize: 10 }}>Контрольная</div>
                </div>
            </Col>
            <Col span={4}>
                <div className='dataset-paje-component-statistic-subset-cell'>
                    <div className='dataset-paje-component-statistic-subpoint'>{data.testExamples}</div>
                    <div style={{ fontSize: 10 }}>Тестовая</div>
                </div>
            </Col>
            <Col span={24}>
                <Progress
                    percent={((100 / data.countExamples) * (data.trainExamples + data.validationExamples))}
                    success={{ percent: ((100 / data.countExamples) * data.trainExamples), strokeColor: '#368585' }}
                    showInfo={false}
                    strokeColor='#384952'
                    trailColor='#C2C8D3'
                    style={{ marginBottom: 15, width: `${((100 / maxCount) * data.countExamples)}%` }}
                />
            </Col>
        </Row>
    );
};

export default DataseAboutStatisticItemComponent;
