import React, { useEffect, useState } from 'react';

import { Dataset } from 'proto/c60/nn/center/DataSets_pb';

import {
    Row, Col, Input, Statistic,
} from 'antd';

interface DataseAboutProjectComponentProps {
    datasetData: Dataset.AsObject | undefined;
}

const DataseAboutProjectComponent = (props: DataseAboutProjectComponentProps): JSX.Element => {
    const { datasetData } = props;

    // Сумма изображений в датасете
    const [subsetsAmount, setSubsetsAmount] = useState<number>(0);
    // Количество изображений в Обучающей выборке
    const [trainSubset, setTrainSubsets] = useState<number>(0);
    // Количество изображений в Констрольной выборке
    const [validationSubset, setValidationSubsets] = useState<number>(0);
    // Количество изображений в Тестовой выборке
    const [testSubset, setTestSubsets] = useState<number>(0);

    // Вычисляем и указываем количество изображений в выборках датасета
    useEffect(() => {
        if (datasetData) {
            let amount = 0;

            if (datasetData.trainsetimages) {
                amount += datasetData.trainsetimages;
                setTrainSubsets(datasetData.trainsetimages);
            }

            if (datasetData.validationsetimages) {
                amount += datasetData.validationsetimages;
                setValidationSubsets(datasetData.validationsetimages);
            }

            if (datasetData.testsetimages) {
                amount += datasetData.testsetimages;
                setTestSubsets(datasetData.testsetimages);
            }

            setSubsetsAmount(amount);
        }
    }, [datasetData]);

    return (
        <Row gutter={[16, 40]}>
            <Col span={8}>
                <div className='dataset-paje-component-chapter-project'>Проект</div>
            </Col>
            <Col span={16}>
                <Row gutter={[16, 30]}>
                    <Col span={24}>
                        <Input style={{ borderRadius: 6 }} value={datasetData ? datasetData.cvatProjectname : ''} disabled />
                    </Col>
                    <Col span={12}>
                        <Statistic title='Количество изображений' value={subsetsAmount} />
                    </Col>
                    <Col span={12}>
                        <Statistic title='Обучающая выборка' value={trainSubset} />
                    </Col>
                    <Col span={12}>
                        <Statistic title='Констрольная выборка' value={validationSubset} />
                    </Col>
                    <Col span={12}>
                        <Statistic title='Тестовая выборка' value={testSubset} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DataseAboutProjectComponent;
