import { ObjectsId } from 'proto/Common_pb';
import { DClassServiceClient } from 'proto/c60/nn/center/DatasetClassesServiceClientPb';
import { DClass } from 'proto/c60/nn/center/DatasetClasses_pb';
import React from 'react';
import { getClientHost } from 'shared/constants';

const useDatasetClasses = (ids: number[]) => {
    const currentHost = getClientHost();

    const client = new DClassServiceClient(currentHost ?? '');

    const [dclasses, setDClasses] = React.useState<DClass.AsObject[]>([]);

    const [tags, setTags] = React.useState<string[]>([]);

    React.useEffect(() => {
        const getClasses = async (ids: number[]) => {
            const objectIds = new ObjectsId().setIdsList(ids);
            const res = await client.getInfo(objectIds, null);

            setDClasses(res.toObject().classesList);
            if (res.toObject().classesList) {
                res.toObject().classesList.map((cl) => {
                    if (cl.title) setTags([...tags, cl.title]);
                });
            }
        };

        if (ids) {
            getClasses(ids);
        }
    }, [ids]);

    return {
        dclasses,
        tags,
    };
};

export default useDatasetClasses;
